import { ChangeHistory } from "../history/interfaces";
import { Attachment } from "../structures/interfaces";

export interface Task {
    id?: string,
    projectId?: string,
    projectName?: string,
    structureId?: string,
    structureName?: string,
    name?: string,
    assignedToUserId?: string,
    assignedToName?: string,
    status?: string, 
    timerStatus?: string
    priority?: string,
    description?: string,
    isDefault?: boolean,
    scheduledStartTime?: Date,
    scheduledEndTime?: Date,
    estimatedDurationInSeconds?: number,
    startTime?: Date,
    endTime?: Date,
    pausedTime?: Date, 
    durationInSeconds?: number
    elapsedTime?: number, 
    comments?: Comment[],
    estimatedDuration?: number,
    orderNumber?: number,
    createdBy?: string,
    createdById?: string,
    updatedById?: string,
    updatedBy?: string,
    changeHistory?: ChangeHistory[],
    attachments?: Attachment[]

}

export interface Comment {
    authorUserId?: string,
    authorFullName?: string,
    comment?: string,
    status?: string,
    createdOn: Date
}

export interface AddComment {
    taskId?: string,
    comment?: Comment
}

export interface TaskState {
    tasks: Task[],
    overdueTasks: Task[],
    loading: boolean, 
    error: any, 
    pagesize: number,
    pagenumber: number,
    pagecount: number, 
    resultstotal: number
}
export interface SvcStateBase {
    loading: boolean, 
    error: any
}
export interface TaskSvcState extends SvcStateBase {
    tasks: Task[]
}

export enum TimerStatus {
    Pending = 'Pending', 
    InProgress = 'In Progress', 
    Paused = 'Paused',
    Stopped = 'Stopped'
}