import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';
import { Paper } from '@mui/material';

import styles from '../reports.module.scss';
import { DailyReportSummary, DailyReportDetail } from '../interfaces';
import Heading from '../../../components/heading/Heading';
import { Modal } from '../../../components/modal/Modal';
import { Tab } from '../../../components/tab/interfaces';
import TabItem from '../../../components/tab/TabItem';
import { TaskTableUI } from '../../../components/tables/TasksTable';
import { ReportCommentsTable } from '../../../components/tables/CommentsTable';
import History from '../../history/History';
import ReportsForm from '../../../components/forms/ReportsForm';

const ReportItem = () => {
    const history = useHistory();

    type Params = { id: string; }
    const { id } = useParams<Params>();

    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type DailyReportsStore = { reportsinfo: { dailyreports: DailyReportSummary[], loading: boolean, error: any }, 
                               selectreportinfo: { dailyreport: DailyReportDetail, loading: boolean, error: any } };
    const dailyReportsStore = useSelector((state: { dailyReportsStore: DailyReportsStore }) => state.dailyReportsStore);
    const report = dailyReportsStore.reportsinfo.dailyreports.find(p => p.id === decodeURIComponent(id));

    const tabItems: Tab[] = [
        { id: 1, name: `Daily Report` }
		// , { id: 2, name: 'History' }
    ]

    const foremanContent = (
        <div className={styles['table-container']}>
            <div className={styles.title}>Notes</div>
            <div>
                {report?.notes || 'There are no notes.'}
            </div>
            <div className={styles.title}>Completed Tasks</div>
            <TaskTableUI tasks={dailyReportsStore?.selectreportinfo?.dailyreport?.completedWorkTasks || []} 
                    loading={dailyReportsStore?.selectreportinfo.loading}
                    error={dailyReportsStore?.selectreportinfo.error}
                    filterValue={''} showSort={false}
                    isCompletedOnly={true} isOverdueOnly={false} canEdit={false}  />
            <div className={styles.title}>Overdue Tasks</div>
            <TaskTableUI tasks={dailyReportsStore?.selectreportinfo?.dailyreport?.overdueWorkTasks || []} 
                    loading={dailyReportsStore?.selectreportinfo.loading}
                    error={dailyReportsStore?.selectreportinfo.error}
                    filterValue={''} showSort={false}
                    isCompletedOnly={false} isOverdueOnly={true} canEdit={false}  />
            <div className={styles.title}>Comments</div>
            <ReportCommentsTable comments={dailyReportsStore?.selectreportinfo?.dailyreport?.taskComments || []}
                    loading={dailyReportsStore?.selectreportinfo.loading}
                    error={dailyReportsStore?.selectreportinfo.error} />
        </div>
    );

    const historyContent = (
        <div className={styles['table-container']}>
            <History showSort={true} showTitle={false} history={dailyReportsStore?.selectreportinfo?.dailyreport?.changeHistory?dailyReportsStore?.selectreportinfo?.dailyreport?.changeHistory:[]}/>
        </div>
    );

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    return (
        <div className={styles['report-item-container']}>
            <Heading title={`Daily Report - ${report?.reportDate ? dayjs(report.reportDate).format('MM/DD/YYYY h:mm a') : ''}`} isClickable={true} clickAction={() => setShowModal(true)} />
            <div className={styles['item-detail-section']}>
                <div style={{ marginRight: 0 }} className={styles.item}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className={styles.title}>Report Info</div>
                        <div className={styles.detail}>
                            <div>Project</div>
                            <div className={styles['clickable-item']} onClick={() => history.push(`/projects/${encodeURIComponent(report?.projectId || '')}`)}>{report?.projectName || '-'}</div>
                        </div>
                        <div className={styles.detail}>
                            <div>Author</div>
                            <div>{report?.authorName || '-'}</div>
                        </div>
                        <div className={styles.detail}>
                            <div>Created On</div>
                            <div>{report?.createdOn ? new Date(report.createdOn).toLocaleDateString() : '-'}</div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className={styles['tabs-container']}>
                <div className={styles.tabs}>
                    {tabItems.map(ti => <TabItem tab={ti} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />)}
                </div>
                <div className={styles['tab-content']}>
                    {
                        selectedTab === 1 ? foremanContent :
                        selectedTab === 2 ? historyContent : null
                    }
                </div>
            </div>
            {showModal && <Modal title='Edit Report' closeModal={() => setShowEditModal(true)}><ReportsForm dailyReport={report} isExisting={true} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal>}
        </div>
    )
}

export default ReportItem;