import styles from './popup.module.scss';


type Props = ({ closeModal: any, editEntity: any, discardChanges : any, customMessage? : string });
const EditPopup = ({closeModal, editEntity, discardChanges, customMessage }: Props) => {

    return (

        <div>
            {customMessage !== undefined && customMessage !== '' ? 
            <>
                <div style={{textAlign : 'center'}}>
                    <div>{customMessage}</div>
                </div>
                    <div className={styles.actions} style={{backgroundColor:'gray'}}>
                    <button onClick={() => discardChanges()}>Cancel</button>
                    <button onClick={() => editEntity()}>Complete</button>
                </div>
            </>
            :
            <>
                <div style={{textAlign : 'center'}}>
                    <div>You have made changes.</div>
                    <div>Would you like to discard or save your changes?</div>
                </div> 
                <div className={styles.actions} style={{backgroundColor:'gray'}}>
                    <button onClick={() => discardChanges()}>Discard</button>
                    <button onClick={() => editEntity()}>Save</button>
                </div>
            </>    
            }
        </div>

    )
}

export default EditPopup;