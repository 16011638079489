import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import styles from './history.module.scss';
import Title from '../../components/title/Title';
import HistoryTable from '../../components/tables/HistoryTable';
import { ChangeHistory } from './interfaces';

type Props = ({ showSort: boolean, showTitle: boolean, history?: ChangeHistory[] });
const History = ({ showSort, showTitle, history }: Props) => {
    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);

    const Cards = (
        <div className={styles['cards-container']}>
            <div className={styles.cards}>
                <div>History cards</div>
            </div>
        </div>
    )
    const Table = (
        <div>
            <HistoryTable showSort={showSort} filterValue={search} history={history?history:[]}/>
        </div>
    )
    return (
        <div className={styles['history-container']}>
            <Title headingTitle='History' hideTitle={!showTitle} />
            <div className={styles['filter-section']}>
                <FontAwesomeIcon icon={faFilter} />
                <div className={styles['input-item']}>
                    <input type='text' placeholder='filter data' onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            {isCard ? Cards : Table}
        </div>
    )
}

export default History;