import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import ProjectForm from '../forms/ProjectForm';
import { SortInfo, useItemsSort } from '../../hooks/hooks';
import { Project } from '../../views/projects/interfaces';
import { deleteProject } from '../../views/projects/actions';
import { useEventListener } from '../../hooks/hooks';
import { DeleteModal, Modal } from '../modal/Modal';
import DeletePopup from '../popup/DeletePopup';

type MenuProps = ({ project: Project, setShowModal: any, setShowDeleteModal:any, setSelectedProject: any, setSelectedRow: any, canEdit: boolean });
const ActionMenu = ({ project, setShowModal, setShowDeleteModal, setSelectedProject, setSelectedRow, canEdit }: MenuProps) => {
    const [ showActions, setShowActions ] = useState<boolean>(false);

    const actionsRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (e: any) => {
        if (actionsRef && actionsRef.current && !actionsRef.current.contains(e.target)) {
            setShowActions(false); setSelectedRow(null);
        }
    }

    useEventListener('mousedown', handleOutsideClick);
    return (
        <div className={styles['actions-menu']} ref={actionsRef}>
            <div className={styles.more} onClick={() => setShowActions(!showActions)}></div>
            {   showActions && 
                <div className={cx(styles['actions-container'], {[styles['show-actions']]: showActions})}>
                    {  canEdit && 
                        <>
                            <div className={styles.action} onClick={() => { setShowModal(true); setSelectedProject(project); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faPencilAlt} /></div><div>edit project</div></div>
                            <div className={styles.action} onClick={() => { setShowDeleteModal(true); setSelectedProject(project); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faTrashAlt} /></div><div>delete project</div></div>
                        </>
                    }
                </div>
            }
        </div>
    )
}

type Props = ({ projects: Project[], loading: boolean, error: any});
const ProjectsTable = ({projects, loading, error}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [selectedRow, setSelectedRow] = useState<string>('');
    
    const [sortType, setSortType] = useState<string>('end-desc');
    const [selectedSort, setSelectedSort] = useState<any | null>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    
    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? 
            setSortType(headingInfo.field+'-asc') : 
            setSortType(headingInfo.field+'-desc');

        setSelectedSort(headingInfo);
    }

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    // setup sorting initial sort is set to 'date' type and using value of 'end date'
    const sortInfo: SortInfo = {items: projects, valueType: selectedSort?.valuetype || 'date', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'end' };
    const sortedProjects = useItemsSort(sortInfo);

    type PermissionsStore = { canEditProjects: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    <tr>
                        {
                            theads.projects.map(h => (
                                (h.title != '' || (h.title == '' && permissionsStore.canEditProjects)) &&
                                // (permissionsStore.canEditProjects || h.showviewonly) &&
                                <th key={h.id} colSpan={h.colspan} onClick={() => h.sort ? handleSort(h) : null}>
                                    <div className={styles['col-labels']}>
                                        <div>{h.title}&nbsp;</div>
                                        { h.sort ? 
                                        h.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                        h.field?.toLowerCase()+'-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> : 
                                        <FontAwesomeIcon icon={faSort} /> : null}
                                    </div>
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                {
                    loading ? 
                    <tbody>
                        <tr className={styles['row-spinner']}><td colSpan={15}>
                            <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                <div className={styles.spinner}></div>
                            </div>
                        </td></tr>
                    </tbody> :
                    <tbody>
                        {
                            sortedProjects.map(p => (
                                <tr key={p.id} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === p.id})}>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}><div>{p.name}</div></td>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}>{p.type}</td>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}>{p.status}</td>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}><div>{p.company}</div></td>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}>{`${p.structuresCompletedCount}/${p.structuresTotalCount}`}</td>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}><div>{p.scheduledStartDate ? new Date(p.scheduledStartDate).toLocaleDateString() : ''}</div></td>
                                    <td onClick={() => history.push(`/projects/${encodeURIComponent(p?.id || '')}`)} colSpan={2}><div>{p.scheduledEndDate ? new Date(p.scheduledEndDate).toLocaleDateString() : ''}</div></td>
                                    {permissionsStore.canEditProjects ? 
                                        <td>
                                            <ActionMenu project={p} setShowModal={setShowModal} setShowDeleteModal={setShowDeleteModal} setSelectedProject={setSelectedProject} setSelectedRow={setSelectedRow} canEdit={permissionsStore.canEditProjects} />
                                        </td> 
                                        :
                                        ""
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                }
            </table>
            { showModal && <Modal title='Edit Project' closeModal={() => setShowEditModal(true)}><ProjectForm project={selectedProject || undefined} isExisting={true} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal> }
            { showDeleteModal && <DeleteModal title='Delete Project' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={selectedProject?.name || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(selectedProject){dispatch(deleteProject(selectedProject))}setShowDeleteModal(false);}}/></DeleteModal> }
        </div>
    )
}

export default ProjectsTable