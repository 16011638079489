import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import StartStop from '../controls/buttons/start-stop/StartStop';
import { Task, TimerStatus } from '../../views/tasks/interfaces';
import { User } from '../../views/users/interfaces';

type ItemProps = ({ task: Task });
const TableItem = ({ task }: ItemProps) => {
    const [elapsedTime, setElapsedTime] = useState<number>(task.elapsedTime || 0);

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;

    useEffect(() => {
        if (task.timerStatus?.toLowerCase() === TimerStatus.InProgress.toLowerCase()) {
            if (task.pausedTime) {
                const current = dayjs();
                const past = dayjs(task.pausedTime);
                const pauseTime = current.diff(past, 'second');
                setElapsedTime(elapsedTime + pauseTime);
            }
            else if (!task.pausedTime && task.startTime) {
                const current = dayjs();
                const past = dayjs(task.startTime);
                const startTime = current.diff(past, 'second');
                setElapsedTime(elapsedTime + startTime);
            }
        }
        //Prevent loss of data after reset
        else if((task.status?.toLocaleLowerCase() === 'in progress') && !task.elapsedTime) {
            const current = dayjs();
            const past = dayjs(task.startTime);
            const beforeRefresh = current.diff(past, 'second');
            setElapsedTime(elapsedTime + beforeRefresh);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const formattedElapsed = `${(Math.floor((elapsedTime / 3600)))}:${('0' + Math.floor((elapsedTime / 60) % 60)).slice(-2)}:${('0' + Math.floor((elapsedTime / 1) % 60)).slice(-2)}`;
    return (
        <>
            <td><div>{task.name}</div></td>
            <td>{task.status}</td>
            <td>{formattedElapsed}</td>
            <td>
            {   profile?.role?.toLocaleLowerCase() !== 'client' && task ? 
                <div className={styles['start-stop']}>
                    <StartStop
                        task={task}
                        elapsedTime={elapsedTime}
                        setElapsedTime={setElapsedTime} />
                </div> : ''
            }
            </td>
        </>
    )
}

const TasksActionTable = () => {
    type TasksStore = { tasks: Task[], loading: boolean, error: any };
    const tasksStore = useSelector((state: { tasksStore: TasksStore }) => state.tasksStore);
    return (
        <div className={styles['table-action-container']}>
            <table>
                <thead>
                    <tr style={{ height: '40px' }}>
                        {   theads.tasksmall.map(h => {
                                return (
                                    <th key={h.id} colSpan={h.colspan}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title}&nbsp;</div>
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                {
                    tasksStore.loading ? 
                    <tbody>
                        <tr className={styles['row-spinner']}>
                            <td colSpan={4}>
                                <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                    <div className={styles.spinner}></div>
                                </div>
                            </td>
                        </tr>
                    </tbody> :
                    <tbody>
                        {
                            tasksStore.tasks.length === 0 ? 
                                <tr>
                                    <td colSpan={4}>
                                        <div>Currently there are no tasks.</div>
                                    </td>
                                </tr>
                            :
                            tasksStore.tasks.map(t => (
                                <tr key={t.id}>
                                    <TableItem task={t} />
                                </tr>
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default TasksActionTable;