import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import { FilterInfo, useItemsFilter, SortInfo, useItemsSort } from '../../hooks/hooks';
import { Structure, StructureState } from '../../views/structures/interfaces';
import { deleteStructure } from '../../views/structures/actions';
import StructureForm from '../forms/StructureForm';
import { DeleteModal, Modal } from '../modal/Modal';
import { useEventListener } from '../../hooks/hooks';
import { useHistory } from 'react-router-dom';
import { Project } from '../../views/projects/interfaces';
import DeletePopup from '../popup/DeletePopup';

import {Dashboard} from '../../views/home/interfaces';
type MenuProps = ({ structure: Structure, setShowModal: any, setSelectedStructure: any, setSelectedRow: any, canEdit: boolean, setShowDeleteModal: any });
const ActionMenu = ({ structure, setShowModal, setSelectedStructure, setSelectedRow, canEdit, setShowDeleteModal }: MenuProps) => {
    const [ showActions, setShowActions ] = useState<boolean>(false);

    const actionsRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (e: any) => {
        if (actionsRef && actionsRef.current && !actionsRef.current.contains(e.target)) {
            setShowActions(false); setSelectedRow(null);
        }
    }

    useEventListener('mousedown', handleOutsideClick);
    return (
        <div className={styles['actions-menu']} ref={actionsRef}>
            <div className={styles.more} onClick={() => {setShowActions(!showActions); setSelectedRow(structure.id);}}></div>
            {   showActions && 
                <div className={cx(styles['actions-container'], {[styles['show-actions']]: showActions})}>
                    {   canEdit &&
                        <>
                            <div className={styles.action} onClick={() => { setShowModal(true); setSelectedStructure(structure); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faPencilAlt} /></div><div>edit structure</div></div>
                            <div className={styles.action} onClick={() => { setShowDeleteModal(true); setSelectedStructure(structure); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faTrashAlt} /></div><div>delete structure</div></div>
                        </>
                    }
                </div>
            }
        </div>
    )
}

type Props = ({ showSort: boolean, project?: Project, filterValue: string, projectId?: string, structureId? : string, hiddenCols? : boolean });
const StructuresTable = ({ showSort, project, filterValue, projectId, structureId, hiddenCols }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedStructure, setSelectedStructure] = useState<Structure | null>(null);
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const [sortType, setSortType] = useState<string>('end-desc');
    const [selectedSort, setSelectedSort] = useState<any | null>(null);

    // type StructuresStore = { structures: Structure[], loading: boolean, error: any };
    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);

    type PermissionsStore = { canEditSturctures: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    type HomeStore = { data: Dashboard, loading: boolean, error: any };
    const homeStore = useSelector((state: { homeStore: HomeStore }) => state.homeStore);

    if (window.location.href.split('/').slice(-1)[0] == 'home') {
        structuresStore.structures = homeStore.data.structures;
    }

    if (projectId && homeStore?.data?.structures) {
        structuresStore.structures = homeStore.data.structures.filter((structure) => {
            return structure.projectId === projectId;
        });
    }

    if(structureId)
    {
        structuresStore.structures = homeStore.data.structures.filter((structure) => {
            return structure.id === structureId;
        })
    }

    //used for column sorting
    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ?
            setSortType(headingInfo.field + '-asc') :
            setSortType(headingInfo.field + '-desc');

        setSelectedSort(headingInfo);
    }

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    // setup of filters
    const filterInfo: FilterInfo = { items: structuresStore.structures, filterValue: filterValue, isItemsObject: true, objectFieldNames: [ 'name', 'type', 'projectName'] };
    const filterStructures = useItemsFilter(filterInfo);

    // setup sorting initial sort is set to 'date' type and using value of 'end date'
    const sortInfo: SortInfo = { items: filterStructures, valueType: selectedSort?.valuetype || 'date', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'end' };
    const sortedStructures = useItemsSort(sortInfo);

    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    {hiddenCols 
                    ? 
                    <tr>
                        {   theads.structures.map(h => {
                                return (
								(h.id !== 3 && h.title != '' || (h.title == '' && permissionsStore.canEditSturctures)) &&
                                    <th key={h.id} colSpan={h.colspan} onClick={() => h.sort ? handleSort(h) : null}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title === 'view' ? '' : h.title}&nbsp;</div>
                                            { showSort && h.sort ? 
                                            h.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                            h.field?.toLowerCase()+'-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> : 
                                            <FontAwesomeIcon icon={faSort} /> : null}
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    :
                    <tr>
                        {   theads.structures.map(h => {
                                return (
                                (h.title != '' || (h.title == '' && permissionsStore.canEditSturctures)) &&
                                    <th key={h.id} colSpan={h.colspan} onClick={() => h.sort ? handleSort(h) : null}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title === 'view' ? '' : h.title}&nbsp;</div>
                                            { showSort && h.sort ? 
                                            h.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                            h.field?.toLowerCase()+'-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> : 
                                            <FontAwesomeIcon icon={faSort} /> : null}
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    }
                    
                </thead>
                {
                    structuresStore.loading ? 
                    <tbody>
                        <tr className={styles['row-spinner']}><td colSpan={13}>
                            <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                <div className={styles.spinner}></div>
                            </div>
                        </td></tr>
                    </tbody> :
                    <tbody>
                        {
                           sortedStructures.map(s => (
                                <tr key={s.id} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === s.id})}>
                                    <td onClick={() => history.push(`/structures/${encodeURIComponent(s.id || '')}`)} colSpan={2}><div>{s.name}</div></td>
                                    <td onClick={() => history.push(`/structures/${encodeURIComponent(s.id || '')}`)} colSpan={2}><div>{s.type}</div></td>
                                    <td onClick={() => history.push(`/structures/${encodeURIComponent(s.id || '')}`)} colSpan={2}><div>{s.status}</div></td>
                                    {!hiddenCols && 
                                        <td onClick={() => history.push(`/structures/${encodeURIComponent(s.id || '')}`)} colSpan={2}><div>{s.projectName}</div></td>
                                    }
                                    <td onClick={() => history.push(`/structures/${encodeURIComponent(s.id || '')}`)} colSpan={2}>{`${s.latitude} / ${s.longitude} `}</td>
                                    <td onClick={() => history.push(`/structures/${encodeURIComponent(s.id || '')}`)} colSpan={2}><div>{s.scheduledStartDate ? dayjs(s.scheduledStartDate).format('MM/DD/YYYY') : null}</div></td>
                                     {permissionsStore.canEditSturctures ? 
                                        <td>
                                            <ActionMenu structure={s} setShowModal={setShowModal} setShowDeleteModal={setShowDeleteModal} setSelectedStructure={setSelectedStructure} setSelectedRow={setSelectedRow} canEdit={permissionsStore.canEditSturctures} />
                                        </td> : 
                                        ""
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                }
            </table>
            {showModal && <Modal title='Edit Structure' closeModal={() => setShowEditModal(true)}><StructureForm structure={selectedStructure || undefined} isExisting={true}  showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} project={project} /></Modal>}
            {showDeleteModal && <DeleteModal title='Delete Structure' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={selectedStructure?.name || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(selectedStructure){dispatch(deleteStructure(selectedStructure))}setShowDeleteModal(false);}}/></DeleteModal> }
        </div>
    )
}

export default StructuresTable