import { useState } from 'react';
import { Avatar, Menu, MenuItem, Divider } from '@mui/material';
import { User } from '../users/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { updateSelectedProject } from '../../store/actions/actions';

import styles from './profile.module.scss';
import { Modal } from '../../components/modal/Modal';
import UserForm from '../../components/forms/UserForm';

type Props = ({ profile: User });
const Profile = ({ profile }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
    const [showDiscard, setShowDiscard] = useState<boolean>(false);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
	const clearSelectedProject = () => {
		profile.selectedProjectId = undefined;
		profile.selectedProjectName = undefined;
		dispatch(updateSelectedProject(profile));
	};

    return (
		<div className={styles.profile}>
			{profile && (
				<div className={styles.info}>
					<div>{`${profile.firstName} ${profile.lastName}`}&nbsp;</div>
					<Avatar
						className={styles.avatar}
						sx={{ bgcolor: '#666666' }}
						onClick={handleClickListItem}>
						<FontAwesomeIcon icon={faUser} />
					</Avatar>
					<Menu
						id='profile-menu'
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'lock-button',
							role: 'listbox'
						}}>
						<MenuItem sx={{ opacity: '1 !important' }} disabled>
							<div className={styles['profile-item-info']}>
								<Avatar sx={{ bgcolor: '#666666', marginRight: '10px' }}>
									<FontAwesomeIcon icon={faUser} />
								</Avatar>
								<div>
									<div
										className={
											styles['profile-main-item']
										}>{`${profile.firstName} ${profile.lastName}`}</div>
									<div className={styles['profile-sub-item']}>
										{profile.email}
									</div>
									{profile.phoneNumber && (
										<div className={styles['profile-sub-item']}>
											{profile.phoneNumber}
										</div>
									)}
								</div>
							</div>
						</MenuItem>
						<Divider />
						<MenuItem sx={{ opacity: '1 !important' }} disabled>
							<div>
								<div className={styles['profile-main-item']}>{profile.company}</div>
								<div className={styles['profile-sub-item']}>{profile.title}</div>
							</div>
						</MenuItem>
						{profile.selectedProjectName && (
							<MenuItem sx={{ opacity: '1 !important' }}>
								<div>
									<div className={styles['profile-main-item']}>
										{profile.selectedProjectName}&nbsp;<FontAwesomeIcon className={styles.icon} icon={faTimesCircle} onClick={() => clearSelectedProject()} />
									</div>
								</div>
							</MenuItem>
						)}
						<MenuItem
							sx={{ display: 'flex', justifyContent: 'flex-end' }}
							onClick={() => {
								setShowEditProfile(true);
								handleClose();
							}}>
							<div className={styles['profile-edit-item']}>Edit</div>
						</MenuItem>
					</Menu>
				</div>
			)}
			{showEditProfile && (
				<Modal title='Edit Profile' closeModal={() => setShowDiscard(true)}>
					<UserForm
						user={profile}
						isExisting={true}
						isViewMode={false}
						showEModal={showDiscard}
						closeModal={setShowDiscard}
						discard={() => {
							setShowDiscard(false);
							setShowEditProfile(false);
						}}
						isProfile={true}
					/>
				</Modal>
			)}
		</div>
	);
}

export default Profile;