import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import styles from './projects.module.scss';
import { fetchProjects } from './actions';
import { ProjectState } from './interfaces';
import { ProjectCardInfo, ProjectCardActionInfo, ProjectCardHeaderInfo } from '../../components/cards/ProjectsCard';
import Card, { DummyCard } from '../../components/cards/Card';
import ProjectsTable from '../../components/tables/ProjectsTable';
import CardPagination from '../../components/pagination/card-pagination';
import TablePagination from '../../components/pagination/table-pagination';
import { Modal } from '../../components/modal/Modal';
import ProjectForm from '../../components/forms/ProjectForm';
import Title from '../../components/title/Title';
import { FilterInfo, useItemsFilter } from '../../hooks/hooks';

const Projects = () => {
    const dispatch = useDispatch();

    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [showEditModal, setShowEditModal] = useState<boolean>(false); 

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);

    type PermissionsStore = { canEditProjects: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    useEffect(()=> {   
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    useEffect(() => {
        dispatch(fetchProjects(10, pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }
    // setup of filters
    const filterInfo: FilterInfo = {items: projectsStore.projectsinfo.projects, filterValue: search, isItemsObject: true, objectFieldNames: ['id', 'name', 'type', 'status'] };
    const filterProjects = useItemsFilter(filterInfo);

    const Cards = (
        <div>
            <div className={styles['cards-container']}>
                <div className={styles.cards}>
                    {
                        filterProjects.map(p => 
                            <Card key={p.id} 
                                title={p.name} 
                                subtitle={p.type} 
                                body={<ProjectCardInfo project={p} />} 
                                actions={<ProjectCardActionInfo project={p} />}
                                headerinfo={<ProjectCardHeaderInfo project={p} />} />)
                    }
                    {(filterProjects.length % 2) ? <DummyCard /> : null}
                </div>
            </div>
            <CardPagination 
                totalPages={Math.floor(projectsStore.projectsinfo.resultstotal % 10 > 0 ? (projectsStore.projectsinfo.resultstotal / 10) + 1 : projectsStore.projectsinfo.resultstotal / 10)} 
                currentPage={pageNumber} 
                setCurrentPage={setPageNumber} />
        </div>
    )
    const Table = (
        <div>
            <ProjectsTable projects={filterProjects} loading={projectsStore.projectsinfo.loading} error={projectsStore.projectsinfo.error} />
            {projectsStore.projectsinfo?.resultstotal && projectsStore.projectsinfo.resultstotal > 10 && 
            <TablePagination totalPages={Math.floor(projectsStore.projectsinfo.resultstotal % 10 > 0 ? (projectsStore.projectsinfo.resultstotal / 10) + 1 : projectsStore.projectsinfo.resultstotal / 10)} currentPage={pageNumber} setCurrentPage={setPageNumber} totalResults={projectsStore.projectsinfo.resultstotal} />}
        </div>
    )
    return (
        <div className={styles['projects-container']}>
            <Title headingTitle='Projects' addItemText={permissionsStore.canEditProjects ? 'New Project' : ''} setShowModal={setShowModal} />
            <div className={styles['filter-section']}>
                <FontAwesomeIcon icon={faFilter} />
                <div className={styles['input-item']}>
                    <input type='text' placeholder='filter data' onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            {isCard ? Cards : Table}
            {showModal && permissionsStore.canEditProjects && <Modal title='Create Project' closeModal={() => setShowEditModal(true)}><ProjectForm showEModal={showEditModal} closeModal={handleChange} discard={() => discard()}/></Modal>}
        </div>
    )
}

export default Projects;