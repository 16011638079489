import styles from './switch.module.scss';

type Props = ({ isSwitchSet: boolean, setSwitch: any, onText: string, offText: string, switchText?: string });
const Switch = ({ isSwitchSet, setSwitch, onText, offText, switchText }: Props) => {

    return (
        <div className={styles['switch-item']}>
            <label className={`${styles.switch} ${styles['btn-color-mode-switch']}`}>
                <input type='checkbox' name='switch-input' id='switch-input' checked={isSwitchSet} onChange={() => setSwitch(!isSwitchSet)} />
                <label htmlFor='switch-input' data-on={onText} data-off={offText} className={styles['btn-color-mode-switch-inner']} />
            </label>
            {switchText && <div className={styles['switch-label']}>{switchText}</div>}
        </div>
    )
}

export default Switch