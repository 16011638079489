import { useSelector } from 'react-redux';
import { User } from '../../views/users/interfaces';

export const CurrentUserDetails = () => {
    
    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;
    const userName =  (profile.firstName ? (profile.firstName) : "")+ " " +(profile.lastName ? (profile.lastName) : ""); 
    const id = profile.id;

    return {
        userName,
        id
    }
     
}

export default CurrentUserDetails;