import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import styles from './reports.module.scss';
import Title from '../../components/title/Title';
import { Modal } from '../../components/modal/Modal';
import { fetchDailyReports } from './actions';
import ReportCards from '../../components/cards/ReportCards';
import ReportsTable from '../../components/tables/ReportsTable';
import CardPagination from '../../components/pagination/card-pagination';
import TablePagination from '../../components/pagination/table-pagination';
import ReportsForm from '../../components/forms/ReportsForm';
import { Project } from '../projects/interfaces';
import { DailyReportState } from './interfaces';
import { User } from '../users/interfaces';

type Props = ({ showSort: boolean, showTitle: boolean, project?: Project, projectId?: string });
export const ReportsContent = ({ showSort, showTitle, project, projectId }: Props) => {
    const dispatch = useDispatch();

    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

	type ProfileStore = { profile: User; loading: boolean; error: any };
	const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
	const profile = profileStore.profile;

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    useEffect(() => {
		let projectIdForQuery = projectId !== undefined ? decodeURIComponent(projectId) 
			: profile?.selectedProjectId ? profile.selectedProjectId 
				: '';
        dispatch(
			fetchDailyReports(
				10,
				pageNumber,
				projectIdForQuery,
				projectIdForQuery !== undefined ? false : true
			)
		);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    type PermissionsStore = { canEditDaily: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    const dailyReportsStore = useSelector((state: { dailyReportsStore: DailyReportState }) => state.dailyReportsStore);

    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);

    const Cards = (
        <div className={styles['cards-container']}>
            <div className={styles.cards}>
                <ReportCards filterValue={search} />
            </div>
            <CardPagination totalPages={Math.floor(dailyReportsStore.reportsinfo.resultstotal % 10 > 0 ? (dailyReportsStore.reportsinfo.resultstotal / 10) + 1 : dailyReportsStore.reportsinfo.resultstotal / 10)} currentPage={pageNumber} setCurrentPage={setPageNumber} />
        </div>
    )
    const Table = (
        <div>
            <ReportsTable showSort={showSort} filterValue={search} />
            {dailyReportsStore.reportsinfo?.resultstotal && dailyReportsStore.reportsinfo.resultstotal > 10 ? 
            <TablePagination totalPages={Math.floor(dailyReportsStore.reportsinfo.resultstotal % 10 > 0 ? (dailyReportsStore.reportsinfo.resultstotal / 10) + 1 : dailyReportsStore.reportsinfo.resultstotal / 10)} currentPage={pageNumber} setCurrentPage={setPageNumber} totalResults={dailyReportsStore.reportsinfo.resultstotal} /> : null}
        </div>
    )
    return (
        <div className={styles['reports-container']}>
            <Title headingTitle='Daily Reports' hideTitle={!showTitle} addItemText={permissionsStore.canEditDaily ? 'New Daily Report' : ''} setShowModal={setShowModal} />
            <div className={styles['filter-section']}>
                <FontAwesomeIcon icon={faFilter} />
                <div className={styles['input-item']}>
                    <input type='text' placeholder='filter data' onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            {isCard ? Cards : Table}
            {showModal && <Modal title='Create Daily Report' closeModal={() => setShowEditModal(true)}><div><ReportsForm closeModal={handleChange} showEModal={showEditModal} discard={() => discard()} project={project}/></div></Modal>}
        </div>
    )
}

const Reports = ({ showSort, showTitle, project, projectId }: Props) => {
    const dispatch = useDispatch();
	type ProfileStore = { profile: User; loading: boolean; error: any };
	const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
	const profile = profileStore.profile;

    useEffect(() => {
		let projectIdForQuery =
			projectId !== undefined
				? decodeURIComponent(projectId)
				: profile?.selectedProjectId
				? profile.selectedProjectId
				: '';
        dispatch(fetchDailyReports(10, 1, projectIdForQuery, projectIdForQuery ? false : true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ReportsContent showSort={showSort} showTitle={showTitle} project={project} projectId = {projectId} />
    )
}

export default Reports;