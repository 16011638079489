import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { ModalContext } from './ModalContext';
import ModalHeader from './header/Header';
import ModalBody from './body/Body'
import styles from './modal.module.scss';

export const Modal: React.FC<{ closeModal: any, title: string, isSmallModal?: boolean }> = ({ children, closeModal, title, isSmallModal }) => {
    const modalNode = useContext(ModalContext);

    return modalNode ?
        ReactDOM.createPortal(
            <div className={styles['modal-overlay']}>
                <div className={cx(styles[`modal-container`], { [styles['small-modal']]: isSmallModal })}>
                    <ModalHeader title={title} closeModal={closeModal} />
                    <ModalBody isSmallModal={isSmallModal}>{children}</ModalBody>
                </div>
            </div>,
            modalNode
        ) :
        null
}

export const DeleteModal: React.FC<{ closeModal: any, title: string }> = ({ children, closeModal, title }) => {
    const modalNode = useContext(ModalContext);
    console.log(children);
    return modalNode ?
        ReactDOM.createPortal(
            <div className={styles['delete-modal-overlay']}>
                <div className={styles[`modal-container`]} style={{ minWidth: "46vh" }}>
                    <ModalHeader title={title} closeModal={closeModal} />
                    <ModalBody>{children}</ModalBody>
                </div>
            </div>,
            modalNode
        ) :
        null
}

//Edit Modal
export const EditModal: React.FC<{ closeModal: any, title: string }> = ({ children, closeModal, title }) => {
    const modalNode = useContext(ModalContext);

    return modalNode ?
        ReactDOM.createPortal(
            <div className={styles['delete-modal-overlay']} >
                <div className={styles[`modal-container`]} style={{ minWidth: "46vh" }}>
                    <ModalHeader title={title} closeModal={closeModal} editModal={true} />
                    <ModalBody >{children}</ModalBody>
                </div>
            </div>,
            modalNode
        ) :
        null
}


//OtherContacts Modal
export const OtherContactModel: React.FC<{ closeModal: any, title: string, data: any }> = ({ children, closeModal, title, data }) => {
    const modalNode = useContext(ModalContext);
    return modalNode ?
        ReactDOM.createPortal(
            <div className={styles['delete-modal-overlay']} >
                <div className={styles[`modal-container`]} style={{ minWidth: "46vh" }}>
                    <div className='otherContact-main-modal'>
                        <ModalHeader title={title} closeModal={closeModal} editModal={true} />
                        {/* <ModalBody >{children}</ModalBody>  */}
                        {data.allContacts && data.allContacts.map((data: any, i: number) => {
                            return (
                                <>
                                    <div className='otherContact-modal'>
                                        <div>{data.fullName}</div>
                                        <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
                                        <a href={`mailto:${data.email}`}>{data.email}</a>
                                    </div>
                                </>
                            )
                        })}
                      {data.allContacts? ''  : <div className='otherContact-availity'>Not available</div> }
                    </div>
                </div>
            </div>,
            modalNode
        ) :
        null
}
