import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';

import { updateTimer } from '../../../../views/tasks/actions';
import { Task } from '../../../../views/tasks/interfaces';
import { EditModal } from '../../../modal/Modal';
import EditPopup from '../../../popup/EditPopup';
import { TimerStatus } from '../../../../views/tasks/interfaces';
import { fetchStructure } from './../../../../views/structures/actions';

import styles from './startstop.module.scss';

type Props = ({ task: Task, elapsedTime: number, setElapsedTime(arg: number): void, useSmallIcons?: boolean });
const StartStop = ({ task, elapsedTime, setElapsedTime, useSmallIcons = false }: Props) => {
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState<boolean>(task.timerStatus?.toLowerCase() === TimerStatus.InProgress.toLowerCase());
    const [timerStatus, setTimerStatus] = useState<string>(task.timerStatus || 'Pending');
    // const [status, setStatus] = useState<string>(task.status || 'Pending');
    const [showEditModal, setShowEditModal] = useState<boolean>(false);  
    const confirmation = `Are you sure you want to complete this task - ${task.name}?`

    useInterval(
        () => {
            setElapsedTime(elapsedTime + 1)
        }, 
        isActive ? 1000 : null
    )

    const handleActionClick = (action: string) => {
        const currentTask = {...task};
        switch (action) {
            case 'started':
                currentTask.startTime = new Date();
                currentTask.elapsedTime = 0;
                currentTask.endTime = undefined;
                currentTask.status = 'In Progress';
                currentTask.timerStatus = TimerStatus.InProgress

                // setStatus('In Progress');
                setTimerStatus(TimerStatus.InProgress);
                setIsActive(true);
                break;
            case 'continued':
                currentTask.elapsedTime = elapsedTime;
                currentTask.pausedTime = new Date();
                currentTask.endTime = undefined;
                currentTask.status = 'In Progress';
                currentTask.timerStatus = TimerStatus.InProgress;

                // setStatus('In Progress');
                setTimerStatus(TimerStatus.InProgress);
                setIsActive(true);
                break;
            case 'stopped':
                setShowEditModal(true);
                return 1;               
            case 'paused':
                currentTask.elapsedTime = elapsedTime
                currentTask.pausedTime = new Date();
                currentTask.endTime = undefined;
                currentTask.status = 'Paused';
                currentTask.timerStatus = TimerStatus.Paused

                // setStatus('Paused');
                setTimerStatus(TimerStatus.Paused);
                setIsActive(false);
                break;
            default:
                break;
        }
        dispatch(updateTimer(currentTask));
    }

    const completeTask = () => {
        const currentTask = {...task};
        currentTask.elapsedTime = elapsedTime;
        currentTask.endTime = new Date();
        currentTask.status = 'Completed'
        currentTask.timerStatus = TimerStatus.Stopped;

        // setStatus('Completed');
        setTimerStatus(TimerStatus.Stopped);
        setIsActive(false);
        setShowEditModal(false);
        dispatch(updateTimer(currentTask));
         //Timeout to wait for task updated and set the state of task
        setTimeout(() =>{
            dispatch(fetchStructure(25, 1, currentTask.projectId));
        }, 500);
    };

    return (
        <>
        {/* {   (status?.toLocaleLowerCase() === 'completed') || (status?.toLocaleLowerCase() === 'completed late') ?  */}
        {   timerStatus?.toLowerCase() === TimerStatus.Stopped.toLowerCase() ? null : 
            timerStatus?.toLowerCase() === TimerStatus.Pending.toLowerCase() || elapsedTime === 0 ?
            <button 
                type='button' 
                className={`${useSmallIcons ? styles['small-btn'] : styles.btn} ${styles['play']}`}
                onClick={() => handleActionClick('started')}></button> : 
            timerStatus?.toLowerCase() === TimerStatus.Paused.toLowerCase() ? 
            <>
                <button 
                    type='button' 
                    className={`${useSmallIcons ? styles['small-btn'] : styles.btn} ${styles['play']}`}
                    onClick={() => handleActionClick('continued')}></button>
                <button 
                    type='button' 
                    className={`${useSmallIcons ? styles['small-btn'] : styles.btn} ${styles['stop']}`}
                    onClick={() => handleActionClick('stopped')}></button>
            </> : 
            timerStatus?.toLowerCase() === TimerStatus.InProgress.toLowerCase() ? 
            <>
                <button 
                    type='button' 
                    className={`${useSmallIcons ? styles['small-btn'] : styles.btn} ${styles['pause']}`}
                    onClick={() => handleActionClick('paused')}></button>
                <button 
                    type='button' 
                    className={`${useSmallIcons ? styles['small-btn'] : styles.btn} ${styles['stop']}`}
                    onClick={() => handleActionClick('stopped')}></button>
            </> : 
            null
        }
        { (showEditModal) && 
        <EditModal title='COMPLETE TASK' closeModal={() =>{setShowEditModal(false)}}><EditPopup customMessage={confirmation} closeModal={() =>{}} editEntity={() =>{completeTask()}} discardChanges = {() => {setShowEditModal(false)}}/></EditModal>}
        </>
    )
}

export default StartStop;