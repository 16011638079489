import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import styles from './cards.module.scss';
import Card, { DummyCard } from './Card';
import { FilterInfo, useItemsFilter } from '../../hooks/hooks';
import { DailyReportSummary } from '../../views/reports/interfaces';
import { Modal } from '../modal/Modal';
import ReportsForm from '../forms/ReportsForm';

type Props = ({ report: DailyReportSummary});

export const ReportCardInfo = ({ report }: Props) => {
    return (
        <div>{report.notes}</div>
    )
}
export const ReportCardHeaderInfo = ({ report }: Props) => {
    return (
        <div>
            <div className={styles.info}>
                <div className={styles['info-item']}>{dayjs(report.reportDate).format('MM/DD/YYYY')}</div>
            </div>
        </div>
    )
}
export const ReportCardActionInfo = ({ report }: Props) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type PermissionsStore = { canEditDaily: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    return (
        <>
            <button type='button' className={styles.btn} onClick={() => history.push(`/reports/${encodeURIComponent(report.id || '')}`)}>View</button>
            {   permissionsStore.canEditDaily && 
                <>
                    <button type='button' className={styles.btn} onClick={() => { setShowModal(true); }}>Edit</button>
                    { showModal && permissionsStore.canEditDaily && <Modal title='Edit Project' closeModal={() => setShowEditModal(true)}><ReportsForm dailyReport={report} isExisting={true} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal> }
                </>
            }
        </>
    )
}

type CardsProps = ({ filterValue: string });
const ReportCards = ({ filterValue }: CardsProps) => {
    type DailyReportsStore = { reportsinfo: { dailyreports: DailyReportSummary[], loading: boolean, error: any } };
    const dailyReportsStore = useSelector((state: { dailyReportsStore: DailyReportsStore }) => state.dailyReportsStore);

    // setup of filters
    // setup of filters
    const filterInfo: FilterInfo = { items: dailyReportsStore.reportsinfo.dailyreports, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['id', 'name', 'type'] };
    const filterReports = useItemsFilter(filterInfo);

    return (
        <>
            {filterReports.map(r => <Card 
                                            key={r.id} 
                                            title={r.projectName}
                                            subtitle={r.authorName}
                                            headerinfo={<ReportCardHeaderInfo report={r} />}
                                            actions={<ReportCardActionInfo report={r} />}
                                            body={<ReportCardInfo report={r} />} 
                                        />)}
            {(dailyReportsStore.reportsinfo.dailyreports.length % 2) ? <DummyCard /> : null}
        </>
    )
}

export default ReportCards;