import React from 'react';

type Props = ({placeholder: string, number: string, setSelected: any, isViewMode: boolean});
const Phone = ({placeholder, number, setSelected, isViewMode}: Props) => {
    const formatPhone = (value?: string) => {
        if (!value) return value;

        const number = value.replace(/[^\d]/g, '');

        if (number.length < 4) return number;
        if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`;

        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
    }

    return (
        <input type='text' placeholder={placeholder} maxLength={14} value={formatPhone(number)} onChange={(e) => setSelected(e.target.value)} readOnly={isViewMode}/>
    )
}

export default Phone;