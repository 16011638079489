
import styles from './pagination.module.scss';

type Props = ({ totalPages: number, currentPage: number, setCurrentPage(arg: number): void });
const CardPagination = ({ totalPages, currentPage, setCurrentPage }: Props) => {
    return (
        <ul className={`${styles['page-numbers']} ${styles['card-navigation']}`}>
            {currentPage !== 1 && <li onClick={() => setCurrentPage(currentPage - 1)}><div className={`${styles['left-arrow']} ${styles['card-arrow-left']}`}></div></li>}
            {currentPage !== totalPages && <li onClick={() => setCurrentPage(currentPage + 1)}><div className={`${styles['right-arrow']} ${styles['card-arrow-right']}`}></div></li>}
        </ul>
    )
}

export default CardPagination;