import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './cards.module.scss';
import Card, { DummyCard } from './Card';
import { FilterInfo, useItemsFilter } from '../../hooks/hooks';
import { Structure } from '../../views/structures/interfaces';
import StructureForm from '../forms/StructureForm';
import { DeleteModal, Modal } from '../modal/Modal';
import { deleteStructure } from '../../views/structures/actions';
import DeletePopup from '../popup/DeletePopup';
import {Dashboard} from '../../views/home/interfaces';
import dayjs from 'dayjs';
type CardProps = ({ structure: Structure });
export const StructureCardInfo = ({ structure }: CardProps) => {
    return (
        <div className={styles['timeline']}>
            <div className={styles['events']}>
                <ol>
                    <ul>
                        <li><a href='#0'>{structure?.scheduledStartDate ? new Date(structure.scheduledStartDate).toLocaleDateString() : 'unknown'}</a></li>
                        <li><a href='#1' className={styles.selected}>{new Date().toLocaleDateString()}</a></li>
                        <li><a href='#2'>{structure?.scheduledEndDate ? new Date(structure.scheduledEndDate).toLocaleDateString() : 'unknown'}</a></li>
                    </ul>
                </ol>
            </div>
        </div>
    )
}
export const StructureCardActionInfo = ({structure}: CardProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type PermissionsStore = { canEditSturctures: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    return (
        <>
            <button type='button' className={styles.btn} onClick={() => history.push(`/structures/${encodeURIComponent(structure.id || '')}`)}>View</button>
            {   permissionsStore.canEditSturctures && 
                <>
                    <button type='button' className={styles.btn} onClick={() => { setShowModal(true); }}>Edit</button>
                    <button type='button' className={styles.btn} onClick={() => { setShowDeleteModal(true); }}>Delete</button>
                    { showModal && permissionsStore.canEditSturctures && <Modal title='Edit Structure' closeModal={() => setShowEditModal(true)}><StructureForm structure={structure} isExisting={true} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal> }
                    {showDeleteModal && <DeleteModal title='Delete Structure' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={structure?.name || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(structure){dispatch(deleteStructure(structure))}setShowDeleteModal(false);}}/></DeleteModal> }
                </>
            }
        </>
    )
}

export const StructureCard = ({ structure }: CardProps) => {
    const formatDuration = (structureDuration: number | undefined) => {
        return structureDuration ? `${(Math.floor((structureDuration / 3600)))}:${('0' + Math.floor((structureDuration / 60) % 60)).slice(-2)}:${('0' + Math.floor((structureDuration / 1) % 60)).slice(-2)}` : '';
    };
    return (
        <div>
                <div className={styles['card-additional-info']}>
                    <div>Project Name: <span><b>{structure.projectName}</b></span></div>
                    <div>Status: <span><b>{structure.status}</b></span></div>
                    <div>Scheduled Start Date: <span><b>{structure.scheduledStartDate ? dayjs(structure.scheduledStartDate).format('MM/DD/YYYY') : ""}</b></span></div>
                    <div>Estimate Duration: <span><b>{formatDuration(structure?.aggregateEstimatedDuration)}</b></span></div>
                    <div>Aggregate Duration: <span><b>{formatDuration(structure?.aggregateDuration)}</b></span></div>
                </div>
                {structure?.type?.toLocaleLowerCase() === 'drilled pier' ? 
                <Pier currentStructure={structure}  /> : 
                structure?.type?.toLocaleLowerCase() === 'slab' ? 
                <Slab currentStructure={structure}  /> : 
                structure?.type?.toLocaleLowerCase() === 'slab on pier' ? 
                <div className={styles['multi-dimension-container']}><Slab currentStructure={structure} /><Pier currentStructure={structure} /></div> : 
                structure?.type?.toLocaleLowerCase() === 'slab on beam' ? 
                <div className={styles['multi-dimension-container']}><Slab currentStructure={structure} /><Beam currentStructure={structure} /></div> : 
                structure?.type?.toLocaleLowerCase() === 'wall' ? 
                <Wall currentStructure={structure} /> : 
                structure?.type?.toLocaleLowerCase() === 'direct embed' ? 
                <Direct currentStructure={structure}  /> : 
                structure?.type?.toLocaleLowerCase() === 'prefab install' ? 
                <Prefab currentStructure={structure}  /> : 
                structure?.type?.toLocaleLowerCase() === 'conduit' ? 
                <Conduit currentStructure={structure} /> : 
                structure?.type?.toLocaleLowerCase() === 'ground grid' ? 
                <Ground currentStructure={structure} /> :
                structure?.type?.toLocaleLowerCase() === 'other' ? 
                <Other currentStructure={structure} /> : 
                null
            }
        </div>
    )
}

type CardsProps = ({ filterValue: string, projectId? :string, structureId? :string })
const StructureCards = ({ filterValue, projectId, structureId }: CardsProps) => {
    type StructuresStore = { structures: Structure[], loading: boolean, error: any };
    const structuresStore = useSelector((state: { structuresStore: StructuresStore }) => state.structuresStore);

    type HomeStore = { data: Dashboard, loading: boolean, error: any };
    const homeStore = useSelector((state: { homeStore: HomeStore }) => state.homeStore);

    if (window.location.href.split('/').slice(-1)[0] === 'home') {
        structuresStore.structures = homeStore.data.structures;
    }

    if (projectId) {
        structuresStore.structures = homeStore.data.structures.filter((structure) => {
            return structure.projectId === projectId;
        });
    }

    if(structureId)
    {
        structuresStore.structures = homeStore.data.structures.filter((structure) => {
            return structure.id === structureId;
        })
    }

    // setup of filters
    const filterInfo: FilterInfo = { items: structuresStore.structures, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['id', 'name', 'type'] };
    const filterStructures = useItemsFilter(filterInfo);

    return (
        <>
            {filterStructures.map(s => <Card 
                                            key={s.id} 
                                            title={s.name}
                                            subtitle={s.type}
                                            actions={<StructureCardActionInfo structure={s} />}
                                            body={<StructureCard structure={s}/>} 
                                        />)}
            {(structuresStore.structures.length % 2) ? <DummyCard /> : null}
        </>
    )
}

type DimensionProps = ({ currentStructure: Structure });
const Slab = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Slab Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>LENGTH:</div>
                <div>{currentStructure?.length ? `${currentStructure?.length} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>WIDTH:</div>
                <div>{currentStructure?.width ? `${currentStructure?.width} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>DEPTH:</div>
                <div>{currentStructure?.depth ? `${currentStructure?.depth} (ft)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Pier = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>{currentStructure?.type === 'Slab on Pier' ? 'Pier' : 'Drilled Pier'} Dimensions</div>
        {   currentStructure?.type === 'Slab on Pier' ?
            <div className={styles['dimensions']}>
                <div className={styles['item']}>
                    <div>DIAMETER: </div>
                    <div>{currentStructure?.diameter ? `${currentStructure?.diameter} (in)`: 'Not Available'}</div>
                </div>
                <div className={styles['item']}>
                    <div>DEPTH:</div>
                    <div>{currentStructure?.depth ? `${currentStructure?.depth} (ft)` : 'Not Available'}</div>
                </div>
                <div className={styles['item']}>
                    <div>QUANTITY:</div>
                    <div>{currentStructure?.quantity || 'Not Available'}</div>
                </div>
            </div>
            :
            <div className={styles['dimensions']}>
                <div className={styles['item']}>
                    <div>DIAMETER: </div>
                    <div>{currentStructure?.diameter ? `${currentStructure?.diameter} (in)` : 'Not Available'}</div>
                </div>
                <div className={styles['item']}>
                    <div>DEPTH + REVEAL:</div>
                    <div>{currentStructure?.depth ? `${currentStructure?.depth} (ft)` : 'Not Available'}</div>
                </div>
            </div>
        }
    </div>
);
const Beam = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Beam Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>TOTAL LENGTH:</div>
                <div>{currentStructure?.length2 ? `${currentStructure?.length2} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>AVG WIDTH:</div>
                <div>{currentStructure?.width2 ? `${currentStructure?.width2} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>AVG DEPTH:</div>
                <div>{currentStructure?.depth2 ? `${currentStructure?.depth2} (ft)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Wall = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Wall Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>LENGTH:</div>
                <div>{currentStructure?.length ? `${currentStructure?.length} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>THICKNESS:</div>
                <div>{currentStructure?.thickness ? `${currentStructure?.thickness} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>HEIGHT:</div>
                <div>{currentStructure?.height ? `${currentStructure?.height} (ft)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Direct = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Direct Embed Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>DIAMETER: </div>
                <div>{currentStructure?.diameter ? `${currentStructure?.diameter} (in)`: 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>DEPTH:</div>
                <div>{currentStructure?.depth ? `${currentStructure?.depth} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>ANNULUS:</div>
                <div>{currentStructure?.annulus ? `${currentStructure?.annulus} (in)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Prefab = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Prefab Install Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>LENGTH:</div>
                <div>{currentStructure?.length ? `${currentStructure?.length} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>WIDTH:</div>
                <div>{currentStructure?.width ? `${currentStructure?.width} (ft)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Conduit = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Conduit Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>LENGTH:</div>
                <div>{currentStructure?.length ? `${currentStructure?.length} (ft)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Ground = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Ground Grid Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>LENGTH:</div>
                <div>{currentStructure?.length ? `${currentStructure?.length} (ft)` : 'Not Available'}</div>
            </div>
        </div>
    </div>
);
const Other = ({ currentStructure }: DimensionProps) => (
    <div>
        <div className={styles['dimensions-title']}>Other Dimensions</div>
        <div className={styles['dimensions']}>
            <div className={styles['item']}>
                <div>LENGTH:</div>
                <div>{currentStructure?.length ? `${currentStructure?.length} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>WIDTH:</div>
                <div>{currentStructure?.width ? `${currentStructure?.width} (ft)` : 'Not Available'}</div>
            </div>
            <div className={styles['item']}>
                <div>HEIGHT:</div>
                <div>{currentStructure?.height ? `${currentStructure?.height} (ft)` : 'Not Available'}</div>
            </div>
            {currentStructure?.thickness && <div className={styles['item']}>
                <div>THICKNESS:</div>
                <div>{currentStructure?.thickness}(ft)</div>
            </div>}
            {currentStructure.depth && <div className={styles['item']}>
                <div>DEPTH:</div>
                <div>{currentStructure?.depth}(ft)</div>
            </div>}
            {currentStructure.diameter && <div className={styles['item']}>
                <div>DIAMETER:</div>
                <div>{currentStructure?.diameter}(in)</div>
            </div>}
            {currentStructure.annulus && <div className={styles['item']}>
                <div>ANNULUS:</div>
                <div>{currentStructure?.annulus}(in)</div>
            </div>}
            {currentStructure.quantity && <div className={styles['item']}>
                <div>QUANTITY:</div>
                <div>{currentStructure?.quantity}</div>
            </div>}
            {currentStructure.length2 && <div className={styles['item']}>
                <div>LENGTH 2 (ft):</div>
                <div>{currentStructure?.length2}(ft)</div>
            </div>}
            {currentStructure.width2 && <div className={styles['item']}>
                <div>WIDTH 2 (ft):</div>
                <div>{currentStructure?.width2}(ft)</div>
            </div>}
            {currentStructure.depth2 && <div className={styles['item']}>
                <div>DEPTH 2 (ft):</div>
                <div>{currentStructure?.depth2}(ft)</div>
            </div>}
        </div>
    </div>
);
export default StructureCards;

