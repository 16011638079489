import { RECCE_USER_SELECT_PROJECT, RECCE_USER_ZOOM_LEVEL } from './../api/endPoints';
import axios, { AxiosError } from 'axios';
import { AuthenticationResult } from '@azure/msal-browser';

import { GetProfile } from './../../views/home/interfaces';
import { RECCE_PROFILE } from '../api/endPoints';
import { ADMIN, CLIENT } from '../../constants/roles';
import { FETCH_PROFILE, SET_ADMIN_PERMISSIONS, SET_FIELD_USER_PERMISSIONS, SET_CLIENT_PERMISSIONS, UPDATE_SELECTED_PROJECT_API, UPDATE_SELECTED_PROJECT_LOCAL, UPDATE_MAP_ZOOM_LEVEL_API, UPDATE_MAP_ZOOM_LEVEL_LOCAL } from './actionTypes';
import { User } from '../../views/users/interfaces';

export const fetchProfile = (request: GetProfile, auth: AuthenticationResult) => {
    sessionStorage.setItem('token', auth.accessToken);
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_PROFILE, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROFILE}`, request, svcconfig(auth.accessToken))
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    if (resp?.data?.data?.role) {
                        switch (resp.data.data.role.toUpperCase()) {
                            case ADMIN: 
                                dispatch({
                                    type: SET_ADMIN_PERMISSIONS, 
                                    payload: null
                                });
                                break;
                            case CLIENT: 
                                dispatch({
                                    type: SET_CLIENT_PERMISSIONS, 
                                    payload: null
                                })
                                break;
                            default: 
                                dispatch({
                                    type: SET_FIELD_USER_PERMISSIONS, 
                                    payload: null
                                })
                                break;
                        }
                    }
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const updateSelectedProject = (request: User) => {
	return (dispatch: (arg: { type: string; payload: any }) => void) => {
		dispatch({
			type: UPDATE_SELECTED_PROJECT_API,
			payload: axios
				.post(
					`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USER_SELECT_PROJECT}`,
					{
						userId: request.id,
						selectedProjectId: request.selectedProjectId,
						selectedProjectName: request.selectedProjectName
					},
					svcconfig()
				)
				.then((resp) => {
					if (!resp.statusText && resp.statusText !== 'OK') return [];
					if (resp?.data?.data) {
						dispatch({
							type: UPDATE_SELECTED_PROJECT_LOCAL,
							payload: request
						});
					}
					return resp.data;
				})
				.catch((error: AxiosError) => {
					if (error?.response?.status === 401) {
						sessionStorage.removeItem('token');
						window.location.href = '/logout';
					}
					return { data: undefined, error: error };
				})
		});
	};
};

export const updateZoomLevel = (request: User) => {
	return (dispatch: (arg: { type: string; payload: any }) => void) => {
		dispatch({
			type: UPDATE_MAP_ZOOM_LEVEL_API,
			payload: axios
				.post(
					`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USER_ZOOM_LEVEL}`,
					{
						userId: request.id,
						mapZoomLevel: request.mapZoomLevel
					},
					svcconfig()
				)
				.then((resp) => {
					if (!resp.statusText && resp.statusText !== 'OK') return [];
					if (resp?.data?.data) {
						dispatch({
							type: UPDATE_MAP_ZOOM_LEVEL_LOCAL,
							payload: request
						});
					}
					return resp.data;
				})
				.catch((error: AxiosError) => {
					if (error?.response?.status === 401) {
						sessionStorage.removeItem('token');
						window.location.href = '/logout';
					}
					return { data: undefined, error: error };
				})
		});
	};
};

export const svcconfig = (token?: string) => {
    const authToken = token || sessionStorage.getItem('token');
    return {
        headers: {'Pragma': 'no-cache',  'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}`}
    }
};
export const fileuploadconfig = (token?: string) => {
    const authToken = token || sessionStorage.getItem('token');
    return {
        headers: {'Pragma': 'no-cache',  'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${authToken}`}
    }
}