import styles from './heading.module.scss';

type Props = ({ title: string, isClickable?: boolean, clickAction?: any });
const Heading = ({ title, isClickable, clickAction } : Props) => (
    <div className={styles.heading}>
        {   isClickable ?
            <label className={styles.click} onClick={() => clickAction()}>{title}</label> : 
            <label>{title}</label>
        }
    </div>
)

export default Heading;