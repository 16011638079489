// recce api endpoints
export const RECCE_HOME = '/home';
export const RECCE_DASHBOARD = '/home/dashboard';
export const RECCE_PROFILE = '/home/profile';
export const RECCE_PROJECTS = '/projects';
export const RECCE_PROJECT_DETAIL = '/projects/enriched';
export const RECCE_STRUCTURES = '/structures';
export const RECCE_STRUCTURES_CSV_UPLOAD = '/structures/uploadcsv';
export const RECCE_STRUCTURES_UPLOAD = '/structures/uploadimage';
export const RECCE_DAILY_REPORTS = '/dailyreports';
export const RECCE_DAILY_REPORT_DETAIL = '/dailyreports/enriched';
export const RECCE_DAILY_REPORT_INITIALIZE = '/dailyreports/Initialize';
export const RECCE_USERS = '/users';
export const RECCE_USER_SELECT_PROJECT = '/users/SelectProject'
export const RECCE_USER_ZOOM_LEVEL = '/users/Zoom';
export const RECCE_TASK = '/tasks';
