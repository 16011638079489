
import { FETCH_PROJECTS, FETCH_PROJECT_DETAIL, CREATE_PROJECT, DELETE_PROJECT, SAVE_PROJECT } from '../../store/actions/actionTypes';
import { ProjectState } from './interfaces';

const initialState: ProjectState = {
    projectsinfo: {
        projects: [], 
        loading: false, 
        error: undefined, 
        pagesize: 0,
        pagenumber: 0,
        pagecount: 0, 
        resultstotal: 0
    }, 
    selectedprojectinfo: {
        project: undefined, 
        loading: false, 
        error: undefined
    }
}

const projectsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${FETCH_PROJECTS}_PENDING`: {
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: true
                }
            }
        }
        case `${FETCH_PROJECTS}_FULFILLED`: {
            return {
                ...state, 
                projectsinfo: {
                    projects: action.payload.data, 
                    loading: false, 
                    error: action?.payload?.error, 
                    pagesize: action.payload.pageSize,
                    pagenumber: action.payload.pageNumber,
                    pagecount: action.payload.pageCount, 
                    resultstotal: action.payload.resultsTotal
                }
            }
        }
        case `${FETCH_PROJECT_DETAIL}_PENDING`: {
            return {
                ...state, 
                selectedprojectinfo: {
                    ...state.selectedprojectinfo, 
                    loading: true
                }
            }
        }
        case `${FETCH_PROJECT_DETAIL}_FULFILLED`: {
            return {
                ...state, 
                selectedprojectinfo: {
                    project: action.payload.data, 
                    loading: false, 
                    error: null
                }
            }
        }
        case `${DELETE_PROJECT}_PENDING`: {
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: true
                }
            }
        }
        case `${DELETE_PROJECT}_FULFILLED`: {
            state.projectsinfo.projects.splice(state.projectsinfo.projects.findIndex(p => p.id === action.payload.id), 1);
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: false
                }
            }
        }
        case `${SAVE_PROJECT}_PENDING`: { 
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: true
                }
            }
        }
        case `${SAVE_PROJECT}_FULFILLED`: {            
            state.projectsinfo.projects.splice(state.projectsinfo.projects.findIndex(p => p.id === action.payload.data.id), 1);
            state.projectsinfo.projects.push(action.payload.data);
            state.selectedprojectinfo.project = action.payload.data;
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: false
                }
            }
        }
        case `${CREATE_PROJECT}_PENDING`: {
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: true
                }
            }
        }
        case `${CREATE_PROJECT}_FULFILLED`: {
            state.projectsinfo.projects.push(action.payload.data)
            return {
                ...state, 
                projectsinfo: {
                    ...state.projectsinfo, 
                    loading: false
                }
            }
        }
        default: return state;
    }
}

export default projectsReducer;