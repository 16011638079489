import axios, { AxiosError } from 'axios';

import { CREATE_COMMENT, CREATE_TASK, DELETE_TASK, FETCH_TASK, FETCH_DEFAULT_TASKS, SAVE_TASK, UPDATE_TIMER_TASK, FETCH_OVERDUE_TASKS } from '../../store/actions/actionTypes';
import { RECCE_TASK } from '../../store/api/endPoints';
import { AddComment, Task } from './interfaces';
import { svcconfig } from '../../store/actions/actions';

export const fetchTasks = (pagesize: number, pagenumber: number, id?: string, structureId?: string, projectId?: string, status?: string, assignedTo?: string, sorting?: number) => {
    const size = `PageSize=${pagesize}`; const number = `PageNumber=${pagenumber}`; 
    const taskStatus = status && status !== '' ? `&Status=${encodeURIComponent(status)}` : '';
    const assignedToId = assignedTo && assignedTo !== '' ? `&AssignedTo=${encodeURIComponent(assignedTo??'')}` : '';

    let endpoint = id ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}?Ids=${encodeURIComponent(id)}&${size}&${number}` :
                     structureId ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}?StructureIds=${encodeURIComponent(structureId)}&${size}&${number}` + taskStatus + assignedToId : 
                     projectId ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}?ProjectIds=${encodeURIComponent(projectId)}&${size}&${number}` + taskStatus + assignedToId : 
                     `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}?${size}&${number}` + taskStatus + assignedToId;

    if (sorting) endpoint += `&Sorting=${sorting}`;
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_TASK, 
            payload: axios.get(endpoint, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}
export const fetchDefaultTasks = () => {
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_DEFAULT_TASKS, 
            payload: axios.get(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}?IsDefault=true`, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const saveTask = (task: Task) => {
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            
            type: SAVE_TASK, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}`, task, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const createTask = (task: Task) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CREATE_TASK, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}`, task, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const deleteTask = (task: Task) => {
    task.status = 'Inactive';
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: DELETE_TASK, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}`, task, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return task;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const updateTimer = (task: Task) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: UPDATE_TIMER_TASK, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}`, task, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const createComment = (comment: AddComment) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CREATE_COMMENT, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}/AddComment`, comment, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const fetchOverdueTasks = (status: string) => {
    const endpoint = `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_TASK}?Status=${status}`;
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_OVERDUE_TASKS, 
            payload: axios.get(endpoint, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}