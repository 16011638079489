import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import styles from './structure.module.scss';
import { StructureState } from './interfaces';
import { ProjectState } from '../projects/interfaces';
import { ProfileState } from '../../store/reducers/global/profileReducer';
import Heading from '../../components/heading/Heading';
import StructureCards from '../../components/cards/StructureCards';
import StructuresTable from '../../components/tables/StructuresTable';
import { Modal } from '../../components/modal/Modal';
import StructureForm from '../../components/forms/StructureForm';
import { fetchStructure, uploadCsv, clearUpload } from './actions';
import { fetchProjects } from '../projects/actions';
import CardPagination from '../../components/pagination/card-pagination';
import TablePagination from '../../components/pagination/table-pagination';
import { Project } from '../projects/interfaces';
import FileLoader from '../../components/controls/file-loader/FileLoader';
import Select from '../../components/controls/select/Select';
import { User } from '../users/interfaces';

type Props = ({ showSort: boolean, showTitle: boolean, project?: Project });
export const StructuresContent = ({ showSort, showTitle, project }: Props) => {
    const dispatch = useDispatch();

    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isUploadModal, setIsUploadModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [pageNumber, setPageNumber] = useState<number>(1);
    // const [showEditModal, setShowEditModal] = useState<boolean>(false);

	type ProfileStore = { profile: User; loading: boolean; error: any };
	const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
	const profile = profileStore.profile;
    type PermissionsStore = { canEditSturctures: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);
    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    useEffect(() => {
        project ? dispatch(fetchStructure(10, pageNumber, project.id)) 
			: profile?.selectedProjectId ? dispatch(fetchStructure(10, pageNumber, profile?.selectedProjectId)) 
				: dispatch(fetchStructure(10, pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);

    // const handleChange = (newValue:boolean) =>{
    //     setShowEditModal(newValue);
       
    //   }
    // const discard = () => {
    //     setShowModal(false);
    //     setShowEditModal(false);
    //     dispatch(clearUpload());
    // }

    const Cards = (
        <div className={styles['cards-container']}>
            <div className={styles.cards}>
                <StructureCards filterValue={search} />
            </div>
            <CardPagination totalPages={Math.floor(structuresStore.resultstotal % 10 > 0 ? (structuresStore.resultstotal / 10) + 1 : structuresStore.resultstotal / 10)} currentPage={pageNumber} setCurrentPage={setPageNumber} />
        </div>
    )
    const Table = (
        <div>
            <StructuresTable showSort={showSort} filterValue={search} project={project} hiddenCols={!showTitle} />
            {structuresStore.resultstotal > 10 && 
            <TablePagination totalPages={Math.floor(structuresStore.resultstotal % 10 > 0 ? (structuresStore.resultstotal / 10) + 1 : structuresStore.resultstotal / 10)} currentPage={pageNumber} setCurrentPage={setPageNumber} totalResults={structuresStore.resultstotal} />}
        </div>
    )
    
    return (
        <div className={styles['structures-container']}>
            <div className={styles['heading-section']}>
                <Heading title={'Structures'} />
                {
                    permissionsStore.canEditSturctures && 
                    <div className={styles['new-item-section']}>
                        <div className={styles['new-table-item']} onClick={() => { setIsUploadModal(true); setShowModal(true); }}>Upload Structures</div>
                        <div className={styles['new-table-item']} onClick={() => { setIsUploadModal(false); setShowModal(true); }}>New Structure +</div>
                    </div>
                }
            </div>
            <div className={styles['filter-section']}>
                <FontAwesomeIcon icon={faFilter} />
                <div className={styles['input-item']}>
                    <input type='text' placeholder='filter data' onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            {isCard ? Cards : Table}
            {
                showModal && <StructuresModal isUploadModal={isUploadModal} setShowModal={setShowModal} project={project} />
            }
        </div>
    )
}

type ModalProps = ({ isUploadModal: boolean, setShowModal(arg: boolean): void, project?: Project });
const StructuresModal = ({ isUploadModal, setShowModal, project }: ModalProps) => {
    const dispatch = useDispatch();

    const [uploadFiles, setUploadFiles] = useState<any[]>([]);
    const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const projectRef = useRef<HTMLDivElement>(null);

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);
    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);
    const profileStore = useSelector((state: { profileStore: ProfileState }) => state.profileStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
    }

    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    useEffect(() => {
        if (isUploadModal) dispatch(fetchProjects());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (structuresStore.upload.complete) setUploadFiles([]);
    }, [structuresStore.upload])

    console.log('files', uploadFiles)
    const file = uploadFiles && uploadFiles.length > 0 ? uploadFiles[0].file : null;
    // const fileName = uploadFiles && uploadFiles.length > 0 ? uploadFiles[0].filename : '';
    return (
        <div>
            {isUploadModal ? 
                <Modal title={'Upload Structures'} isSmallModal= {isUploadModal} closeModal={() => {setShowModal(false); dispatch(clearUpload());}} >
                    {
                        structuresStore.upload.loading ? 
                        <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                            <div className={styles.spinner}></div>
                        </div>
                        :
                        <>
                            <div className={styles['structures-upload-container']}>
                                <div>Upload appropriately formatted CSV files to create multiple structures.</div>
                                {
                                    structuresStore.upload.complete && 
                                    <div className={structuresStore.upload.successful ? styles['successful-upload'] : styles['failed-upload']}>
                                        {structuresStore.upload.successful ? 'Upload completed successfully!' : 'CSV failed to Upload.'}
                                    </div>
                                }
                                <div className={styles['field-item']}>
                                    <Select
                                        placeholder='PROJECT'
                                        items={projectsStore.projectsinfo.projects}
                                        selectedItem={selectedProject?.name || ''}
                                        setSelectedItem={setSelectedProject}
                                        selectref={projectRef}
                                        isItemsObject={true}
                                        objectKeyField='id' 
                                        objectDisplayField='name'
                                    />
                                </div>
                                <FileLoader maxFiles={1} acceptedFileTypes={['application/vnd.ms-excel', 'text/csv', 'application/csv']} files={uploadFiles} setFiles={setUploadFiles} />
                            </div>
                            <div className={styles.actions}>
                                <button onClick={() => {setShowModal(false); dispatch(clearUpload());}}>Cancel</button>
                                {uploadFiles && selectedProject && uploadFiles.length > 0 && <button onClick={() => dispatch(uploadCsv(file, selectedProject?.id || '', selectedProject?.name || '', profileStore?.profile ? profileStore.profile.firstName + ' ' + profileStore.profile.lastName : '', profileStore?.profile ? profileStore.profile.id : ''))}>Upload Structures</button>}
                            </div>
                        </>
                    }
                </Modal>
                :
                <Modal title={'Create Structure'} closeModal={() => {setShowEditModal(true);}}><StructureForm showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} project={project} /></Modal>
            }
        </div>
    )
}

const Structures = ({ showSort, showTitle }: Props) => {
    return (
        <StructuresContent showSort={showSort} showTitle={showTitle} />
    )
}

export default Structures;