import { useState, useEffect, useRef } from 'react';
import cx from 'classnames';

import styles from './select.module.scss';
import { useEventListener } from '../../../hooks/hooks';
import { SelectType } from './interfaces';

type Props = ({ placeholder: string, selectedItem?: string, setSelectedItem?: any, items: any[], 
                isItemsObject: boolean, objectKeyField?: string, objectDisplayField?: string, 
                directionUp?: boolean, selectref: any, disabled?: boolean, selectType?: SelectType });
const Select = ({ placeholder, selectedItem, setSelectedItem, isItemsObject, objectKeyField, objectDisplayField, 
                  items, directionUp, selectref, disabled, selectType }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [showItems, setShowItems] = useState<boolean>(false);
    const [manualText, setManualText] = useState<string>('');
    const [filteredItems, setFilteredItems] = useState<any[]>([]);

    useEffect(() => setFilteredItems([...items]), [items]);

    const filterItems = (e: any) => {
        const filtered = e.target && e.target.value ? 
                         isItemsObject ?
                         items.filter(i => i[objectDisplayField || ''].toLowerCase().includes(e.target.value.toLowerCase())) : 
                         items.filter(i => i.toLowerCase().includes(e.target.value.toLowerCase())) : [...items];
        setFilteredItems([...filtered]);

        e.target && e.target.value ? setManualText(e.target.value) : setManualText('');
    }

    const handleOutsideClick = (e: any) => {
        if (selectref && selectref.current && !selectref.current.contains(e.target)) setShowItems(false);
    }
    const handleSelectionClick = (item: any) => {
        setManualText(isItemsObject ? item[objectDisplayField || ''] : item);
        setSelectedItem(item); setShowItems(false);
    }

    useEventListener('mousedown', handleOutsideClick)
    return (
        <div className={styles['select-container']} ref={selectref}>
            <div className={cx(styles.select, {[styles.disabled]: disabled})} onClick={() => {inputRef.current?.focus(); setShowItems(!showItems);}} ref={containerRef}>
                {   selectType && selectType === SelectType.TypeAhead ? 
                    <div className={styles.label}><input type='text' placeholder={placeholder} value={manualText} onChange={e => filterItems(e)} ref={inputRef} /></div> : 
                    <div className={styles.label}><div className={cx({[styles.placeholder]: !selectedItem})}>{selectedItem || placeholder}</div></div>
                }
                <div className={styles.arrow}></div>
            </div>
            {
                filteredItems && filteredItems.length > 0 && showItems &&
                <div style={{ width: `${containerRef.current?.offsetWidth}px` }} className={cx(styles.items, { [styles['up-item']]: directionUp })}>
                    {
                        filteredItems.map(i => {
                            return (
                                <div key={isItemsObject ? i[objectKeyField || ''] : i} className={styles.item} onClick={() => { handleSelectionClick(i); }}>
                                    <div>{isItemsObject ? i[objectDisplayField || ''] : i}</div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default Select;