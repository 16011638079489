import React from 'react';

import styles from './title.module.scss';
import Heading from '../heading/Heading';

type Props = ({ setShowModal?: any, headingTitle: string, addItemText?: string, hideTitle?: boolean});
const FormHeading = ({ setShowModal, headingTitle, addItemText, hideTitle }: Props) => {
    return (
        <div className={styles['heading-section']}>
            <Heading title={hideTitle ? '' : headingTitle} />
            {addItemText && <div className={styles['new-table-item']} onClick={() => setShowModal(true)}>{addItemText} +</div>}
        </div>
    )
}

export default FormHeading;