
import { FETCH_TASK, FETCH_DEFAULT_TASKS, CREATE_TASK, DELETE_TASK, SAVE_TASK, CREATE_COMMENT, FETCH_OVERDUE_TASKS, UPDATE_TIMER_TASK } from '../../store/actions/actionTypes';
import { TaskState, TaskSvcState } from './interfaces';

const initialState: TaskState = {
    tasks: [], 
    overdueTasks: [],
    loading: false, 
    error: null, 
    pagesize: 0,
    pagenumber: 0,
    pagecount: 0, 
    resultstotal: 0
}

export const tasksReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${FETCH_TASK}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${FETCH_TASK}_FULFILLED`: {
            return {
                ...state, 
                loading: false, 
                tasks: action.payload.data, 
                pagesize: action.payload.pageSize,
                pagenumber: action.payload.pageNumber,
                pagecount: action.payload.pageCount, 
                resultstotal: action.payload.resultsTotal
            }
        }
        case `${SAVE_TASK}_PENDING`: { 
            return {
                ...state, 
                loading: true
            }
        }
        case `${SAVE_TASK}_FULFILLED`: {        
            state.tasks.splice(state.tasks.findIndex(u => u.id === action.payload.data.id), 1);
            state.tasks.push(action.payload.data);
            return {
                ...state, 
                loading: false
            }
        }
        case `${CREATE_TASK}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${CREATE_TASK}_FULFILLED`: {
            state.tasks.push(action.payload.data)
            return {
                ...state, 
                loading: false
            }
        }
        case `${DELETE_TASK}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${DELETE_TASK}_FULFILLED`: {
            state.tasks.splice(state.tasks.findIndex(t => t.id === action.payload.id), 1);
            return {
                ...state, 
                loading: false
            }
        }
        case `${CREATE_COMMENT}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${CREATE_COMMENT}_FULFILLED`: {
            state.tasks.splice(state.tasks.findIndex(u => u.id === action.payload.data.id), 1);
            state.tasks.push(action.payload.data)
            return {
                ...state, 
                loading: false
            }
        }
        case `${FETCH_OVERDUE_TASKS}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${FETCH_OVERDUE_TASKS}_FULFILLED`: {
            state.overdueTasks = action.payload.data;
            return {
                ...state, 
                loading: false, 
                tasks: action.payload.data
            }
        }
        case `${UPDATE_TIMER_TASK}_PENDING`: {
            return {
                ...state, 
                loading: false
            }
        }
        case `${UPDATE_TIMER_TASK}_FULFILLED`: {
            const index = state.tasks.findIndex(u => u.id === action.payload.data.id);
            if(index !== -1)
                state.tasks[index] = action.payload.data;
            return {
                ...state, 
                loading: false
            }
        }
        default: return state;
    }
}

const initialTaskState: TaskSvcState = {
    tasks: [],
    loading: false, 
    error: null
}
export const defaultTaskReducer = (state = initialTaskState, action: any) => {
    switch (action.type) {
        case `${FETCH_DEFAULT_TASKS}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${FETCH_DEFAULT_TASKS}_FULFILLED`: {
            return {
                ...state, 
                loading: false, 
                tasks: action.payload.data
            }
        }
        default: return state;
    }
}