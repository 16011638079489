import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMsal } from "@azure/msal-react";

const Logout = () => {
    const history = useHistory();
    const { instance } = useMsal();

    useEffect(() => {
        try {
            const loginResponse = instance.logoutRedirect().then((response: any) => {
                console.log("Logout Response: " + response.json())
            });
    
            console.log(loginResponse);
        } catch (err) {
            console.log(err);
        }
        history.push('/login');
    }, [])

    return (
        <div></div>
    )
}

export default Logout;