import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";

const Login = () => {
    const { instance } = useMsal();

    useEffect(() => {
        const loginResponse = instance.loginRedirect().then((response: any) => {
            console.log("Login Response: " + response.json())
        });
    }, [instance])

    return (
        <div></div>
        // <div className={styles['login-form-container']}>
        //     <div className={styles['login-form']}>
        //         <form>
        //             <h1>Welcome to Recce!</h1>
        //             <div className={styles.inputs}>
        //                 <div className={styles['input-item']}>
        //                     <input type='email' placeholder='EMAIL' />
        //                 </div>
        //                 <div className={styles['input-item']}>
        //                     <input type='password' placeholder='PASSWORD' />
        //                 </div>
        //                 <Link to='/recover'>FORGOT PASSWORD?</Link>
        //             </div>
        //             <div className={styles.actions}>
        //                 {/* <SignInButton /> */}
        //                 <button>Create Account</button>
        //                 <button onClick={() => history.push('/')}>Sign In</button>
        //             </div>
        //         </form>
        //     </div>
        // </div>
    )
}

export default Login;