import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './home.module.scss';
import { User } from '../users/interfaces';
import { fetchHomeDashboard } from './actions';
import AdminDashboard from './AdminDashboard';
import FieldUserDashboard from './FieldUserDashboard';

const Home = () => {
    const dispatch = useDispatch();

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;
    useEffect(() => {
        if (profile) {
            dispatch(fetchHomeDashboard(profile?.azureId))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profileStore.profile])

    return (
        <div className={styles['home-container']}>
            <AuthenticatedTemplate>
                {profile ? (profile.role === 'Admin' ? <AdminDashboard /> : <FieldUserDashboard />) : ''}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div>Unauthenticated</div>
            </UnauthenticatedTemplate>

        </div>
    );
}

export default Home;