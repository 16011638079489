import axios, { AxiosError } from 'axios';

import { FETCH_HOME_DASHBOARD } from '../../store/actions/actionTypes';
import { RECCE_DASHBOARD } from '../../store/api/endPoints';
import { svcconfig } from '../../store/actions/actions';

// const svcconfig = {headers: {'Pragma': 'no-cache',  'Content-Type': 'application/json'}};

type HomeRequest = ({ azureId?: string, projectId?: string });
export const fetchHomeDashboard = (azureId?: string, projectId?: string ) => {
    const request: HomeRequest = { azureId: azureId, projectId: projectId };
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_HOME_DASHBOARD, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DASHBOARD}`, request, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}