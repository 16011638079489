import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import { User } from '../../views/users/interfaces';
import { DailyReportSummary } from '../../views/reports/interfaces';
import { fetchDailyReportDetails } from '../../views/reports/actions';
import { FilterInfo, useItemsFilter, SortInfo, useItemsSort, useEventListener } from '../../hooks/hooks';
import { Modal } from '../modal/Modal';
import ReportsForm from '../forms/ReportsForm';
import { Project } from '../../views/projects/interfaces';

type MenuProps = ({ report: DailyReportSummary, setShowModal: any, setSelectedReport: any, setSelectedRow: any, canEdit: boolean });
const ActionMenu = ({ report, setShowModal, setSelectedReport, setSelectedRow, canEdit }: MenuProps) => {
    const [ showActions, setShowActions ] = useState<boolean>(false);

    const actionsRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (e: any) => {
        if (actionsRef && actionsRef.current && !actionsRef.current.contains(e.target)) {
            setShowActions(false); setSelectedRow(null);
        }
    }

    useEventListener('mousedown', handleOutsideClick);
    return (
        <div className={styles['actions-menu']} ref={actionsRef}>
            <div className={styles.more} onClick={() => setShowActions(!showActions)}></div>
            {   showActions && 
                <div className={cx(styles['actions-container'], {[styles['show-actions']]: showActions})}>
                    {   canEdit && 
                        <div className={styles.action} onClick={() => { setShowModal(true); setSelectedReport(report); }}><div className={styles.icon}><FontAwesomeIcon icon={faPencilAlt} /></div><div>edit report</div></div>
                    }
                </div>
            }
        </div>
    )
}

type Props = ({ showSort: boolean, filterValue: string, project?: Project });
const ReportsTable = ({ showSort, filterValue, project }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedReport, setSelectedReport] = useState<DailyReportSummary>();
    const [selectedRow, setSelectedRow] = useState<string>('');

    const [sortType, setSortType] = useState<string>('end-desc');
    const [selectedSort, setSelectedSort] = useState<any>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type DailyReportsStore = { reportsinfo: { dailyreports: DailyReportSummary[], loading: boolean, error: any } };
    const dailyReportsStore = useSelector((state: { dailyReportsStore: DailyReportsStore }) => state.dailyReportsStore);

    type PermissionsStore = { canEditDaily: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);

    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? 
            setSortType(headingInfo.field+'-asc') : 
            setSortType(headingInfo.field+'-desc');

        setSelectedSort(headingInfo);
    }
    const handleViewReport = (report: DailyReportSummary) => {
        dispatch(fetchDailyReportDetails(report?.id || '', profileStore.profile?.id)); 
        history.push(`/reports/${encodeURIComponent(report?.id || '')}`);
    }

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    // setup of filters
    const filterInfo: FilterInfo = { items: dailyReportsStore.reportsinfo.dailyreports, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['id', 'name', 'type'] };
    const filterReports = useItemsFilter(filterInfo);

    // setup sorting initial sort is set to 'date' type and using value of 'end date'
    const sortInfo: SortInfo = { items: filterReports, valueType: selectedSort?.valuetype || 'date', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'end' };
    const sortedReports = useItemsSort(sortInfo);
    const tzLocale = 'en-US';
    const tzOptions: Intl.DateTimeFormatOptions = { timeZoneName: 'short', hour12: false };

    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    <tr>
                        {
                            theads.reports.map(h => {
                                return (
                                    (h.title != '' || (h.title == '' && permissionsStore.canEditDaily)) &&
                                    <th key={h.id} colSpan={h.colspan} onClick={() => h.sort ? handleSort(h) : null}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title}&nbsp;</div>
                                            {   showSort && h.sort ? 
                                                h.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                                h.field?.toLowerCase()+'-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> : 
                                                <FontAwesomeIcon icon={faSort} /> : null
                                            }
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                {   dailyReportsStore.reportsinfo.loading ?
                    <tbody>
                        <tr className={styles['row-spinner']}><td colSpan={9}>
                            <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                <div className={styles.spinner}></div>
                            </div>
                        </td></tr>
                    </tbody> : 
                    <tbody>
                    {
                        sortedReports.length === 0 ?
                            <tr>
                                <td colSpan={9}><div className={styles['no-data']}>There are currently no daily reports.</div></td>
                            </tr>
                        :
                        sortedReports.map(r => (
                            <tr key={r.id} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === r.id})}>
                                <td onClick={() => handleViewReport(r)} colSpan={2}>{r.projectName}</td>
                                <td onClick={() => handleViewReport(r)} colSpan={2}>{r.authorName}</td>
                                <td onClick={() => handleViewReport(r)} colSpan={2}>{r.notes?.length > 50 ? r.notes.substring(0, 50) + '...' : r.notes}</td>
                                <td onClick={() => handleViewReport(r)} colSpan={2}>{new Date(r.reportDate).toLocaleString(tzLocale, tzOptions)}</td>
                                {permissionsStore.canEditDaily ? 
                                    <td>
                                        <ActionMenu report={r} setShowModal={setShowModal} setSelectedReport={setSelectedReport} setSelectedRow={setSelectedRow} canEdit={permissionsStore.canEditDaily} />
                                    </td>
                                    :
                                    ""
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                }
            </table>
            {showModal && <Modal title='Edit Report' closeModal={() => setShowEditModal(true)}><ReportsForm dailyReport={selectedReport} isExisting={true} project={project} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal>}
        </div>
    )
}

export default ReportsTable;