import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import { FilterInfo, useItemsFilter, SortInfo, useItemsSort } from '../../hooks/hooks';
import { Task } from '../../views/tasks/interfaces';
import { DeleteModal, Modal } from '../modal/Modal';
import TaskForm from '../forms/TaskForm';
import { deleteTask } from '../../views/tasks/actions';
import { useHistory } from 'react-router-dom';
import { useEventListener } from '../../hooks/hooks';
import { Structure } from '../../views/structures/interfaces';
import { Project } from '../../views/projects/interfaces';
import DeletePopup from '../popup/DeletePopup';

import {Dashboard} from '../../views/home/interfaces';

type MenuProps = ({ task: Task, setShowModal: any, setSelectedTask: any, setSelectedRow: any, canEdit: boolean, setShowDeleteModal: any });
const ActionMenu = ({ task, setShowModal, setSelectedTask, setSelectedRow, canEdit, setShowDeleteModal }: MenuProps) => {
    const [ showActions, setShowActions ] = useState<boolean>(false);

    const actionsRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (e: any) => {
        if (actionsRef && actionsRef.current && !actionsRef.current.contains(e.target)) {
            setShowActions(false); setSelectedRow(null);
        }
    }

    useEventListener('mousedown', handleOutsideClick);
    return (
        <div className={styles['actions-menu']} ref={actionsRef}>
            <div className={styles.more} onClick={() => setShowActions(!showActions)}></div>
            {   showActions && 
                <div className={cx(styles['actions-container'], {[styles['show-actions']]: showActions})}>
                    {   canEdit &&
                        <>
                            <div className={styles.action} onClick={() => { setShowModal(true); setSelectedTask(task); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faPencilAlt} /></div><div>edit task</div></div>
                            <div className={styles.action} onClick={() => { setShowDeleteModal(true); setSelectedTask(task); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faTrashAlt} /></div><div>delete task</div></div>
                        </>
                    }
                </div>
            }
        </div>
    )
}

type UI = ({ tasks: Task[], loading: boolean, error: any, filterValue: string, showSort: boolean, isCompletedOnly: boolean, isOverdueOnly: boolean, canEdit: boolean, setShowModal?: any, setSelectedTask?: any, setShowDeleteModal?: any });
export const TaskTableUI = ({ tasks, loading, error, filterValue, showSort, isCompletedOnly, isOverdueOnly, canEdit, setShowModal = null, setSelectedTask = null, setShowDeleteModal = null }: UI) => {
    const history = useHistory();
    type TasksStore = { tasks: Task[], loading: boolean, error: any, overdueTasks: Task[] };
    const overdueTask = useSelector((state: { tasksStore: TasksStore }) => state.tasksStore?.overdueTasks);
    const urlParam = window.location.href.split("?");
    
    
    if (urlParam.length > 1 && urlParam[1] === 'overdue') tasks = overdueTask;
    
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [sortType, setSortType] = useState<string>('end-desc');
    const [selectedSort, setSelectedSort] = useState<any | null>(null);
    dayjs.extend(timezone);
    dayjs.extend(advanced);
    const dateTimeFormat = 'MM/DD/YYYY HH:mm z';
    
    console.log("selectedRow",selectedRow);

    type PermissionsStore = { canEditTasks: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    //used for column sorting
    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ?
            setSortType(headingInfo.field + '-asc') :
            setSortType(headingInfo.field + '-desc');

        setSelectedSort(headingInfo);
    }

    // setup of filters
    const filterInfo: FilterInfo = { items: tasks, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['id', 'name', 'type'] };
    const filterTasks = useItemsFilter(filterInfo);

    // setup sorting initial sort is set to 'date' type and using value of 'start time'
    const sortInfo: SortInfo = { items: filterTasks, valueType: selectedSort?.valuetype || 'date', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'start' };
    const sortedTasks = useItemsSort(sortInfo);

    localStorage.setItem('allData', JSON.stringify(sortedTasks));

    const completedTasks = (
        sortedTasks.map(t => (
            <tr key={t.id} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === t.id})}>
                <td><div>{t.name}</div></td>
                <td><div>{t.structureName}</div></td>
                <td><div>{t.assignedToName}</div></td>
                <td><div>{t.startTime ? dayjs(t.startTime).format(dateTimeFormat) : ''}</div></td>
                <td><div>{t.endTime ? dayjs(t.endTime).format(dateTimeFormat) : ''}</div></td>
                <td>{t.elapsedTime ? `${(Math.floor((t.elapsedTime / 3600)))}:${('0' + Math.floor((t.elapsedTime / 60) % 60)).slice(-2)}:${('0' + Math.floor((t.elapsedTime / 1) % 60)).slice(-2)}` : ''}</td>
            </tr>
        ))
    );

    const overdueTasks = (
        sortedTasks.map(t => (
            <>
            {console.log("selectedRow === t.id", selectedRow, t.id)}
            <tr key={t.id} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === t.id})}>
                <td><div>{t.name}</div></td>
                <td><div>{t.structureName}</div></td>
                <td><div>{t.assignedToName}</div></td>
                <td>{t.status}</td> 
                <td><div>{t.startTime ? dayjs(t.startTime).format(dateTimeFormat) : ''}</div></td>
            </tr>
            </>
        ))
    );
    const fullTasks = (
        sortedTasks.map((t,index) => (
            <>
            
            <tr key={t.id} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === t.id})}>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.orderNumber}</td>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.name}</td>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.structureName}</td>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.assignedToName}</td>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.status}</td> 
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.startTime ? dayjs(t.startTime).format(dateTimeFormat) : ''}</td>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.endTime ? dayjs(t.endTime).format(dateTimeFormat) : ''}</td>
                <td onClick={() => history.push(`/tasks/${encodeURIComponent(t.id || '')}?query=${index}`)} colSpan={2}>{t.elapsedTime ? `${(Math.floor((t.elapsedTime / 3600)))}:${('0' + Math.floor((t.elapsedTime / 60) % 60)).slice(-2)}:${('0' + Math.floor((t.elapsedTime / 1) % 60)).slice(-2)}` : ''}</td>
                {   !isCompletedOnly && !isOverdueOnly && permissionsStore.canEditTasks ?
                    <td>
                        <ActionMenu task={t} setShowModal={setShowModal} setShowDeleteModal={setShowDeleteModal} setSelectedTask={setSelectedTask} setSelectedRow={setSelectedRow} canEdit={canEdit} />
                    </td>
                      :
                      ""
                }
            </tr>
            </>
        ))
    )

    return (
        <div>
        <table>
            <thead>
                <tr>
                    {   isOverdueOnly ? 
                        theads.tasksoverdue.map(h => {
                            return (
                                <th key={h.id} onClick={() => handleSort(h)}>
                                    <div className={styles['col-labels']}>
                                        <div>{h.title}&nbsp;</div>
                                    </div>
                                </th>
                            )
                        }) :
                        isCompletedOnly ?
                        theads.taskcompleted.map(h => {
                            return (
                                <th key={h.id} onClick={() => handleSort(h)}>
                                    <div className={styles['col-labels']}>
                                        <div>{h.title}&nbsp;</div>
                                    </div>
                                </th>
                            )
                        }) :
                        theads.tasks.map(h => {
                            return (
                                (h.title !== '' || !isCompletedOnly) && 
                                <th key={h.id} colSpan={h.colspan} onClick={() => handleSort(h)}>
                                    <div className={styles['col-labels']}>
                                        <div>{h.title}&nbsp;</div>
                                        { (showSort && h.sort) ? 
                                        h.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                        h.field?.toLowerCase()+'-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> : 
                                        <FontAwesomeIcon icon={faSort} /> : null}
                                    </div>
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
            {
                loading ? 
                <tbody>
                    <tr className={styles['row-spinner']}><td colSpan={isOverdueOnly || isCompletedOnly ? 6 : 15}>
                        <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                            <div className={styles.spinner}></div>
                        </div>
                    </td></tr>
                </tbody> :
                <tbody>
                    {
                        sortedTasks.length === 0 ?
                            <tr>
                                <td colSpan={isOverdueOnly || isCompletedOnly ? 5 : 15}><div className={styles['no-data']}>There are currently no tasks.</div></td>
                            </tr>
                        :
                        isOverdueOnly ? overdueTasks : isCompletedOnly? completedTasks : fullTasks
                    }
                </tbody>
            }
        </table>
        </div>
    )
}

type Props = ({ showSort: boolean, filterValue: string, structure?: Structure, project?: Project, projectId?: string, taskStatus?: string, structureId?: string });
const TasksTable = ({ showSort, filterValue, structure, project, projectId, taskStatus, structureId}: Props) => {
    const dispatch = useDispatch();
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    

    type TasksStore = { tasks: Task[], loading: boolean, error: any };
    const tasksStore = useSelector((state: { tasksStore: TasksStore }) => state.tasksStore);

    type HomeStore = { data: Dashboard, loading: boolean, error: any };
    const homeStore = useSelector((state: { homeStore: HomeStore }) => state.homeStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    if (window.location.href.split('/').slice(-1)[0] === 'home') {
        tasksStore.tasks = homeStore.data.tasks;
    }
    
    if (projectId && homeStore?.data?.tasks) {
        tasksStore.tasks = homeStore.data.tasks.filter((task) => {
            return task.projectId === projectId;
        });
    }
    if (taskStatus && homeStore?.data?.tasks && taskStatus!=='') {
        tasksStore.tasks = homeStore.data.tasks.filter(task => task.status === taskStatus);
    }

    if(structureId)
    {
        tasksStore.tasks = homeStore.data.tasks.filter((task) => {
            return task.structureId === structureId;
        })
    }

    type PermissionsStore = { canEditTasks: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    var currentdate = new Date(); 
    currentdate.setHours(currentdate.getHours(),currentdate.getMinutes(),currentdate.getSeconds(),0);
    console.log("tasksStore.tasks",tasksStore.tasks);
    console.log("selectedTask",selectedTask);
    return (
        <div className={styles['table-container']}>
            <TaskTableUI tasks={tasksStore.tasks} loading={tasksStore.loading} error={tasksStore.error} filterValue={filterValue} showSort={showSort} isCompletedOnly={false} isOverdueOnly={false} canEdit={permissionsStore.canEditTasks} setShowModal={setShowModal} setShowDeleteModal={setShowDeleteModal} setSelectedTask={setSelectedTask} /> 
            {showModal && <Modal title='Edit Task' closeModal={() => setShowEditModal(true)}><TaskForm task={selectedTask || undefined} isExisting={true}  showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} structure={structure} project={project} /></Modal>}
            {showDeleteModal && <DeleteModal title='Delete Task' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={selectedTask?.name || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(selectedTask){dispatch(deleteTask(selectedTask))}setShowDeleteModal(false);}}/></DeleteModal> }
        </div>
    )
}

export default TasksTable

