import cx from 'classnames';

import styles from './pagination.module.scss';
import { usePagination, dots } from '../../hooks/hooks';

type Props = ({ totalPages: number, currentPage: number, setCurrentPage(arg: number): void, pageSize?: number, totalResults?: number });
const TablePagination = ({ totalPages, currentPage, setCurrentPage, pageSize = 10, totalResults = 0 }: Props) => {
    const paginationRange = usePagination(totalResults, pageSize, currentPage, 1);
    if (currentPage === 0 || !paginationRange || paginationRange.length < 2) return null;

    return (
        <ul className={styles['page-numbers']}>
            {currentPage !== 1 && <li onClick={() => setCurrentPage(currentPage - 1)} className={styles['previous-next']}>Prev</li>}
            {   paginationRange.map(p => {
                    if (p === dots) return <li key={p} className={styles.dots}>&#8230;</li>

                    return <li key={p} onClick={() => setCurrentPage(typeof p === 'number' ? p : 0)} className={cx({[styles.active]: currentPage === p})}>{p}</li>
                })
            }
            {currentPage !== totalPages && <li onClick={() => setCurrentPage(currentPage + 1)} className={styles['previous-next']}>Next</li>}
        </ul>
    )
}

export default TablePagination;