import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import styles from './fileloader.module.scss';

// Register the plugins
registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginFileValidateType);

type Props = ({ label?: string, maxFiles: number, files: any[], setFiles: any, acceptedFileTypes?: string[] })
const FileLoader = ({ label, maxFiles, acceptedFileTypes, files, setFiles }: Props) => {
    return (
        <div className={styles['upload-container']}>
            <FilePond 
                className='filepond'
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={maxFiles > 1}
                maxFiles={maxFiles}
                allowFileEncode={true}
                allowImagePreview={true}
                imagePreviewMaxHeight={100}
                allowImageResize={true}
                imageResizeUpscale={true}
                imageResizeMode='cover'
                allowFileTypeValidation={acceptedFileTypes && acceptedFileTypes.length > 0}
                acceptedFileTypes={acceptedFileTypes}
                instantUpload={false}
                labelIdle={label || 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'}
            />
        </div>
    )
}

export default FileLoader