import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authorization/AuthConfig";

import './index.css';
import App from './views/app/App';
import reportWebVitals from './reportWebVitals';
import rootReducer from './store/reducers/rootReducer';

const msalInstance = new PublicClientApplication(msalConfig);
const store = createStore(rootReducer, applyMiddleware(promise, thunk));

ReactDOM.render(
	<Router>
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<App />
			</MsalProvider>
		</Provider>
	</Router>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
