import React from 'react';

import styles from './tab.module.scss';
import { Tab } from './interfaces';

type Props = ({ tab: Tab, selectedTab: number, setSelectedTab: any });
const TabItem = ({ tab, selectedTab, setSelectedTab }: Props) => {
    return (
        <>
            <input type='radio' name='tab' id={`tab-${tab.id}`} className={styles['tab-item-input']} defaultChecked={tab.id === selectedTab} onClick={() => setSelectedTab(tab.id)} />
            <label htmlFor={`tab-${tab.id}`} className={styles['tab-item-label']}>{tab.name}</label>
        </>
    )
}

export default TabItem;