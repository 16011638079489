import { combineReducers } from 'redux';

import profileReducer from './global/profileReducer';
import permissionsReducer from './global/permissionsReducer';
import projectsReducer from '../../views/projects/reducer';
import structuresReducer from '../../views/structures/reducer';
import dailyReportsReducer from '../../views/reports/reducer';
import usersReducer from '../../views/users/reducer';
import { tasksReducer, defaultTaskReducer } from '../../views/tasks/reducer';
import homeReducer from '../../views/home/reducer';

const reducers = {
    profileStore: profileReducer,
    permissionsStore: permissionsReducer, 
    projectsStore: projectsReducer,
    structuresStore: structuresReducer,
    dailyReportsStore: dailyReportsReducer, 
    usersStore: usersReducer,
    tasksStore: tasksReducer,
    defaultTaskStore: defaultTaskReducer, 
    homeStore: homeReducer
}

export default combineReducers(reducers);