import axios, { AxiosError } from 'axios';

import { DELETE_USERS, SAVE_USERS, CREATE_USERS, FETCH_USERS, USERS_ERROR, FETCH_PROFILE } from '../../store/actions/actionTypes';
import { RECCE_USERS, RECCE_PROFILE } from '../../store/api/endPoints';
import { User } from './interfaces';
import { svcconfig } from '../../store/actions/actions';

// const svcconfig = {headers: {'Pragma': 'no-cache',  'Content-Type': 'application/json'}};

export const fetchUsers = (pageSize?: number, pageNumber?: number) => {
    const size = pageSize ? `PageSize=${pageSize}` : null; const number = pageNumber ? `PageNumber=${pageNumber}` : null; 
    const endpoint = size && number ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USERS}?${size}&${number}` : `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USERS}`;
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_USERS, 
            payload: axios.get(endpoint, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const saveUser = (user: User, isProfile: boolean) => {
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: SAVE_USERS, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USERS}`, user, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    if (isProfile) { dispatch({
                        type: FETCH_PROFILE, 
                        payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROFILE}`, user, svcconfig())
                            .then(resp => {
                                if (!resp.statusText && resp.statusText !== 'OK') return [];
                                return resp.data;
                            })
                            .catch(error => {
            
                            })
                    }) }
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const deleteUser = (user: User) => {
    user.status = 'Inactive';
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: DELETE_USERS, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USERS}`, user, svcconfig())
            .then(resp => {
                if (!resp.statusText && resp.statusText !== 'OK') return [];
                return resp.data;
            })
            .catch((error: AxiosError) => {
                if (error?.response?.status === 401) {
                    sessionStorage.removeItem('token');
                    window.location.href = '/logout';
                }
                return { data: undefined, error: error }
            })
        })
    }
}

export const createUser = (user: User) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CREATE_USERS, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_USERS}`, user, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}