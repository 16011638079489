import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';

import styles from './camera.module.scss';
import Select from '../controls/select/Select';
import formStyle from '../forms/forms.module.scss';

const Camera = ({sendFile, showEModal, closeModal, discard, parentCallback}) => {
    const videoRef = useRef(null);
    const photoRef = useRef(null);

    const [hasPhoto, setHasPhoto] = useState(false);
    //States For Confirmation modal before save/create
    const [showEditModal, setShowEditModal] = useState(false); 
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [imageCaption, setImageCaption] = useState("");
    const [imageSelected, setImageSelected] = useState("");

    const tasksStore = useSelector(state => state.tasksStore);
    const upload = useSelector(state => state?.structuresStore?.upload);

    const [selectedTask, setSelectedTask] = useState(tasksStore.tasks.length > 0 ? tasksStore.tasks[0] : []);
    parentCallback(selectedTask, imageCaption);

    const typeRef = useRef(null);
    let taskItems = [];

    tasksStore.tasks.forEach(task => {
        taskItems.push(task.name);
    });

    const setTask = (taskname) => {
        tasksStore.tasks.forEach(task => {
            if(task.name === taskname)
            setSelectedTask(task);
            parentCallback(selectedTask, imageCaption);
            setUnsavedChanges(true); 
        });
    }

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    const getVideo = () => {
        navigator.mediaDevices
			.getUserMedia({
				audio: false,
				video: { width: 1920, height: 1080, facingMode: { exact: 'environment' } }
			})
			.then((stream) => {
				let video = videoRef.current;
				video.srcObject = stream;
				video.play();
			})
			.catch((err) => {
				console.error(err);
			});
    }
    const takePhoto = () => {
        const width = 414;
        const height = width / (16/9);

        const video = videoRef.current;
        const photo = photoRef.current;

        photo.width = width;
        photo.height = height;

        const ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        setHasPhoto(true);
    }
    const closePhoto = () => {
        const photo = photoRef.current;
        const ctx = photo.getContext('2d');

        ctx.clearRect(0, 0, photo.width, photo.height);
        setHasPhoto(false);
    } 
    const uploadPhoto = () => {
        parentCallback(selectedTask, imageCaption);
        const photo = photoRef.current;
        const ctx = photo.getContext('2d');
        
        ctx.canvas.toBlob(sendFile);
        setHasPhoto(false);
    }

    const validation = () => {
        setImageSelected("*Please Select an Image to upload");
        handleChange();
    }

    const validationStyle = {
        color : "red", 
        display : "flex", 
        justifyContent : "center", 
        alignItems : "center", 
        margin : "20px 0"
    }

    useEffect(() => {
        getVideo();
    }, [videoRef, upload.loading])

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }
    
    return (
        <div className={styles['camera-container']}>
            {   upload.loading ? 
                    <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                        <div className={styles.spinner}></div>
                    </div>
                :
                <div>
                    <div className={styles.camera}>
                        <video ref={videoRef} />
                        <div className={styles['icons']}>
                            <div><FontAwesomeIcon icon={faCamera} size={'2x'} onClick={takePhoto} /></div>
                        </div>
                        <div>{imageSelected !== '' ? <label style={validationStyle} className={formStyle.required}>{imageSelected}</label> : ''}</div>
                        <div className={formStyle['form-container']}>
                            <div className={formStyle['inputs-section']}>
                                <div className={formStyle.inputs}>
                                    <div className={formStyle['input-item']}>
                                        <label>Image caption</label>
                                        <input type='text' placeholder='description' value={imageCaption} onChange={(e) => {setImageCaption(e.target.value); setUnsavedChanges(true);}} />
                                    </div>
                                </div>
                            </div>
                            <div className={formStyle['inputs-section']}>
                                <div className={formStyle.inputs}>
                                    <div className={formStyle['input-item']}>
                                        <Select placeholder='NO TASKS' items={taskItems} selectedItem={selectedTask.name} setSelectedItem={setTask} selectref={typeRef} isItemsObject={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={cx(styles['camera-result'], {[styles['has-photo']]: hasPhoto})}>
                <canvas ref={photoRef} />
                <div className={cx(styles.actions, {[styles['has-photo']]: hasPhoto})}>
                    <button onClick={closePhoto}>Close</button>
                    <button onClick={uploadPhoto}>upload</button>
                </div>
            </div>
            { (showEModal || showEditModal) && <EditModal title='UNSAVED CHANGES' closeModal={() =>{handleChange()}}><EditPopup  closeModal={() =>{handleChange()}} editEntity={() =>validation()} discardChanges = {() => discard()}/></EditModal> }
        </div>
    )
}

export default Camera;