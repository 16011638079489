import styles from './popup.module.scss';

type Props = ({ name: String, closeModal: any, deleteEntity: any });
const DeletePopup = ({ name, closeModal, deleteEntity }: Props) => {



    return (

        <div style={{ padding: "15px" }}>
            <div> Are you sure to want delete {name}? </div>

            <div className={styles.actions}>
                <button onClick={() => closeModal()}>Cancel</button>
                <button style={{ backgroundColor: "#CC0000" }} onClick={() => deleteEntity()}>Delete</button>
            </div>
        </div>

    )
}

export default DeletePopup;