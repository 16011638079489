import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { Button, Paper } from '@mui/material';

import styles from '../task.module.scss';
import formstyles from '../../../components/forms/forms.module.scss';
import Title from '../../../components/title/Title';
import Heading from '../../../components/heading/Heading';
import { Tab } from '../../../components/tab/interfaces';
import TabItem from '../../../components/tab/TabItem';
import StartStop from '../../../components/controls/buttons/start-stop/StartStop';
import { Task, TimerStatus } from '../interfaces';
import { fetchTasks } from '../actions';
import CommentsTable from '../../../components/tables/CommentsTable';
import { Modal } from '../../../components/modal/Modal';
import CommentForm from '../../../components/forms/CommentForm';
import History from '../../history/History';
import { User } from '../../users/interfaces';
import TaskForm from '../../../components/forms/TaskForm';
import { Attachment } from '../../structures/interfaces';
import { ReactImageGalleryItem } from 'react-image-gallery';
import PhotoGallery from '../../../components/gallery/photo-gallery';
import emptyImagesStyle from '../../structures/structure.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Camera from '../../../components/camera/Camera';
import { uploadFile } from '../../structures/actions';
import { FilterInfo, SortInfo, useItemsFilter, useItemsSort } from '../../../hooks/hooks';

type TaskItemProps = ({ task: Task, setShowTaskModal(arg: boolean): void });
const TaskItemInfo = ({ task, setShowTaskModal }: TaskItemProps) => {
    const history = useHistory();
    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;

    const [elapsedTime, setElapsedTime] = useState<number>(task.elapsedTime || 0);
    const [showCameraModal, setShowCameraModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<Task>();
    const [imageCaption, setImageCaption] = useState<string>("");
    const dispatch = useDispatch();
    
    const callback = useCallback((task, caption) => {
        setSelectedTask(task);
        setImageCaption(caption);
    }, []);

  // setup of filters
//   const filterInfo: FilterInfo = { items: tasks, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['id', 'name', 'type'] };
//   const filterTasks = useItemsFilter(filterInfo);


//     const sortInfo: SortInfo = { items: filterTasks, valueType: selectedSort?.valuetype || 'date', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'start' };
//     const sortedTasks = useItemsSort(sortInfo);


    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
    }

    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
        setShowCameraModal(false);
    }

    const upload = async (file: any) => {
        const firstName = profile.firstName ? profile.firstName : "";
        const lastName = profile.lastName ? profile.lastName : "";
        const data = {
            FileName : imageCaption,
            StructureId : selectedTask ? selectedTask.structureId : "",
            StructureName : selectedTask ? selectedTask.structureName : "",
            TaskId : selectedTask ? selectedTask.id : "",
            TaskName : selectedTask ? selectedTask.name : "",
            CreatedBy : firstName + " " + lastName,
            CreatedById : profile.id
        } 
        dispatch(uploadFile(file, new Date().toTimeString(), data));
        setTimeout(()=> {
            setShowCameraModal(false);
        }, 800);
    };

    useEffect(() => {
        if (task.timerStatus?.toLowerCase() === TimerStatus.InProgress.toLowerCase()) {
            if (task.pausedTime) {
                const current = dayjs();
                const past = dayjs(task.pausedTime);
                const pauseTime = current.diff(past, 'second');
                setElapsedTime(elapsedTime + pauseTime);
            }
            else if (!task.pausedTime && task.startTime) {
                const current = dayjs();
                const past = dayjs(task.startTime);
                const startTime = current.diff(past, 'second');
                setElapsedTime(elapsedTime + startTime);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const formattedElapsed = `${(Math.floor((elapsedTime / 3600)))}:${('0' + Math.floor((elapsedTime / 60) % 60)).slice(-2)}:${('0' + Math.floor((elapsedTime / 1) % 60)).slice(-2)}`;
    const formattedEstimatedDuration = task?.estimatedDuration ? `${(Math.floor((task?.estimatedDuration / 3600)))}:${('0' + Math.floor((task?.estimatedDuration / 60) % 60)).slice(-2)}:${('0' + Math.floor((task?.estimatedDuration / 1) % 60)).slice(-2)}` : '-';
    const formattedCompleteElapsedTime = task?.elapsedTime ? `${(Math.floor((task?.elapsedTime / 3600)))}:${('0' + Math.floor((task?.elapsedTime / 60) % 60)).slice(-2)}:${('0' + Math.floor((task?.elapsedTime / 1) % 60)).slice(-2)}` : '00:00:00';

    return (
        <>
            <div className={styles.heading}>
                <Heading title={`${task.orderNumber?.toString()}. ${task.name}`} isClickable={true} clickAction={() => setShowTaskModal(true)} />
                <div className={`${formstyles['form-container']} ${styles.timer}`}>
                    {   
                        process.env.REACT_APP_ENABLE_CAMERA?.toString().toLowerCase() === 'true' &&
                            <div className={styles['icons']}>  
                                <FontAwesomeIcon icon={faCamera} size={'2x'} onClick={() => setShowCameraModal(true)} />
                            </div>
                    }
                </div>
            </div>
            <div className={styles['item-detail-section']}>
                <div className={styles.item}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className={styles.title}>Task Info</div>
                        <div className={styles.detail}>
                            <div>Project</div>
                            <div className={styles['clickable-item']} onClick={() => history.push(`/projects/${encodeURIComponent(task?.projectId || '')}`)}>{task?.projectName || '-'}</div>
                        </div>
                        <div className={styles.detail}>
                            <div>Structure</div>
                            <div className={styles['clickable-item']} onClick={() => history.push(`/structures/${encodeURIComponent(task?.structureId || '')}`)}>{task?.structureName || '-'}</div>
                        </div>
                        <div className={styles.detail}>
                            <div>Status</div>
                            <div>{task?.status || '-'}</div>
                        </div>
                        <div className={styles.detail}>
                            <div>Assigned To</div>
                            <div>{task?.assignedToName || '-'}</div>
                        </div>
                    </Paper>
                </div>
                <div className={styles.item}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className={`${styles.title} ${styles['title-items']}`}>
                            <div>Task Time Info</div>
                            {profile?.role?.toLocaleLowerCase() !== 'client' && task ? <div className={styles['title-actions']}><StartStop task={task} elapsedTime={elapsedTime} setElapsedTime={setElapsedTime} useSmallIcons={true} /></div> : ''}
                        </div>
                        {   task?.timerStatus?.toLowerCase() !== TimerStatus.Stopped.toLowerCase() && 
                            <div className={styles.detail}>
                                <div>Elapsed Time</div>
                                <div>{formattedElapsed}</div>
                            </div>
                        }
                        {   profile && profile.role?.toLocaleLowerCase() !== 'client' && 
                            <div className={styles.detail}>
                                <div>Estimated Duration</div>
                                <div>{formattedEstimatedDuration}</div>
                            </div>
                        }
                        {   (task?.status?.toLocaleLowerCase() === 'completed' || task?.status?.toLocaleLowerCase() === 'completed late') && 
                            <div className={styles.detail}>
                                <div>Duration</div>
                                <div>{formattedCompleteElapsedTime}</div>
                            </div>
                        }
                        <div className={styles.detail}>
                            <div>Start Time</div>
                            <div>{task?.startTime ? dayjs(task?.startTime).format('MM/DD/YYYY HH:mm z') : '-'}</div>
                        </div>
                        <div className={styles.detail}>
                            <div>End Time</div>
                            <div>{task?.endTime ? dayjs(task?.endTime).format('MM/DD/YYYY HH:mm z') : '-'}</div>
                        </div>
                    </Paper>
                </div>
            </div>
            {process.env.REACT_APP_ENABLE_CAMERA?.toString().toLowerCase() === 'true' && showCameraModal && <Modal title='Capture Image' closeModal={() => setShowEditModal(true)}><Camera sendFile={upload} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} parentCallback = {callback} /></Modal>}
        </>
    )
}

type ImageProps = ({ attachments?: Attachment[] });
const ImageContent = ( { attachments }: ImageProps ) => {
    const history = useHistory();
    const [currentAttachment, setCurrentAttachment] = useState<Attachment | undefined>(attachments ? attachments[0] : undefined);

    const images: ReactImageGalleryItem[] | undefined = attachments?.map(a => {
        const caption = a.fileName ? 
                        `${ a.fileName } - ${a.createdOn && dayjs(a.createdOn).format('MM/DD/YYYY HH:mm')}` : 
                        a.createdOn && dayjs(a.createdOn).format('MM/DD/YYYY HH:mm');
        return {
            original: a.uri,
			thumbnail: a.uri, 
            description: caption
        }
    });

    const updateCurrentImage = (imagePath: string) => {
        const attachment = attachments?.find(a => a.uri === imagePath);
        setCurrentAttachment(attachment);
    }

    return (
        <div style={{ marginTop: '20px' }} className={styles['table-container']}>
            { attachments && attachments.length > 0 && images && images.length > 0 ? 
                <div className={styles['image-data']}>
                    <div className={styles.title}>
                        <div className={styles['title-item']} onClick={() => history.push(`/tasks/${encodeURIComponent(currentAttachment?.workTaskId || '')}`)}>
                            {currentAttachment?.workTaskName || currentAttachment?.workTaskId || 'No task name available'}
                        </div>
                    </div>
                    <PhotoGallery images={images} updateCurrentImage={updateCurrentImage} /> 
                </div> : 
                <div className={emptyImagesStyle['no-data']}>No images found for this task. Click the camera button to upload a new image.</div> }
        </div> 
    )
}

const TaskItem = () => {
    const history = useHistory();

    const dispatch = useDispatch();
    type Params = { id: string; }
    const { id } = useParams<Params>();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo:any = params.get('query');
    // console.log("foo", foo);

    let obj:any = [];
    let allData = localStorage.getItem('allData');
    if(allData != undefined){

        obj = JSON.parse(allData);
        
    }

    let [index,setIndex] = useState<any>(foo)
    const [task, setTask] = useState<Task | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    
    type TasksStore = { tasks: Task[], loading: boolean, error: any };
    const tasksStore = useSelector((state: { tasksStore: TasksStore }) => state.tasksStore);

    useEffect(() => {
        dispatch(fetchTasks(25, 1, decodeURIComponent(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // console.log("idddd>>", decodeURIComponent(id))
    }, [])
    useEffect(() => {
        if (tasksStore.loading) setTask(undefined);
        else {
            setTask(tasksStore.tasks[0]);
        }
    }, [tasksStore])

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }
    const handleTaskChange = (newValue:boolean) =>{
        setShowTaskModal(newValue);
        setShowEditModal(newValue);
       
      }
    const discardTask = () => {
        setShowTaskModal(false);
        setShowEditModal(false);
    }

    const previousTask = ()=>{
        if(parseInt(index) - 1 != -1){
			let single_data = obj[parseInt(index)-1];

			setIndex(parseInt(index)-1)
			history.push(`/tasks/${encodeURIComponent(single_data.id || '')}?query=${parseInt(index)-1}`)
			window.location.reload();
        }
		else {
			let single_data = obj[obj.length - 1];

			setIndex(obj.length - 1);
			console.log('pre click', single_data.id);
			history.push(
				`/tasks/${encodeURIComponent(single_data.id || '')}?query=${obj.length - 1}`
			);
			window.location.reload();
		}

    }

    const nextTask = ()=>{
		let localIndex = 0;
		if (parseInt(index) + 1 < obj?.length) {
			localIndex = parseInt(index) + 1;
			setIndex(localIndex);
		}

		let single_data = obj[localIndex];
        history.push(`/tasks/${encodeURIComponent(single_data.id || '')}?query=${localIndex}`)
        window.location.reload();
    }

    const tabItems: Tab[] = [{ id: 1, name: 'Comments' },
    // { id: 2, name: 'History' },
    { id: 3, name: 'Images' }];
    const tasksContent = (
        <>
            <Title headingTitle='Task Comments' hideTitle={true} addItemText={'New Comment'} setShowModal={setShowModal} />
            <div className={styles['filter-section']}>&nbsp;</div>
            <CommentsTable showSort={false} showModifies={false} filterValue={''} task={task || undefined} />
            {showModal && <Modal title='Add Comment' closeModal={() => setShowEditModal(true)}><CommentForm task={task || undefined} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal>}
        </>
    );
    const historyContent = (
        <div className={styles['table-container']}>
            <History showSort={true} showTitle={false} history={task?.changeHistory?task.changeHistory:[]}/>
        </div>
    )
    return (
        <div className={styles['task-item-container']}>
            { task && <TaskItemInfo task={task} setShowTaskModal={setShowTaskModal} />}
            <div className={styles['tabs-container']}>
                <div className={styles.tabs}>
                    {tabItems.map(ti => <TabItem key={ti.id} tab={ti} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />)}
                </div>
                <div className={styles['tab-content']}>
                    {
                        selectedTab === 1 ? tasksContent :
                        selectedTab === 2 ? historyContent : 
                        selectedTab === 3 ? <ImageContent attachments={task?.attachments} /> : null
                    }
                </div>
            </div>
            {showTaskModal && <Modal title='Edit Task' closeModal={() => setShowEditModal(true)}><TaskForm task={task || undefined} isExisting={true}  showEModal={showEditModal} closeModal={handleTaskChange} discard={() => discardTask()} /></Modal>}
            <div className='Task-Comment-Next-Button'>
				<Button onClick={previousTask}>Previous</Button>
				<Button onClick={nextTask}>Next</Button>
			</div>
        </div>
        
    )
}

export default TaskItem;