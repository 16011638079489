import { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskOverviewChart from "../../components/charts/TaskOverviewChart";
import TimeLineChart from "../../components/charts/TimeLineChart";
import Select from "../../components/controls/select/Select";
import StructuresTable from "../../components/tables/StructuresTable";
import TasksActionTable from "../../components/tables/TasksActionTable";
import { Project } from "../projects/interfaces";
import { Task } from "../tasks/interfaces";
import { User } from "../users/interfaces";
import { fetchHomeDashboard } from "./actions";
import styles from './home.module.scss';
import { Dashboard } from "./interfaces";
import { Structure } from '../structures/interfaces';
import StructuresList from '../../components/lists/structures';
import { fetchProjects } from '../projects/actions';
import ProjectsTable from '../../components/tables/ProjectsTable';
import TasksTable from '../../components/tables/TasksTable';
import StructureCards from '../../components/cards/StructureCards';
import TaskCards from '../../components/cards/TaskCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const FieldUserDashboard = () => {
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState<Project>();
    const [taskStatus, setTaskStatus] = useState<string>('');
    const [reset, setReset] = useState<boolean>(true);
    const [selectedStructure, setSelectedStructure] = useState<any>();
    const [hideTables, setHideTables] = useState<boolean>(false);
    const [structureId, setStructureId] = useState("");
    const callback = useCallback((chartdata,status) => {
        setTaskChartData(chartData);
        if(status == ''){
            setChartData(chartdata);
            setProjectId(chartData?.id);
            setReset(false);
        }else{
            setTaskStatus(status);
        }
      }, []);

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;

    type HomeStore = { data: Dashboard, loading: boolean, error: any };
    const homeStore = useSelector((state: { homeStore: HomeStore }) => state.homeStore);
    const home = !(Object.keys(homeStore.data).length === 0);
    const projects = homeStore.data.projects;

    const [search, setSearch] = useState<string>('');
    type ProjectsStore = { projectsinfo: { projects: Project[], loading: boolean, error: any } };
    const projectsStore = useSelector((state: { projectsStore: ProjectsStore }) => state.projectsStore);
    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [selectedProject, setSelectedProject] = useState<Project[]>([]);
    const [projectId, setProjectId] = useState<any>("");
    const [taskChartData, setTaskChartData] = useState<Project | Dashboard>();
    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);
    //Take this values from store:
    const structures = homeStore.data.structures;

    useEffect(() => {
        dispatch(fetchProjects());
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    const resetStructureFilter = () => {
        setSelectedStructure([]);
        setHideTables(false);
        setStructureId("");
        setSelectedProject(homeStore.data.projects);
        setTaskChartData(homeStore.data);
        callback([],'');
        setReset(true);
    }

    const resetFilter = () => {
        if(hideTables)
        {
            resetStructureFilter();
        }
        else {
            callback([],'');
            setTaskChartData(homeStore.data);
            setReset(true);
        }
    }

    const filterByStructure = (projectId: string, selectedStructureId: string) => {
        const project = homeStore.data.projects.filter((project) => {
            return project.id === projectId
        });
        const selectedStructureData = structures.filter((structure) => {
            return structure.id === selectedStructureId
        });
        setReset(false);
        setSelectedStructure(selectedStructureData[0]);
        setHideTables(true);
        setSelectedProject(project);
        setChartData(project[0]);
        setTaskChartData(project[0]);
        setStructureId(selectedStructureId);
    };


    const projectRef = useRef<HTMLDivElement>(null);

    const TableView = (
        <div>
            {!hideTables &&
                <div>
                    <div>
                        <h4>Current Structures</h4>
                    </div>
                    <StructuresTable showSort = {false} filterValue = {""} projectId = {projectId} structureId = {structureId} />
                </div>
            }
                <div>
                <h4>Current Tasks</h4>
            </div>
                <TasksTable showSort={false} filterValue={""}  projectId = {projectId} structureId = {structureId} />
         </div>
    );

    const CardView = (
        <div>
            {!hideTables &&
                <div>
                    <div>
                        <h4>Current Structures</h4>
                    </div>
                    <div className={styles['cards-container']}>
                        <div className={styles.cards}>
                            <StructureCards filterValue = {""} projectId={projectId} />
                        </div>
                    </div>
                </div>
            }
            <div>
                <h4>Current Tasks</h4>
            </div>
            <div className={styles['cards-container']}>
                <div className={styles.cards}>
                    <TaskCards filterValue={""} projectId={projectId} taskStatus={taskStatus?taskStatus:''}/>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {home ?
                <>
                    {/* <div className={styles['input-item']}>
                        <Select placeholder='Project' items={projects} selectedItem={projectName === "" && projects !== null ? projects[0].name : projectName} setSelectedItem={setProject} selectref={projectRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name' />
                    </div> */}
                    {chartData && chartData.name &&
                        <div>
                            <p>Selected Project: <b>{chartData?.name}</b><FontAwesomeIcon icon={faTimesCircle} onClick={() => resetFilter()} /></p>
                        </div>
                    }
                    <div className={styles["chart-list"]}>
                        <div className={styles["chart-item"]}> 
                            <TimeLineChart project={homeStore.data.projects || []} parentCallback={callback} selected={null} resetFilter={null} />
                        </div>
                        <div className={styles["chart-item"]}>
                            {selectedStructure && selectedStructure.id  && 
                                <div>
                                    <p style={{marginLeft : "122px"}} >Selected Structure: <b>{selectedStructure?.name}</b><FontAwesomeIcon icon={faTimesCircle} onClick={() => resetStructureFilter()} /></p>
                                </div>
                            }
                            <StructuresList reset = {reset} structures={selectedStructure?.id ? homeStore.data.structures.filter(s => s.projectId === selectedStructure.projectId) : homeStore.data.structures} selectedProject={reset ? homeStore.data.projects[0] : selectedProject[0]}  applyFilter={filterByStructure}  />
                        </div>
                        <div className={styles["chart-item"]}>
                            <TaskOverviewChart dashboardData={(taskChartData) || (reset ? homeStore.data :(chartData?.id?chartData:homeStore.data))} parentCallback={callback}/>
                        </div>
                    </div>
                    {/* <div>
                        <h4>Current Task</h4>
                    </div>
                    <TasksActionTable />

                    <div>
                        <h4>Current Structures</h4>
                    </div>
                    <StructuresTable showSort={false} filterValue={search} /> */}
                     {isCard ? CardView : TableView}

                </>
                : ''}
        </div>
    );
}

export default FieldUserDashboard;