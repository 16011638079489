import { useEffect, useState } from 'react';
import { Chart, Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import 'chartjs-adapter-moment';
import 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import styles from './charts.module.scss';
Chart.register(zoomPlugin);

const TimeLineChart = ({ project, parentCallback, selected, resetFilter }) => {
	const [chartData, setChartData] = useState([]);
	const [minDate, setMinDate] = useState(new Date());
	const [maxDate, setMaxDate] = useState(new Date());
	const daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const currentDay = daysList.indexOf(daysList[(new Date()).getDay()]);
	let zoomUpperLimit = new Date();
	zoomUpperLimit.setDate(minDate.getDate() + 56);

	useEffect(() => {
		if (project) {
			var data = [];
			// const startDates: any[] = [];
			// const endDates: any[] = [];
			project.forEach((project) => {
				var dates = [];
				dates.push(project.scheduledStartDate);
				dates.push(project.scheduledEndDate);
				// startDates.push(new Date(project.scheduledStartDate ? project.scheduledStartDate : ''));
				// endDates.push(project.scheduledEndDate ? new Date(project.scheduledEndDate) : new Date());
				data.push(dates);
			});
			setChartData(data);
		}
		let chartMinDate = new Date();
		chartMinDate.setDate(chartMinDate.getDate() - 3);
		setMinDate(chartMinDate);
		let chartMaxDate = new Date();
		chartMaxDate.setDate(chartMaxDate.getDate() + 14);
		setMaxDate(chartMaxDate);
	}, [project]);

	const data = {
		labels: project.map((p) => p.name),
		datasets: [
			{
				axis: 'y',
				label: 'Project Timeline',
				data: chartData,
				fill: false,
				backgroundColor: ['rgb(73,1,1)'],
				borderWidth: 1
			}
		]
	};
	const plugins = [
		{
			afterDraw: function (chart) {
				// find coordinates
				if (chart?.scales?.x?.ticks && chart.scales.x.ticks.length) {
					const ticks = chart.boxes[3]._labelItems.find(
						(i) =>
							i.label.toLocaleLowerCase() ===
							dayjs(new Date()).format('MMM DD YY').toLocaleLowerCase()
					);
					const { ctx } = chart;

					const topY = chart.scales.y.top;
					const bottomY = chart.scales.y.bottom;

					// draw vertical line
					if (ticks && ticks.translation.length) {
						ctx.save();
						ctx.beginPath();
						ctx.moveTo(ticks.translation[0], topY);
						ctx.lineTo(ticks.translation[0], bottomY);
						ctx.lineWidth = 3;
						ctx.strokeStyle = '#09b409';
						ctx.stroke();
						ctx.restore();
					}
				}
			}
		}
	];

	console.log('timeline data', data)
	return (
		<Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '320px'
            }}
        >
			<div className={styles['chart-container']}>
				<div className={styles.title}>
					<div>Project Timelines</div>
					{selected?.name && <div className={styles['selected-title']}>{selected?.name}&nbsp;<FontAwesomeIcon className={styles.icon} icon={faTimesCircle} onClick={() => resetFilter()} /></div>}
				</div>
				{/* <div className={styles['chart-info']}> */}
					<div style={{maxHeight:'260px'}}>
					<Bar
						data={data}
						// width={'300'}
						height={'400'}
						plugins={plugins}
						options={{
							indexAxis: 'y',
							// responsive: true,
							maintainAspectRatio: false,
							scales: {
								x: {
									type: 'time',
									min: minDate.getTime(),
									max: maxDate.getTime(),
									position: 'bottom',
									time: {
										isoWeekday : currentDay,
										tooltipFormat: 'MMM DD YY',
										displayFormats: {
											millisecond: 'MMM DD YY',
											second: 'MMM DD YY',
											minute: 'MMM DD YY',
											hour: 'MMM DD YY',
											day: 'MMM DD YY',
											week: 'MMM DD YY',
											month: 'MMM DD YY',
											quarter: 'MMM DD YY',
											year: 'MMM DD YY'
										},
										unit: 'week'
									}
								}
							},
							onClick: function(evt, element) {
								if(element.length > 0)
								{
									var ind = element[0].index;
									const selectedProject = project.filter(x=>x.name=== data.labels[ind]);
									parentCallback(selectedProject[0],'');
								}
							},
							plugins: {
								// title: {
								// 	display: true,
								// 	text: 'Project Timelines'
								// },
								zoom : {
									zoom : {
										wheel : {
											enabled: true,
										},
										mode : 'x',
										onZoomStart: ({chart,event,point}) => {
											let newMaxDate = new Date();
											let lowerLimit = new Date();
											lowerLimit.setDate(lowerLimit.getDate() + 14)
											newMaxDate.setDate(maxDate.getDate() + 42);
											// Mouse wheel up scroll
											if(event.deltaY < 0 && maxDate.getTime() <= newMaxDate.getTime())
											{
												let increaseMaxDate = new Date(maxDate.getTime());
												increaseMaxDate.setDate(maxDate.getDate() + 7);
												setMaxDate(increaseMaxDate);
											}
											// Mouse wheel down scroll
											else if(event.deltaY > 0 && maxDate.getTime() >= (lowerLimit.getTime())) {
												let decreaseMaxDate = new Date(maxDate.getTime());
												decreaseMaxDate.setDate(maxDate.getDate() - 7);
												setMaxDate(decreaseMaxDate);
											}
											//Prevent scrolling when max limit(8 weeks) or min limit(3 weeks) arrived.
											else {
												return false;
											}
											return true;
										},
									},
									pan: {
										enabled: true,
									},
									limits: {
										x: {min: minDate, max: zoomUpperLimit}
									}
								},
								legend: {
									display: false
								},
								tooltip: {
									callbacks: {
										label: function (context) {
											let label = context.dataset.label || '';
											const dateTimeFormat = 'MMM DD YYYY';
											const dateArray = context.formattedValue
												.substring(1, context.formattedValue.length - 1)
												.split(', ')
												.map((x) =>
													dayjs(new Date(parseInt(x))).format(dateTimeFormat)
												);
											label +=
												' ' +
												dateArray[0].toString() +
												' - ' +
												dateArray[1].toString();
											return label;
										}
									}
								}
							}
						}}
					/>
					</div>
				{/* </div> */}
			</div>
		</Paper>
	);
};

export default TimeLineChart;
