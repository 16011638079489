import { useRef, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

export interface FilterInfo {
    items: any[], 
    filterValue: string, 
    isItemsObject: boolean, 
    objectFieldNames: string[]
}
export interface SortInfo {
    items: any[], 
    valueType: string, 
    isAsc: boolean, 
    isItemsObject: boolean, 
    objectFieldName: string
}

export const useEventListener = (eventName: any, handler: any, element = window) => {
    // Create a ref that stores handler
    const savedHandler = useRef<any>();
    
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
  
    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On 
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;
            
            // Create event listener that calls handler function stored in ref
            const eventListener = (event: any) => savedHandler.current(event);
            
            // Add event listener
            element.addEventListener(eventName, eventListener);
            
            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

export const usePrevious = (value: any) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

export const useItemsFilter = ({items, filterValue, isItemsObject, objectFieldNames}: FilterInfo) => {
    if (!filterValue) return items;

    if (isItemsObject) {
        const updatedItems: any[] = [];
        objectFieldNames.forEach(ofn => {
            const filterItems = items.filter(i => i[ofn]!=null && i[ofn].toLowerCase().includes(filterValue.toLowerCase()));
            filterItems.forEach(item => {
                if (!updatedItems.includes(item)) updatedItems.push(item);
            })
        });
        return updatedItems
    } 
    return items.filter(i => i.toLowerCase().includes(filterValue.toLowerCase()));
}

export const useItemsSort = ({items, valueType, isAsc, isItemsObject, objectFieldName}: SortInfo) => {
    if (!valueType) return items;
    if (isItemsObject) {
        if (valueType === 'string') return isAsc ? [...items].sort((a, b) => (a[objectFieldName] > b[objectFieldName] ? 1 : -1)) : 
                                                   [...items].sort((a, b) => (b[objectFieldName] > a[objectFieldName] ? 1 : -1));
        if (valueType === 'date') return isAsc ? [...items].sort((a, b) => dayjs(a[objectFieldName]).diff(b[objectFieldName])) : 
                                                 [...items].sort((a, b) => dayjs(b[objectFieldName]).diff(a[objectFieldName]));
    }
    else {
        if (valueType === 'string') return isAsc ? [...items].sort((a, b) => (a > b ? 1 : -1)) : [...items].sort((a, b) => (b > a ? 1 : -1));
        if (valueType === 'date') return isAsc ? [...items].sort((a, b) => dayjs(a).diff(b)) : [...items].sort((a, b) => dayjs(b).diff(a));
    }
    return items;
}

export const dots = '...';
export const usePagination = (totalCount: number, pageSize: number, currentPage: number, siblingCount: number = 1) => {
    const range = (start: number, end: number) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
        We do not want to show dots if there is only one position left 
        after/before the left/right page count as that would lead to a change if our Pagination
        component size which we do not want
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, dots, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, dots, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex];
        }

    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
}