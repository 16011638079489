import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import 'react-image-gallery/styles/scss/image-gallery.scss';

type Props = ({ images: ReactImageGalleryItem[], updateCurrentImage(arg: string): void });
const PhotoGallery = ({ images, updateCurrentImage }: Props) => {	
	
	const validateImages = () => {
		const validImages: ReactImageGalleryItem[] = [];
		const validExt = ['png', 'jpg', 'svg'];
		
		images.forEach(i => {
			const ext = i.original.substr(i.original.lastIndexOf('.') + 1);
			if (ext && (validExt.indexOf(ext.toLocaleLowerCase()) > -1)) validImages.push(i);
		});
		return validImages;
	}

	return (
		<section className='app'>
			<ImageGallery
				items={validateImages()}
				infinite={true}
				showBullets={true}
				showFullscreenButton={true}
				showPlayButton={true}
				showThumbnails={true}
				showIndex={false}
				showNav={true}
				isRTL={false}
				thumbnailPosition='left'
				slideDuration={450}
				slideInterval={2000}
				slideOnThumbnailOver={false}
				additionalClass='app-image-gallery'
				onSlide={i => updateCurrentImage(images[i].original)}
			/>
		</section>
	);
}

export default PhotoGallery;