import axios, { AxiosError } from 'axios';

import { FETCH_PROJECTS, FETCH_PROJECT_DETAIL, DELETE_PROJECT, SAVE_PROJECT, CREATE_PROJECT } from '../../store/actions/actionTypes';
import { RECCE_PROJECTS, RECCE_PROJECT_DETAIL } from '../../store/api/endPoints';
import { Project } from './interfaces';
import { svcconfig } from '../../store/actions/actions';

// const svcconfig = {headers: {'Pragma': 'no-cache',  'Content-Type': 'application/json'}};

export const fetchProjects = (pageSize?: number, pageNumber?: number) => {
    const size = pageSize ? `PageSize=${pageSize}` : null; const number = pageNumber ? `PageNumber=${pageNumber}` : null; 
    const endpoint = size && number ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROJECTS}?${size}&${number}` : `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROJECTS}`;
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_PROJECTS, 
            payload: axios.get(endpoint, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                    // sessionStorage.removeItem('token');
                    // window.location.href = '/logout';
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}
export const fetchProjectDetail = (projectId: string, userId: string) => {
    const request = { projectId: projectId, userId: userId };
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_PROJECT_DETAIL, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROJECT_DETAIL}`, request, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}
export const deleteProject = (project: Project) => {
    project.status = 'Inactive';
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: DELETE_PROJECT, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROJECTS}`, project, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return project;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}
export const saveProject = (project: Project) => {
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: SAVE_PROJECT, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROJECTS}`, project, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}
export const createProject = (project: Project) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CREATE_PROJECT, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_PROJECTS}`, project, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}