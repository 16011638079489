import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './cards.module.scss';
import { User } from '../../views/users/interfaces';
import { deleteUser } from '../../views/users/actions';
import { DeleteModal, Modal } from '../modal/Modal';
import UserForm from '../forms/UserForm';
import DeletePopup from '../popup/DeletePopup';

type Props = ({ user: User });
export const UserActionInfo = ({ user }: Props) => {
    const dispatch = useDispatch();
    
    const [showViewMode, setViewMode] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);

    type PermissionsStore = { canEditUsers: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    return (
        <>
            <button type='button' className={styles.btn} onClick={() => { setShowModal(true); setViewMode(true); }}>View</button>
            {   permissionsStore.canEditUsers && 
                <>
                    <button type='button' className={styles.btn} onClick={() => { setShowModal(true); setViewMode(false); }}>Edit</button>
                    <button type='button' className={styles.btn} onClick={() => { setShowDeleteModal(true); setViewMode(false); }}>Delete</button>
                    { showModal && permissionsStore.canEditUsers && <Modal title='Edit Project' closeModal={() => setShowEditModal(true)}><UserForm user={user} isExisting={true} isViewMode={showViewMode} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} isProfile={profileStore?.profile?.azureId === user?.azureId} /></Modal> }
                    { showDeleteModal && <DeleteModal title='Delete User' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={user?.firstName+" "+user?.lastName || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(user){dispatch(deleteUser(user))}setShowDeleteModal(false);}}/></DeleteModal> }
                </>
            }
        </>
    )
}
export const UserCardHeaderInfo = ({ user }: Props) => {
    return (
        <div>
            <div className={styles.info}>
                <div className={styles['info-item']}>{user.company}</div>
                <div className={styles['info-item']}>{user.email}</div>
                <div className={styles['info-item']}>{user.phoneNumber}</div>
            </div>
        </div>
    )
}

const UsersCard = ({ user }: Props) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showViewMode, setViewMode] = useState<boolean>(false);
    

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);

    return (
        <div className={styles['card-container']}>
              <div className={styles.heading}>
                <div>{user.firstName} {user.lastName}</div>
            </div>
            <div className={styles.info}>
                <div className={styles['info-item']}>
                    <div>Email: </div>
                    <div>{user.email}</div>
                </div>
                <div className={styles['info-item']}>
                    <div>Company: </div>
                    <div>{user.company}</div>
                </div>
                <div className={styles['info-item']}>
                    <div>Title: </div>
                    <div>{user.title}</div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles['clickable-item']} onClick={() => { setShowModal(true); setSelectedUser(user); setViewMode(true);}}><FontAwesomeIcon icon={faEye} /></div>
                <div className={styles['clickable-item']} onClick={() => { setShowModal(true); setSelectedUser(user); setViewMode(false);}}><FontAwesomeIcon icon={faPencilAlt} /></div>
                <div className={styles['clickable-item']} onClick={() => { dispatch(deleteUser(user));}}><FontAwesomeIcon icon={faTrashAlt} /></div>
            </div>
            { showModal && <Modal title={showViewMode?'User Information':'Edit User'} closeModal={() => setShowModal(false)}><UserForm user={selectedUser || undefined} isExisting={true} isViewMode={showViewMode} closeModal={() => setShowModal(false)} isProfile={profileStore.profile?.azureId === selectedUser?.azureId} /></Modal> }
            
        </div>
    )
}

export default UsersCard;