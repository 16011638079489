import { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import data from '../../data/history-data.json';
import { FilterInfo, useItemsFilter, SortInfo, useItemsSort } from '../../hooks/hooks';
import { ChangeHistory } from '../../views/history/interfaces';
import dayjs from 'dayjs';

type Props = ({ showSort: boolean, filterValue: string, history: ChangeHistory[] });
const HistoryTable = ({ showSort, filterValue, history }: Props) => {
    const [selectedRow, setSelectedRow] = useState<string>('');

    const [sortType, setSortType] = useState<string>('date-desc');
    const [selectedSort, setSelectedSort] = useState<any>(null);


    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ?
            setSortType(headingInfo.field + '-asc') :
            setSortType(headingInfo.field + '-desc');

        setSelectedSort(headingInfo);
    }

    // setup of filters
    const filterInfo: FilterInfo = { items: history, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['date', 'modifiedBy', 'description'] };
    const filterHistory = useItemsFilter(filterInfo);

    // setup sorting initial sort is set to 'date' type and using value of 'modified date'
    const sortInfo: SortInfo = { items: filterHistory, valueType: selectedSort?.valuetype || 'date', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'date' };
    const sortedHistory = useItemsSort(sortInfo);

    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    <tr>
                        {
                            theads.history.map(h => {
                                return (
                                    <th key={h.id} colSpan={h.colspan} onClick={() => h.sort ? handleSort(h) : null}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title}&nbsp;</div>
                                            {showSort && h.sort ?
                                                h.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                                    h.field?.toLowerCase() + '-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> :
                                                        <FontAwesomeIcon icon={faSort} /> : null
                                            }
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>{
                    sortedHistory.map(r => (
                        <tr key={r.id} className={cx(styles['row-item'], { [styles['selected-item']]: selectedRow === r.id })}>
                            <td colSpan={2}>{r.createdOn ? dayjs(r.createdOn).format('MM/DD/YYYY') : ''}</td>
                            <td colSpan={2}>{r.userFullName}</td>
                            <td style={{textAlign:'left', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}  colSpan={2}>{r.description}</td>

                        </tr>
                    ))
                }
                </tbody>
            </table>

        </div>
    )
}

export default HistoryTable;

