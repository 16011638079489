import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import { Task, Comment } from '../../views/tasks/interfaces';

type ReportCommentsProps = ({ comments: Comment[], loading: boolean, error: any });
export const ReportCommentsTable = ({ comments, loading, error }: ReportCommentsProps) => {
    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    <tr>
                        {   theads.reportcomments.map(h => {
                                return (
                                    <th key={h.title}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title}&nbsp;</div>
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                {   loading ?
                    <tbody>
                        <tr className={styles['row-spinner']}><td colSpan={3}>
                            <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                <div className={styles.spinner}></div>
                            </div>
                        </td></tr>
                    </tbody> : 
                    <tbody>
                        {   comments.length === 0 ?
                                <tr>
                                    <td colSpan={3}><div className={styles['no-data']}>There are currently no comments.</div></td>
                                </tr>
                            :
                            comments.map(c => (
                                <tr key={c.comment} className={styles['row-item']}>
                                    <td>{new Date(c.createdOn).toLocaleString()}</td>
                                    <td>{c.authorFullName}</td>
                                    <td>{c.comment}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

type Props = ({ showSort: boolean, showModifies: boolean, task?: Task, filterValue: string });
const CommentsTable = ({ showSort, showModifies, task, filterValue }: Props) => {
    const [sortType, setSortType] = useState<string>('end-desc');
    const [selectedSort, setSelectedSort] = useState<any | null>(null);

    type TasksStore = { tasks: Task[], loading: boolean, error: any };
    const tasksStore = useSelector((state: { tasksStore: TasksStore }) => state.tasksStore);

    //used for column sorting
    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ?
            setSortType(headingInfo.field + '-asc') :
            setSortType(headingInfo.field + '-desc');

        setSelectedSort(headingInfo);
    }

    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    <tr>
                        {   theads.comments.map(h => {
                                return (
                                    (showModifies && h.title === '') || h.title !== '' ?
                                    <th key={h.id} colSpan={h.title === '' ? 1 : 2} onClick={() => handleSort(h)}>
                                        <div className={styles['col-labels']}>
                                            <div>{h.title}&nbsp;</div>
                                            { showSort && h.sort ? 
                                            h.field?.toLowerCase()+'-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                            h.field?.toLowerCase()+'-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> : 
                                            <FontAwesomeIcon icon={faSort} /> : null}
                                        </div>
                                    </th>
                                    : null
                                )
                            })
                        }
                    </tr>
                </thead>
                {
                    tasksStore.loading ? 
                    <tbody>
                        <tr className={styles['row-spinner']}><td colSpan={6}>
                            <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                <div className={styles.spinner}></div>
                            </div>
                        </td></tr>
                    </tbody> :
                    <tbody>
                        {   !task?.comments || task.comments.length === 0 ?
                                <tr>
                                    <td colSpan={6}><div className={styles['no-data']}>There are currently no comments.</div></td>
                                </tr>
                            :
                            task?.comments?.map((c, i) => (
                                <tr key={i}>
                                    <td colSpan={2}>{new Date(c.createdOn).toLocaleString('en-US', { timeZoneName: 'short', hour12: false })}</td>
                                    <td colSpan={2}>{c.authorFullName}</td>
                                    <td colSpan={2}>{c.comment}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default CommentsTable