
import { CREATE_USERS, DELETE_USERS, FETCH_USERS, SAVE_USERS, USERS_ERROR } from '../../store/actions/actionTypes';
import { UserState } from './interfaces';

const initialState: UserState = {
    users: [],
    loading: false,
    error: null, 
    pagesize: 0,
    pagenumber: 0,
    pagecount: 0, 
    resultstotal: 0
}

const usersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${FETCH_USERS}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${FETCH_USERS}_FULFILLED`: {
            return {
                ...state, 
                loading: false, 
                users: action.payload.data, 
                pagesize: action.payload.pageSize,
                pagenumber: action.payload.pageNumber,
                pagecount: action.payload.pageCount, 
                resultstotal: action.payload.resultsTotal
            }
        }
        case `${SAVE_USERS}_PENDING`: { 
            return {
                ...state, 
                loading: true
            }
        }
        case `${SAVE_USERS}_FULFILLED`: {        
            state.users.splice(state.users.findIndex(u => u.id === action.payload.data.id), 1);
            state.users.push(action.payload.data);
            return {
                ...state, 
                loading: false
            }
        }
        case `${DELETE_USERS}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${DELETE_USERS}_FULFILLED`: {
            state.users.splice(state.users.findIndex(p => p.id === action.payload.id), 1);
           // state.users.push(action.payload.data);
            return {
                ...state, 
                loading: false
            }
        }
        case `${CREATE_USERS}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${CREATE_USERS}_FULFILLED`: {
            state.users.push(action.payload.data)
            return {
                ...state, 
                loading: false
            }
        }
        case `${USERS_ERROR}`:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state;
    }
}

export default usersReducer;