import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './forms.module.scss';
import Select from '../controls/select/Select';
import { Project } from '../../views/projects/interfaces';
import { DailyReportSummary } from '../../views/reports/interfaces';
import { TaskTableUI } from '../tables/TasksTable';
import { ReportCommentsTable } from '../tables/CommentsTable';
import { createDailyReport, fetchDailyReportData, saveDailyReport } from '../../views/reports/actions';
import { User } from '../../views/users/interfaces';
import { CurrentUserDetails } from '../helpers/CurrentUserDetails';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';
import selector from '../controls/select/select.module.scss';

type Props = ({ dailyReport?: DailyReportSummary, isExisting?: boolean, closeModal: any, project?: Project, showEModal?: boolean, discard ?: any  });
const ReportsForm = ({ dailyReport, isExisting, closeModal, project, showEModal, discard  }: Props) => {
    const dispatch = useDispatch();
    const [disabledProject, setDisabledProject] = useState<boolean>(false);

    type ProjectsStore = { projectsinfo: { projects: Project[], loading: boolean, error: any } };
    const projectsStore = useSelector((state: { projectsStore: ProjectsStore }) => state.projectsStore);
    const projects = projectsStore.projectsinfo.projects;
    useEffect(() => {
        // used to set project details if create report triggres inside project detail page
        if (project) {
            setDisabledProject(true);
            setCurrentDailyReport({ ...currentDailyReport, projectId: project.id || '', projectName: project.name });
        }
        // used to set project name for edit report
        if (dailyReport && projects.length > 0) {
            var obj: any;
            obj = projects.filter(p => p.id === dailyReport?.projectId)[0];
            if (obj) setCurrentDailyReport({ ...currentDailyReport, projectName: obj.name });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project])

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;

    type DailyReportsStore = {
        selectreportinfo: {
            dailyreport: {
                projectId: '',
                reportDate: undefined,
                status: undefined,
                userId: undefined,
                authorName: '',
                notes: '',
                completedWorkTasks: [],
                overdueWorkTasks: [],
                taskComments: []
            }, loading: boolean, error: any
        }
    };
    const dailyReportsStore = useSelector((state: { dailyReportsStore: DailyReportsStore }) => state.dailyReportsStore);

    const [currentDailyReport, setCurrentDailyReport] = useState<DailyReportSummary>({
        id: dailyReport?.id, projectId: dailyReport?.projectId || '', projectName: dailyReport?.projectName || '', reportDate: dailyReport?.reportDate || new Date(), status: dailyReport?.status, userId: dailyReport?.userId || profile?.id, authorName: dailyReport?.authorName || profile?.firstName + " " + profile.lastName, notes: dailyReport?.notes,
        completedTaskIds: dailyReport?.completedTaskIds, overdueTaskIds: dailyReport?.overdueTaskIds, workTaskIds: dailyReport?.workTaskIds, createdOn: dailyReport?.createdOn, createdBy: dailyReport?.createdBy,
        updatedOn: dailyReport?.updatedOn, updatedBy: dailyReport?.updatedBy
    });

    const [completedWorkTasks, setCompletedWorkTasks] = useState([]);
    const [overdueWorkTasks, setOverdueWorkTasks] = useState([]);
    const [taskComments, setTaskComments] = useState([]);
    const [showProjectRequired, setShowProjectRequired] = useState<boolean>(false);
    //States For Confirmation modal before save/create
    const [showEditModal, setShowEditModal] = useState<boolean>(false);  
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    useEffect(() => {
        if (currentDailyReport.projectId && currentDailyReport.reportDate) {
            dispatch(fetchDailyReportData(currentDailyReport.projectId, currentDailyReport.reportDate, profile.id));
        } else {
            dailyReportsStore.selectreportinfo.dailyreport.completedWorkTasks = [];
            dailyReportsStore.selectreportinfo.dailyreport.overdueWorkTasks = [];
            dailyReportsStore.selectreportinfo.dailyreport.taskComments = [];
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDailyReport.projectId, currentDailyReport.reportDate])

    useEffect(() => {
        setCompletedWorkTasks(dailyReportsStore.selectreportinfo.dailyreport.completedWorkTasks);
        setOverdueWorkTasks(dailyReportsStore.selectreportinfo.dailyreport.overdueWorkTasks);
        setTaskComments(dailyReportsStore.selectreportinfo.dailyreport.taskComments);
    }, [dailyReportsStore.selectreportinfo.dailyreport.completedWorkTasks, dailyReportsStore.selectreportinfo.dailyreport.overdueWorkTasks,
    dailyReportsStore.selectreportinfo.dailyreport.taskComments])

    const setProject = (project: Project) => setCurrentDailyReport({ ...currentDailyReport, projectId: project.id || '', projectName: project.name });

    const setReportDate = (date: Date) => {setCurrentDailyReport({ ...currentDailyReport, reportDate: date }); setUnsavedChanges(true);};

    const currentUser = CurrentUserDetails();
    // used for save report
    const handleSaveDailyReport = () => {
        handleChange();
        if (!currentDailyReport?.projectId) setShowProjectRequired(true);
        if (currentDailyReport?.projectId) {
            currentDailyReport.completedTaskIds = [];
            dailyReportsStore?.selectreportinfo?.dailyreport?.completedWorkTasks.forEach(
                function (obj) {
                    currentDailyReport.completedTaskIds?.push(obj['id']);
                }
            )
            currentDailyReport.overdueTaskIds = [];
            dailyReportsStore?.selectreportinfo?.dailyreport?.overdueWorkTasks.forEach(
                function (obj) {
                    currentDailyReport.overdueTaskIds?.push(obj['id']);
                }
            )
            if(isExisting) {
                currentDailyReport.updatedBy = currentUser.userName;
                currentDailyReport.updatedById = currentUser.id;
            }
            else {
                currentDailyReport.createdBy = currentUser.userName;
                currentDailyReport.createdById = currentUser.id;
            }    
            dispatch(isExisting ? saveDailyReport(currentDailyReport) : createDailyReport({ ...currentDailyReport }));
            discard();
            closeModal();
        }

    }

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }

    const form = document.getElementById("reportForm");

    if(form && !unsavedChanges) {
        form.addEventListener("input", function () {
            setUnsavedChanges(true);
        });
    }

    const selectorItems = document.getElementsByClassName(selector.item);

    if(selectorItems.length > 0 && !unsavedChanges)
    {
        setUnsavedChanges(true);
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    const projectRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <div>
                <div className={styles['form-container']}>
                    <form id='reportForm'>
                        <div className={styles['inputs-section']}>
                            <div className={styles.inputs}>
                                <div className={styles['input-item']}>

                                    {showProjectRequired ? <label className={styles.required}>* project is required</label> : <label>{currentDailyReport?.projectId ? 'project' : ''}</label>}
                                    <Select placeholder='Project' items={projects} selectedItem={currentDailyReport?.projectName} setSelectedItem={setProject} selectref={projectRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name' disabled={disabledProject || isExisting === true} />
                                </div>
                            </div>
                            <div className={styles.inputs}>
                                <div className={styles['input-item']}>
                                    <label>{currentDailyReport?.reportDate ? 'Report Date' : ''}</label>
                                    <DatePicker placeholderText='Report Date' selected={currentDailyReport?.reportDate ? new Date(currentDailyReport.reportDate) : new Date()} onChange={setReportDate} disabled={isExisting === true}/>
                                </div>
                            </div>
                            <div className={styles.inputs}>
                                <div className={styles['input-item']}>
                                    <label>{currentDailyReport?.notes ? 'notes' : ''}</label>
                                    <textarea rows={3} cols={10} id="notes" name="notes" placeholder='Notes' value={currentDailyReport?.notes} onChange={(e) => setCurrentDailyReport({ ...currentDailyReport, notes: e.target.value })} />
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={styles.title}>Completed Tasks - {currentDailyReport?.reportDate ? dayjs(currentDailyReport.reportDate).format('MM/DD/YYYY') : dayjs(new Date()).format('MM/DD/YYYY')}</div>
                            <TaskTableUI tasks={completedWorkTasks || []}
                                loading={dailyReportsStore?.selectreportinfo.loading}
                                error={dailyReportsStore?.selectreportinfo.error}
                                filterValue={''} showSort={false}
                                isCompletedOnly={true} isOverdueOnly={false} canEdit={false} />
                        </div>
                        <div>
                            <div className={styles.title}>Overdue Tasks - {currentDailyReport?.reportDate ? dayjs(currentDailyReport.reportDate).format('MM/DD/YYYY') : dayjs(new Date()).format('MM/DD/YYYY')}</div>
                            <TaskTableUI tasks={overdueWorkTasks || []}
                                loading={dailyReportsStore?.selectreportinfo.loading}
                                error={dailyReportsStore?.selectreportinfo.error}
                                filterValue={''} showSort={false}
                                isCompletedOnly={false} isOverdueOnly={true} canEdit={false} />
                        </div>
                        <div>
                            <div className={styles.title}>Comments - {currentDailyReport?.reportDate ? dayjs(currentDailyReport.reportDate).format('MM/DD/YYYY') : dayjs(new Date()).format('MM/DD/YYYY')}</div>
                            <ReportCommentsTable comments={taskComments || []}
                                loading={dailyReportsStore?.selectreportinfo.loading}
                                error={dailyReportsStore?.selectreportinfo.error} />
                        </div>
                    </form>
                </div>
            </div>

            <div className={styles.actions}>
                <button onClick={() => {if(unsavedChanges) {setShowEditModal(true); } else {discard(); }}}>Cancel</button>
                <button onClick={() => handleSaveDailyReport()}>{isExisting ? 'Save' : 'Create'} Daily Report</button>
            </div>
            {(showEModal || showEditModal) && <EditModal title='UNSAVED CHANGES' closeModal={() =>{handleChange()}}><EditPopup  closeModal={() =>{handleChange()}} editEntity={() =>handleSaveDailyReport()} discardChanges = {() => discard()}/></EditModal> }
        </>
    )
}

export default ReportsForm;


