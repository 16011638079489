import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchProjects } from '../projects/actions';
import { updateSelectedProject } from '../../store/actions/actions';
import TaskOverviewChart from '../../components/charts/TaskOverviewChart';
import TimeLineChart from '../../components/charts/TimeLineChart';
import ProjectsTable from '../../components/tables/ProjectsTable';
import { Project, ProjectState } from '../projects/interfaces';
import styles from './home.module.scss';
import { Dashboard } from './interfaces';
import { Structure } from '../structures/interfaces';
import StructuresList from '../../components/lists/structures';
import Card, {DummyCard} from '../../components/cards/Card';
import { ProjectCardInfo, ProjectCardActionInfo, ProjectCardHeaderInfo } from '../../components/cards/ProjectsCard';
import TaskCards from '../../components/cards/TaskCards';
import TasksTable from '../../components/tables/TasksTable';
import StructureCards from '../../components/cards/StructureCards';
import StructuresTable from '../../components/tables/StructuresTable';
import { User } from '../users/interfaces';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const AdminDashboard = () => {
    const dispatch = useDispatch();
	type ProfileStore = { profile: User; loading: boolean; error: any };
	const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
	const profile = profileStore.profile;

    const [chartData, setChartData] = useState<Project>();
    const [reset, setReset] = useState<boolean>(false);
    const [taskStatus, setTaskStatus] = useState<string>('');
    const [selectedStructure, setSelectedStructure] = useState<Structure>();
    const [hideTables, setHideTables] = useState<boolean>(false);
    const [hideCurrTask, setHideCurrTask] = useState<boolean>(false);

    const callback = useCallback((chartdata,status) => {
		if (chartdata.length === 0) {
			profile.selectedProjectId = undefined;
			profile.selectedProjectName = undefined;
			dispatch(updateSelectedProject(profile));
		}
		else {
			profile.selectedProjectId = chartdata.id;
			profile.selectedProjectName = chartdata.name;
			dispatch(updateSelectedProject(profile));
		}
        setTaskChartData(chartData);
        if (status === ''){
            setChartData(chartdata);
            setReset(false);
        } else {
            setTaskStatus(status);
        }
      }, []);
    
    useEffect(() => {
        dispatch(fetchProjects());
		if (profile?.selectedProjectId) {
			let selectedProject = projectsStore?.projectsinfo?.projects?.filter((project) => {
				return project.id === profile?.selectedProjectId;
			});
			setChartData(selectedProject[0]);
		}

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);
    const structures = projectsStore?.projectsinfo?.projects ? 
                       projectsStore.projectsinfo.projects.reduce((groupedStructures: Structure[], project: Project) => {
                            if (project?.structures) groupedStructures = [...groupedStructures, ...project.structures];
                            return groupedStructures;
                       }, []) : [];
    
    type HomeStore = { data: Dashboard, loading: boolean, error: any };
    const homeStore = useSelector((state: { homeStore: HomeStore }) => state.homeStore);
    const home = !(Object.keys(homeStore.data).length === 0);

    //New code for Filtered project and Structure and Task Tables
    //Code Start
    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [selectedProject, setSelectedProject] = useState(projectsStore?.projectsinfo?.projects ? projectsStore?.projectsinfo?.projects.filter((project) => {
        return project.id === chartData?.id || project.id === profile?.selectedProjectId
    }) : []);
    const [taskChartData, setTaskChartData] = useState<Project | Dashboard | any>();
    const [structureId, setStructureId] = useState('');
    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);

    const resetFilter = () => {
        if(hideTables)
        {
            resetStructureFilter();
        }
        else {
            callback([],'');
            setTaskChartData(homeStore.data);
            setReset(true);
        }
    }

    const resetStructureFilter = () => {
        setHideCurrTask(false)
        setSelectedStructure([]);
        setHideTables(false);
        setStructureId('');
        setSelectedProject(homeStore.data.projects);
        setTaskChartData(homeStore.data);
        callback([],'');
        setReset(true);
    }

    const filterByStructure = (projectId: string, selectedStructureId: string) => {
        setHideCurrTask(true)
        const project = homeStore.data.projects.filter((project) => {
            return project.id === projectId
        });
        const selectedStructureData = homeStore.data.structures.filter((structure) => {
            return structure.id === selectedStructureId
        });
        setSelectedStructure(selectedStructureData[0]);
        setHideTables(true);
        setSelectedProject(project);
        setChartData(project[0]);
        setTaskChartData(selectedStructureData[0]);
        setStructureId(selectedStructureId);
    };

    const TableView = (
        <>
            {!hideTables &&
                <div className={styles['table-container']}>
                    {!chartData?.id &&
                        <div>
                            <div>
                                <h4>Current Projects</h4>
                            </div>
                            <ProjectsTable projects = {selectedProject.length ?selectedProject : homeStore.data.projects || []} loading = {false} error = {undefined} />
                        </div>
                    }
                    <div>
                        <h4>Current Structures</h4>
                    </div>
                    <StructuresTable showSort = {true} filterValue = {''} project = {selectedProject.length > 0 ? selectedProject[0] : projectsStore.projectsinfo.projects[0]}   projectId = {reset ? '' : chartData?.id } structureId = {structureId} />
                </div>
            }
           <div className={hideCurrTask?'':'hide'}>
           <div>
                <h4>Current Tasks</h4>
            </div>
            <TasksTable showSort={true} filterValue={''} structure = {selectedProject.length > 0 ? selectedProject[0].structures : structures} project = {selectedProject.length > 0 ? selectedProject[0] : projectsStore.projectsinfo.projects[0]} projectId = {reset ? '' : chartData?.id} taskStatus={taskStatus?taskStatus:''} structureId = {structureId} />
         </div>
        </>
    );

    const CardView = (
        <>
            {!hideTables &&
                <div>
                    {!chartData?.id &&
                        <div>   
                            <div>
                                <h4>Current Projects</h4>
                            </div>
                            <div className={styles['cards-container']}>
                                <div className={styles.cards}>
                                    {
                                        (selectedProject.length ?selectedProject : homeStore.data.projects || []).map(p => 
                                            <Card key={p.id} 
                                                title={p.name} 
                                                subtitle={p.type} 
                                                body={<ProjectCardInfo project={p} />} 
                                                actions={<ProjectCardActionInfo project={p} />}
                                                headerinfo={<ProjectCardHeaderInfo project={p} />} />)
                                    }
                                    {((selectedProject.length ?selectedProject : homeStore.data.projects || []).length % 2) ? <DummyCard /> : null}
                                </div>
                            </div>
                        </div>
                    }
                    <div>
                        <h4>Current Structures</h4>
                    </div>
                    <div className={styles['cards-container']}>
                        <div className={styles.cards}>
                            <StructureCards filterValue = {''} projectId = {reset ? '' : chartData?.id} structureId = {structureId} />
                        </div>
                    </div>
                </div>
            }
            <div>
                <h4>Current Tasks</h4>
            </div>
            <div className={styles['cards-container']}>
                <div className={styles.cards}>
                    <TaskCards filterValue={''} projectId={reset ? '' : chartData?.id} taskStatus={taskStatus ? taskStatus : ''} structureId = {structureId} />
                </div>
            </div>
        </>
    )
    //Code End

    return (
        <div>
            {home ?
                <>
                    <div className={styles['chart-list']}>

                        <div className={styles['chart-item']}> 
                            <TimeLineChart 
                                project={homeStore.data.projects || []} 
                                parentCallback={callback}
                                selected={chartData}
                                resetFilter={resetFilter}
                            />
                        </div>
                        <div className={styles['chart-item']}>
                            <StructuresList 
                                structures={selectedStructure?.id ? homeStore.data.structures.filter(s => s.projectId === selectedStructure.projectId) : homeStore.data.structures} 
                                behind={homeStore.data.structuresBehindScheduleCount}
                                active={homeStore.data.structuresInProgressCount}
                                selected={selectedStructure}
                                resetClick={resetStructureFilter}
                                upcoming={homeStore.data.structuresPendingCount}
                                selectedProject={chartData} 
                                reset={reset} 
                                applyFilter={filterByStructure} />
                        </div>
                        <div className={styles['chart-item']}>
                            <TaskOverviewChart 
                                dashboardData={(taskChartData) || (reset ? homeStore.data :(chartData?.id?chartData:homeStore.data))} 
                                parentCallback={callback}
                            />
                        </div>
                    </div>
                    
                    {isCard ? CardView : TableView}
                </>
                : ''}
        </div>
    );
}

export default AdminDashboard;