//Projects
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';

//Structure
export const FETCH_STRUCTURES = 'FETCH_STRUCTURES';
export const DELETE_STRUCTURES = 'DELETE_STRUCTURES';
export const SAVE_STRUCTURES = 'SAVE_STRUCTURES';
export const CREATE_STRUCTURES = 'CREATE_STRUCTURES';
export const UPLOAD_STRUCTURES = 'UPLOAD_STRUCTURES';
export const CLEAR_STRUCTURES_UPLOAD = 'CLEAR_STRUCTURES_UPLOAD';

//Reports
export const FETCH_DAILY_REPORTS = 'FETCH_DAILY_REPORTS'
export const FETCH_DAILY_REPORT_DETAIL = 'FETCH_DAILY_REPORT_DETAIL';
export const FETCH_DAILY_REPORT_DATA = 'FETCH_DAILY_REPORT_DATA';
export const CREATE_DAILY_REPORT = 'CREATE_DAILY_REPORT';
export const SAVE_DAILY_REPORT = 'SAVE_DAILY_REPORT';

//Profile
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROJECT_DETAIL = 'FETCH_PROJECT_DETAIL';
export const UPDATE_SELECTED_PROJECT_LOCAL = 'UPDATE_SELECTED_PROJECT_LOCAL';
export const UPDATE_SELECTED_PROJECT_API = 'UPDATE_SELECTED_PROJECT_API';
export const UPDATE_MAP_ZOOM_LEVEL_LOCAL = 'UPDATE_MAP_ZOOM_LEVEL_LOCAL';
export const UPDATE_MAP_ZOOM_LEVEL_API = 'UPDATE_MAP_ZOOM_LEVEL_API';

//Permissions
export const SET_ADMIN_PERMISSIONS = 'SET_ADMIN_PERMISSIONS';
export const SET_FIELD_USER_PERMISSIONS = 'SET_FIELD_USER_PERMISSIONS';
export const SET_CLIENT_PERMISSIONS = 'SET_CLIENT_PERMISSIONS';

//Users
export const FETCH_USERS = 'FETCH_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const SAVE_USERS = 'SAVE_USERS';
export const CREATE_USERS = 'CREATE_USERS';
export const USERS_ERROR = 'USERS_ERROR';

//HomeDashboard
export const FETCH_HOME_DASHBOARD = 'FETCH_HOME_DASHBOARD';

//task
export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_DEFAULT_TASKS = 'FETCH_DEFAULT_TASKS';
export const DELETE_TASK = 'DELETE_TASK';
export const SAVE_TASK = 'SAVE_TASK';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TIMER_TASK = 'UPDATE_TIMER_TASK';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const FETCH_OVERDUE_TASKS = 'FETCH_OVERDUE_TASKS';
