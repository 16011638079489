import React, { createContext, useRef, useState, useLayoutEffect } from 'react';


export const ModalContext = createContext(null);

const ModalContextProvider = ({ children } : any) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [modalContext, setModalContext] = useState<any>();

    useLayoutEffect(() => {
        setModalContext(modalRef.current)
    }, []);

    return (
        <>
            <ModalContext.Provider value={modalContext}>
                {children} 
            </ModalContext.Provider>
            <div ref={modalRef} />
        </>
    )
}

export default ModalContextProvider;