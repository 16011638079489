import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import styles from './users.module.scss';
import Title from '../../components/title/Title';
import { FilterInfo, useItemsFilter } from '../../hooks/hooks';
import Card, { DummyCard } from '../../components/cards/Card';
import UsersTable from '../../components/tables/UsersTable';
import { UserCardHeaderInfo, UserActionInfo } from '../../components/cards/UsersCard';
import { Modal } from '../../components/modal/Modal';
import UserForm from '../../components/forms/UserForm';
import { fetchUsers } from './actions';
import { UserState } from './interfaces';
import CardPagination from '../../components/pagination/card-pagination';
import TablePagination from '../../components/pagination/table-pagination';

const Users = () => {    
    const dispatch = useDispatch();

    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const usersStore = useSelector((state: { usersStore: UserState }) => state.usersStore);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    useEffect(() => {
        dispatch(fetchUsers(10, pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const handleWindowResize = () => window.innerWidth <= 1024 ? setIsCard(true) : setIsCard(false);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
    }

    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    // setup of filters
    const filterInfo: FilterInfo = { items: usersStore.users, filterValue: search, isItemsObject: true, objectFieldNames: ['firstName', 'lastName', 'email', 'company', 'title'] };
    const filterUsers = useItemsFilter(filterInfo);

    const Cards = (
        <div>
            <div className={styles['cards-container']}>
                <div className={styles.cards}>
                    {
                        usersStore.users.map(u => 
                            <Card 
                                key={u.azureId} 
                                title={`${u.firstName} ${u.lastName}`} 
                                subtitle={u.title || ''} 
                                headerinfo={<UserCardHeaderInfo user={u} />}
                                body={<div></div>}
                                actions={<UserActionInfo user={u} />}
                            />)
                    }
                    {(filterUsers.length % 2) ? <DummyCard /> : null}
                </div>
            </div>
            <CardPagination totalPages={Math.floor(usersStore.resultstotal % 10 > 0 ? (usersStore.resultstotal / 10) + 1 : usersStore.resultstotal / 10)} 
                currentPage={pageNumber} setCurrentPage={setPageNumber} />
        </div>
    )
    const Table = (
        <div>
            <UsersTable users={filterUsers} loading={usersStore.loading} error={usersStore.error}/>
            {usersStore.resultstotal && usersStore.resultstotal > 10 && 
            <TablePagination 
                totalPages={Math.floor(usersStore.resultstotal % 10 > 0 ? (usersStore.resultstotal / 10) + 1 : usersStore.resultstotal / 10)} 
                currentPage={pageNumber} setCurrentPage={setPageNumber} totalResults={usersStore.resultstotal} />}
        </div>
    )
    return (
        <div className={styles['users-container']}>
            <Title headingTitle='Users' addItemText='New User' setShowModal={setShowModal} />
            <div className={styles['filter-section']}>
                <FontAwesomeIcon icon={faFilter} />
                <div className={styles['input-item']}>
                    <input type='text' placeholder='filter data' onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            {isCard ? Cards : Table}
            {showModal && <Modal title='Create User' closeModal={() => setShowEditModal(true)}><UserForm showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} isProfile={false} /></Modal>}
        </div>
    )
}

export default Users;