import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import styles from './task.module.scss';
import Title from '../../components/title/Title';
import { Modal } from '../../components/modal/Modal';
import { Project } from '../projects/interfaces';
import { Structure } from '../structures/interfaces';
import { fetchTasks } from './actions';
import TasksTable from '../../components/tables/TasksTable';
import TaskForm from '../../components/forms/TaskForm';
import TaskCards from '../../components/cards/TaskCards';
import { TaskState } from './interfaces';
import CardPagination from '../../components/pagination/card-pagination';
import TablePagination from '../../components/pagination/table-pagination';
import { fetchOverdueTasks } from '../../views/tasks/actions';
import Filter from '../../components/filter/Filter';
import { User } from '../users/interfaces';

type Props = ({ showSort: boolean, showTitle: boolean, structure?: Structure, project?: Project });
export const TasksContent = ({ showSort, showTitle, structure, project }: Props) => {
    const dispatch = useDispatch();

    const [isCard, setIsCard] = useState(window.innerWidth <= 1024 ? true : false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [showEditModal, setShowEditModal] = useState<boolean>(false); 
    const [filterByStructureId, setFilterByStructureId] = useState<any>();
    const [filterByProjectId, setFilterByProjectId] = useState<any>();
    const [filterByStatus, setFilterByStatus] = useState<string | undefined>(undefined);
    const [filterByUserId, setFilterByUserId] = useState<string | undefined>(undefined);
    const [customPageSize, setCustomPageSize] = useState<number>(10);

	type ProfileStore = { profile: User; loading: boolean; error: any };
	const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
	const profile = profileStore.profile;
    type PermissionsStore = { canEditTasks: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);
    const tasksStore = useSelector((state: { tasksStore: TaskState }) => state.tasksStore);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        const urlParam = window.location.href.split("?");
        if (urlParam.length > 1 && urlParam[1] === 'overdue') {
            dispatch(fetchOverdueTasks("Overdue"));
        }
        return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        dispatch(fetchTasks(customPageSize || 10, pageNumber, undefined, filterByStructureId || structure?.id, filterByProjectId || project?.id || profile?.selectedProjectId, filterByStatus, filterByUserId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const handleWindowResize = () => window.innerWidth <= 1240 ? setIsCard(true) : setIsCard(false);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }
    const Cards = (
        <div className={styles['cards-container']}>
            <div className={styles.cards}>
                <TaskCards filterValue={search} />
            </div>
            <CardPagination totalPages={Math.floor(tasksStore.resultstotal % customPageSize > 0 ? (tasksStore.resultstotal / customPageSize) + 1 : tasksStore.resultstotal / customPageSize)} currentPage={pageNumber} setCurrentPage={setPageNumber} />
        </div>
    )
    const Table = (
        <div>
            <TasksTable showSort={showSort} filterValue={search} structure={structure} project={project} />
            {tasksStore.resultstotal && tasksStore.resultstotal > 0 ? 
            <TablePagination totalPages={Math.floor(tasksStore.resultstotal % customPageSize > 0 ? (tasksStore.resultstotal / customPageSize) + 1 : tasksStore.resultstotal / customPageSize)} pageSize = {customPageSize} currentPage={pageNumber} setCurrentPage={setPageNumber} totalResults={tasksStore.resultstotal} /> : null}
        </div>
    )
    return (
        <div className={styles['tasks-container']}>
            <Title headingTitle='Tasks' hideTitle={!showTitle} addItemText={permissionsStore.canEditTasks ? 'New Task' : ''} setShowModal={setShowModal} />
            <div className={styles['filter-section']}>
                <FontAwesomeIcon icon={faFilter} />
                <div className={styles['input-item']}>
                    <input type='text' placeholder='filter data' onChange={(e) => setSearch(e.target.value)} />
                </div>
                {
                    process.env.REACT_APP_ENABLE_ADVANCED_FILTER?.toString().toLowerCase() === 'true' &&
                    <Filter setStructureId={setFilterByStructureId} setProjectId={setFilterByProjectId} setCustomPageSize={setCustomPageSize} setFilterStatus={setFilterByStatus} setUserId={setFilterByUserId} />
                }
            </div>
            {isCard ? Cards : Table}
            {showModal && <Modal title='Add Task' closeModal={() => setShowEditModal(true)}><TaskForm showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} structure={structure} project={project}/></Modal>}
        </div>
    )
}

const Tasks = ({ showSort, showTitle, structure, project }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {    
        dispatch(fetchTasks(10, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TasksContent showSort={showSort} showTitle={showTitle} structure={structure} project={project} />
    )
}

export default Tasks;