import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import styles from './app.module.scss';
import ModalContextProvider from '../../components/modal/ModalContext';
import Navigation from '../../components/navigation/Navigation';
import Profile from '../profile/profile';
import Home from '../home/Home';
import Projects from '../projects/Projects';
import ProjectItem from '../projects/project-item/ProjectItem';
import Reports from '../reports/Reports';
import ReportItem from '../reports/report-item/ReportItem';
import Structures from '../structures/Structures';
import Tasks from '../tasks/Tasks';
import Users from '../users/Users';
import Login from '../login/Login';
import StructureItem from '../structures/structure-item/StructureItem';
import { User } from '../users/interfaces';
import { fetchProfile } from '../../store/actions/actions';
import TaskItem from '../tasks/task-item/TaskItem';
import Logout from '../logout/Logout';

const App = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { accounts, instance, inProgress } = useMsal();

	type ProfileStore = { profile: User; loading: boolean; error: any };
	const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
	const profile = profileStore.profile;

	useEffect(() => {
		if (accounts[0]?.localAccountId) {
			const request = {
				scopes: process.env.REACT_APP_MSAL_SCOPES!.split(','), //["https://pfsrecce.onmicrosoft.com/api-dev/api.read","https://pfsrecce.onmicrosoft.com/api-dev/api.write"],
				account: accounts[0]
			};
			instance.acquireTokenSilent(request).then((resp) => {
				console.log('token resp', resp);
				dispatch(fetchProfile({ azureId: accounts[0].localAccountId }, resp));
			});
		} else {
			if (inProgress === 'none') {
				history.push('/login');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inProgress]);

	return (
		<div className='app-container'>
			<ModalContextProvider>
				{/* <div>header</div> */}
				<div className={styles['app-body']}>
					{location.pathname !== '/' && (
						<div className={styles.navigation}>
							<Navigation />
						</div>
					)}
					<div className={styles.page}>
						<Profile profile={profile} />
						<Switch>
							<Route exact path='/login'>
								<Login />
							</Route>
							<Route exact path='/home'>
								<Home />
							</Route>
							<Route exact path='/projects'>
								<Projects />
							</Route>
							<Route path={'/projects/:id'} component={ProjectItem} />
							<Route exact path='/reports'>
								<Reports showTitle={true} showSort={true} />
							</Route>
							<Route path={'/reports/:id'} component={ReportItem} />
							<Route exact path='/structures'>
								<Structures showSort={true} showTitle={true} />
							</Route>
							<Route path={'/structures/:id'} component={StructureItem} />
							<Route exact path='/tasks'>
								<Tasks showSort={true} showTitle={true} />
							</Route>
							<Route path={'/tasks/:id'} component={TaskItem} />
							<Route path='/users'>
								<Users />
							</Route>
							<Route exact path={'/logout'}>
								<Logout />
							</Route>
						</Switch>
					</div>
				</div>
			</ModalContextProvider>
		</div>
	);
};

export default App;
