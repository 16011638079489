
import {  CREATE_STRUCTURES, DELETE_STRUCTURES, FETCH_STRUCTURES, SAVE_STRUCTURES, UPLOAD_STRUCTURES, CLEAR_STRUCTURES_UPLOAD } from '../../store/actions/actionTypes';
import { StructureState } from './interfaces';

const initialState: StructureState = {
    structures:[],
    loading: false,
    error: null,
    pagesize: 0,
    pagenumber: 0,
    pagecount: 0, 
    resultstotal: 0, 
    upload: {
        loading: false, 
        complete: false, 
        successful: false
    }
}

const structuresReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${FETCH_STRUCTURES}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${FETCH_STRUCTURES}_FULFILLED`: {
            return {
                ...state, 
                loading: false, 
                structures: action.payload.data,
                pagesize: action.payload.pageSize,
                pagenumber: action.payload.pageNumber,
                pagecount: action.payload.pageCount, 
                resultstotal: action.payload.resultsTotal
            }
        }
        case `${UPLOAD_STRUCTURES}_PENDING`: {
            return {
                ...state, 
                upload: {
                    loading: true,
                    complete: false, 
                    successful: false
                }
            }
        }
        case `${UPLOAD_STRUCTURES}_FULFILLED`: {
            return {
                ...state, 
                upload: {
                    loading: false,
                    complete: true, 
                    successful: action.payload
                }
            }
        }
        case `${CLEAR_STRUCTURES_UPLOAD}`: {
            return {
                ...state, 
                upload: {
                    loading: false,
                    complete: false, 
                    successful: false
                }
            }
        }
        case `${SAVE_STRUCTURES}_PENDING`: { 
            return {
                ...state, 
                loading: true
            }
        }
        case `${SAVE_STRUCTURES}_FULFILLED`: {        
            state.structures.splice(state.structures.findIndex(u => u.id === action.payload.data.id), 1);
            state.structures.push(action.payload.data);
            return {
                ...state, 
                loading: false
            }
        }
        case `${DELETE_STRUCTURES}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${DELETE_STRUCTURES}_FULFILLED`: {
            state.structures.splice(state.structures.findIndex(s => s.id === action.payload.id), 1);
            return {
                ...state, 
                loading: false
            }
        }
        case `${CREATE_STRUCTURES}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${CREATE_STRUCTURES}_FULFILLED`: {
            state.structures.push(action.payload.data)
            return {
                ...state, 
                loading: false
            }
        }
        default: return state;
    }
}

export default structuresReducer;