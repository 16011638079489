import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import styles from './tables.module.scss';
import theads from '../../data/table-headings.json';
import { SortInfo, useItemsSort } from '../../hooks/hooks';
import { User } from '../../views/users/interfaces';
import { deleteUser } from '../../views/users/actions';
import UserForm from '../forms/UserForm';
import { DeleteModal, Modal } from '../modal/Modal';
import { useEventListener } from '../../hooks/hooks';
import DeletePopup from '../popup/DeletePopup';

type MenuProps = ({ user: User, setShowModal: any, setShowDeleteModal: any, setSelectedUser: any, setSelectedRow: any });
const ActionMenu = ({ user, setShowModal, setShowDeleteModal, setSelectedUser, setSelectedRow }: MenuProps) => {

    const [ showActions, setShowActions ] = useState<boolean>(false);

    const actionsRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (e: any) => {
        if (actionsRef && actionsRef.current && !actionsRef.current.contains(e.target)) {
            setShowActions(false); setSelectedRow(null);
        }
    }

    useEventListener('mousedown', handleOutsideClick);
    return (
        <div className={styles['actions-menu']} ref={actionsRef}>
            <div className={styles.more} onClick={() => setShowActions(!showActions)}></div>
            <div className={cx(styles['actions-container'], {[styles['show-actions']]: showActions})}>
            {   showActions && 
                <>                                                  
                    <div className={styles.action} onClick={() => { setShowModal(true); setSelectedUser(user); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faPencilAlt} /></div><div>edit user</div></div>
                    <div className={styles.action} onClick={() => { setShowDeleteModal(true); setSelectedUser(user); setShowActions(false); }}><div className={styles.icon}><FontAwesomeIcon icon={faTrashAlt} /></div><div>delete user</div></div>
                </>
            }
            </div>
        </div>
    )
}

type Props = ({ users: User[], loading: boolean, error: any });
const UsersTable = ({ users, loading, error }: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showViewMode, setViewMode] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<string>('');

    const [sortType, setSortType] = useState<string>('end-desc');
    const [selectedSort, setSelectedSort] = useState<any | null>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);

    const handleSort = (headingInfo: any) => {
        headingInfo.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ?
            setSortType(headingInfo.field + '-asc') :
            setSortType(headingInfo.field + '-desc');

        setSelectedSort(headingInfo);
    }

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }
    // setup sorting initial sort is set to 'date' type and using value of 'title'
    const sortInfo: SortInfo = { items: users, valueType: selectedSort?.valuetype || 'string', isAsc: sortType.includes('asc'), isItemsObject: true, objectFieldName: selectedSort?.field || 'title' };
    const sortedUsers = useItemsSort(sortInfo);
    return (
        <div className={styles['table-container']}>
            <table>
                <thead>
                    <tr>
                        {
                            theads.users.map(h => (
                                <th key={h.title} colSpan={h.title === '' ? 1 : 2} onClick={() => handleSort(h)}>
                                    <div className={styles['col-labels']}>
                                        <div>{h.title}&nbsp;</div>
                                        {h.sort ?
                                            h.field?.toLowerCase() + '-desc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortDown} /> :
                                                h.field?.toLowerCase() + '-asc' === sortType.toLowerCase() ? <FontAwesomeIcon icon={faSortUp} /> :
                                                    <FontAwesomeIcon icon={faSort} /> : null}
                                    </div>
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                {
                    loading ? 
                    <tbody>
                    <tr className={styles['row-spinner']}><td colSpan={13}>
                        <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                            <div className={styles.spinner}></div>
                        </div>
                    </td></tr>
                </tbody> :
                <tbody>
                    {
                        sortedUsers.map(p => (
                            <tr key={p.azureId} className={cx(styles['row-item'], {[styles['selected-item']]: selectedRow === p.id})}>
                                <td colSpan={2}>{p.firstName}</td>
                                <td colSpan={2}>{p.lastName}</td>
                                <td colSpan={2}><a href={'mailto:' + p.email}>{p.email}</a></td>
                                <td colSpan={2}><a href={'tel:' + p.phoneNumber}>{p.phoneNumber}</a></td>
                                <td colSpan={2}>{p.company}</td>
                                <td colSpan={2}>{p.title}</td>
                                <td>
                                    <ActionMenu user={p} setShowModal={setShowModal} setShowDeleteModal={setShowDeleteModal} setSelectedUser={setSelectedUser} setSelectedRow={setSelectedRow} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
                 }
            </table>
            { showModal && <Modal title={showViewMode?'User Information':'Edit User'} closeModal={() => setShowEditModal(true)}><UserForm user={selectedUser || undefined} isExisting={true} isViewMode={showViewMode} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} isProfile={profileStore.profile?.azureId === selectedUser?.azureId} /></Modal> }
            { showDeleteModal && <DeleteModal title='Delete User' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={selectedUser?.firstName+" "+selectedUser?.lastName || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(selectedUser){dispatch(deleteUser(selectedUser));}setShowDeleteModal(false);}}/></DeleteModal> }
        </div>
    )
}

export default UsersTable