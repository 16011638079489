import { useEffect, useRef, useState } from 'react';
import { Paper } from '@mui/material';
import { Chart, Doughnut } from 'react-chartjs-2';

import styles from './charts.module.scss';

const defaults = Chart.defaults;
defaults.plugins.legend.position = 'bottom';

// type Props = ({ dashboardData: Dashboard});
const TaskOverviewChart = ({ dashboardData, parentCallback }) => {
	const [dataChart, setDataChart] = useState([0, 0, 0]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		// plugins: {
		// 	title: {
		// 		display: true,
		// 		text: `Today's Task Overview`
		// 	}
		// },
		onClick: (evt, item) => {
			if (item) {
				switch (item[0].index) {
					case 0:
						parentCallback([], 'Completed');
						break;
					case 1:
						parentCallback([], 'Overdue');
						break;
					case 2:
						parentCallback([], 'Pending');
						break;
					default:
						break;
				}
			}
		}
	};

	const valRef = useRef(0);

	useEffect(() => {
		//If count willbecome 0, it will check 2nd condition, so have to check for undefined
		valRef.current =
			dashboardData.tasksOverdueHighPriorityCount !== undefined
				? dashboardData.tasksOverdueHighPriorityCount
				: dashboardData.workTasksOverdueHighPriorityCount;
		setDataChart([
			dashboardData.tasksCompletedCount
				? dashboardData.tasksCompletedCount
				: dashboardData.workTasksCompletedCount,
			dashboardData.tasksOverdueCount
				? dashboardData.tasksOverdueCount
				: dashboardData.workTasksOverdueCount,
			dashboardData.tasksPendingCount
				? dashboardData.tasksPendingCount
				: dashboardData.workTasksPendingCount
		]);
	}, [dashboardData]);

	const data = {
		// labels: ['Completed', 'Overdue', 'Pending'],
		datasets: [
			{
				data: dataChart,
				backgroundColor: ['#09b409', 'rgb(204, 0, 0)', 'rgb(128, 128, 128)'],
				borderWidth: 1
			}
		]
	};
	const plugins = [
		{
			afterDraw: function (chart) {
				const width = chart.width,
					height = chart.height,
					ctx = chart.ctx;
				ctx.restore();
				const fontSize = (height / 160).toFixed(2);
				ctx.font = fontSize + 'em sans-serif';
				ctx.fillStyle = '#D50032';
				ctx.textBaseline = 'top';
				var text = valRef.current,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2 - 12;
				ctx.fillText(text, textX, textY);
				ctx.save();
			}
		}
	];

	return (
		<Paper
			sx={{
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				height: '320px'
			}}
		>
			<div className={styles['chart-container']}>
				<div className={styles.title}>
					<div>Today's Task Overview</div>
				</div>
				<div className={styles['chart-info']}>
					<div>
						<Doughnut data={data} height='220' options={options} plugins={plugins} />
					</div>
				</div>
				<div className={styles.footer}>
					<div className={styles.overdue}>
						<span>
							{dashboardData.tasksOverdueCount || dashboardData.workTasksOverdueCount || 0}
						</span>{' '}
						overdue
					</div>
					<div className={styles.completed}>
						<span>
							{dashboardData.tasksCompletedCount || dashboardData.workTasksCompletedCount || 0}
						</span>{' '}
						completed
					</div>
					<div className={styles.pending}>
						<span>
							{dashboardData.tasksPendingCount || dashboardData.workTasksPendingCount || 0}
						</span>{' '}
						pending
					</div>
				</div>
			</div>
		</Paper>
	);
};

export default TaskOverviewChart;
