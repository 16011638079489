
import styles from './cards.module.scss';

export const DummyCard = () => {
    return (
        <div className={`${styles.card} ${styles['dummy-card']}`}>
            <div className={styles['card-header']}>
                <div className={styles.title}>

                </div>
            </div>
            <div className={styles['card-body']}>
            </div>
            <div className={styles['card-actions']}>
                <button type='button' className={styles.btn}>View</button>
                <button type='button' className={styles.btn}>Edit</button>
                <button type='button' className={styles.btn}>Delete</button>
            </div>
        </div>
    )
}
type Props = ({ title: string, subtitle: string, status?: string, body: React.ReactNode, actions: React.ReactNode, headerinfo?: React.ReactNode, projectName?: string, structureName?: string})
const Card = ({ title, subtitle, status, body, actions, headerinfo, projectName, structureName }: Props ) => {
    return (
        <div className={styles.card}>
            <div className={styles['card-header']}>
                <div className={styles.title}>
                    {/* Added the condition to maintain custom order in task view */}
                    {projectName && structureName ?
                        <div>
                            <div className={styles['title-text']}>{title}</div>
                            <div className={styles['subtitle-text']}>{status}</div>
                            <div className={styles['subtitle-text']}>{projectName}</div>
                            <div className={styles['subtitle-text']}>{structureName}</div>
                            <div className={styles['subtitle-text']}>{subtitle}</div>
                        </div>
                        :
                        <div>
                            <div className={styles['title-text']}>{title}</div>
                            <div className={styles['subtitle-text']}>{subtitle}</div>
                            <div className={styles['subtitle-text']}>{status}</div>
                        </div>
                    }
                </div>
                {   headerinfo &&
                    <div className={styles['header-info']}>
                        { headerinfo }
                    </div>
                }
            </div>
            <div className={styles['card-body']}>
                { body }
            </div>
            <div className={styles['card-actions']}>
                { actions }
            </div>
        </div>
    )
}

export default Card;