import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cx from 'classnames';

import styles from './cards.module.scss';
import { Project } from '../../views/projects/interfaces';
import { DeleteModal, Modal } from '../modal/Modal';
import ProjectForm from '../forms/ProjectForm';
import { deleteProject } from '../../views/projects/actions';
import DeletePopup from '../popup/DeletePopup';

type Props = ({ project: Project });
export const ProjectCardInfo = ({ project }: Props) => {
    return (
        <>
            <div className={styles['timeline']}>
                <div className={styles['events']}>
                    <ol>
                        <ul>
                            <li><a href='#0'>{project?.scheduledStartDate ? new Date(project.scheduledStartDate).toLocaleDateString() : ''}</a></li>
                            <li><a href='#1' className={styles.selected}>{new Date().toLocaleDateString()}</a></li>
                            <li><a href='#2'>{project?.scheduledEndDate ? new Date(project.scheduledEndDate).toLocaleDateString() : 'unknown'}</a></li>
                        </ul>
                    </ol>
                </div>
            </div>
            <div className={cx(styles['timeline-label'], {[styles.success]: project.status?.toLowerCase() === 'completed'}, {[styles.warning]: project.status?.toLowerCase() === 'pending'})}>{project.status}</div>
        </>
    )
}
export const ProjectCardHeaderInfo = ({ project }: Props) => {
    return (
        <div className={`${styles.ratios} ${styles.single}`}>
            <div className={styles.ratio}>
                <div className={styles.title}>tasks</div>
                <div className={styles.bubble}>{`${project.workTasksCompletedCount}/${project.workTasksTotalCount}`}</div>
            </div>
        </div>
    )
}
export const ProjectCardActionInfo = ({project}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type PermissionsStore = { canEditProjects: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    return (
        <>
            <button type='button' className={styles.btn} onClick={() => history.push(`/projects/${encodeURIComponent(project.id || '')}`)}>View</button>
            {   permissionsStore.canEditProjects && 
                <>
                    <button type='button' className={styles.btn} onClick={() => { setShowModal(true); }}>Edit</button>
                    <button type='button' className={styles.btn} onClick={() => { setShowDeleteModal(true); }}>Delete</button>
                    { showModal && permissionsStore.canEditProjects && <Modal title='Edit Project' closeModal={() => setShowEditModal(true)}><ProjectForm project={project} isExisting={true} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal> }
                    { showDeleteModal && <DeleteModal title='Delete Project' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={project?.name || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(project){dispatch(deleteProject(project))}setShowDeleteModal(false);}}/></DeleteModal> }
                </>
            }
        </>
    )
}