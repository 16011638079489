import { SET_ADMIN_PERMISSIONS, SET_FIELD_USER_PERMISSIONS, SET_CLIENT_PERMISSIONS } from '../../actions/actionTypes';

export interface PermissionsState {
    canEditProjects: boolean, 
    canEditSturctures: boolean, 
    canEditTasks: boolean,
    canEditDaily: boolean, 
    canEditUsers: boolean
}

const initialState: PermissionsState = {
    canEditProjects: false, 
    canEditSturctures: false, 
    canEditTasks: false, 
    canEditDaily: false, 
    canEditUsers: false
}

const permissionsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${SET_ADMIN_PERMISSIONS}`: {
            return {
                ...state, 
                canEditProjects: true, 
                canEditSturctures: true, 
                canEditTasks: true, 
                canEditDaily: true, 
                canEditUsers: true
            }
        }
        case `${SET_FIELD_USER_PERMISSIONS}`: {
            return {
                ...state, 
                canEditProjects: false, 
                canEditSturctures: false, 
                canEditTasks: true, 
                canEditDaily: true, 
                canEditUsers: false
            }
        }
        case `${SET_CLIENT_PERMISSIONS}`: {
            return {
                ...state, 
                canEditProjects: false, 
                canEditSturctures: false, 
                canEditTasks: false, 
                canEditDaily: false, 
                canEditUsers: false
            }
        }
        default: return state
    }
}

export default permissionsReducer;