import { FETCH_PROFILE, UPDATE_SELECTED_PROJECT_LOCAL } from '../../actions/actionTypes';
import { User } from '../../../views/users/interfaces';

export interface ProfileState {
    profile?: User,
    loading: boolean, 
    error: any
}

const initialState: ProfileState = {
    profile: undefined, 
    loading: false, 
    error: null
}

const profileReducer = (state = initialState, action: any) => {
    switch (action.type) {
		case `${FETCH_PROFILE}_PENDING`: {
			return {
				...state,
				loading: true
			};
		}
		case `${FETCH_PROFILE}_FULFILLED`: {
			return {
				...state,
				loading: false,
				profile: action?.payload?.data
			};
		}
		case `${UPDATE_SELECTED_PROJECT_LOCAL}`: {
			return {
				...state,
				profile: action.payload
			};
		}
		default:
			return state;
	}
}

export default profileReducer;