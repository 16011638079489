import { CREATE_DAILY_REPORT, FETCH_DAILY_REPORTS, FETCH_DAILY_REPORT_DATA, FETCH_DAILY_REPORT_DETAIL, SAVE_DAILY_REPORT } from '../../store/actions/actionTypes';
import { DailyReportState } from './interfaces';

const initialState: DailyReportState = {
    reportsinfo: {
        dailyreports:[],
        loading: false,
        error: null,
        pagesize: 0,
        pagenumber: 0,
        pagecount: 0,
        resultstotal: 0
    }, 
    selectreportinfo: {
        dailyreport: {
            projectId: '', 
            reportDate: undefined, 
            status: undefined, 
            userId: undefined, 
            authorName: '', 
            notes: '', 
            completedWorkTasks: [], 
            overdueWorkTasks: [], 
            taskComments: []
        }, 
        loading: false, 
        error: null
    }
}

const dailyReportsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${FETCH_DAILY_REPORTS}_PENDING`: {
            return {
                ...state, 
                reportsinfo: {
                    dailyreports: state.reportsinfo.dailyreports,
                    error: state.reportsinfo.error, 
                    pagesize: state.reportsinfo.pagesize, 
                    pagenumber: state.reportsinfo.pagenumber, 
                    pagecount: state.reportsinfo.pagecount, 
                    loading: true
                }
            }
        }
        case `${FETCH_DAILY_REPORTS}_FULFILLED`: {
            return {
                ...state, 
                reportsinfo: {
                    dailyreports: action.payload.data,
                    pagesize: action.payload.pageSize,
                    pagenumber: action.payload.pageNumber,
                    pagecount: action.payload.pageCount, 
                    resultstotal: action.payload.resultsTotal, 
                    loading: false
                }
            }
        }
        case `${FETCH_DAILY_REPORT_DETAIL}_PENDING`: {
            return {
                ...state, 
                selectreportinfo: {
                    dailyreport: state.selectreportinfo.dailyreport, 
                    error: state.selectreportinfo.error, 
                    loading: true
                }
            }
        }
        case `${FETCH_DAILY_REPORT_DETAIL}_FULFILLED`: {
            return {
                ...state, 
                selectreportinfo: {
                    dailyreport: action.payload.data, 
                    loading: false
                }
            }
        }
        case `${FETCH_DAILY_REPORT_DATA}_PENDING`: {
            return {
                ...state, 
                selectreportinfo: {
                    dailyreport: state.selectreportinfo.dailyreport, 
                    error: state.selectreportinfo.error, 
                    loading: true
                }
            }
        }
        case `${FETCH_DAILY_REPORT_DATA}_FULFILLED`: {
            return {
                ...state, 
                selectreportinfo: {
                    dailyreport: action.payload.data, 
                    loading: false
                }
            }
        }
        case `${CREATE_DAILY_REPORT}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${CREATE_DAILY_REPORT}_FULFILLED`: {
            state.reportsinfo.dailyreports.push(action.payload.data)
            return {
                ...state, 
                loading: false
            }
        }
        case `${SAVE_DAILY_REPORT}_PENDING`: { 
            return {
                ...state, 
                loading: true
            }
        }
        case `${SAVE_DAILY_REPORT}_FULFILLED`: {        
            state.reportsinfo.dailyreports.splice(state.reportsinfo.dailyreports.findIndex(u => u.id === action.payload.data.id), 1);
            state.reportsinfo.dailyreports.push(action.payload.data);
            return {
                ...state, 
                loading: false
            }
        }
        default: return state
    }
}

export default dailyReportsReducer;