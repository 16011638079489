import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { CREATE_DAILY_REPORT, FETCH_DAILY_REPORTS, FETCH_DAILY_REPORT_DATA, FETCH_DAILY_REPORT_DETAIL, SAVE_DAILY_REPORT } from '../../store/actions/actionTypes';
import { RECCE_DAILY_REPORTS, RECCE_DAILY_REPORT_DETAIL, RECCE_DAILY_REPORT_INITIALIZE } from '../../store/api/endPoints';
import { DailyReportSummary } from './interfaces';
import { svcconfig } from '../../store/actions/actions';

// const svcconfig = { headers: { 'Pragma': 'no-cache', 'Content-Type': 'application/json' } };

export const fetchDailyReports = (pageSize: number, pageNumber: number, projectId?: string, dateFilter? :boolean) => {
    const size = `PageSize=${pageSize}`; const number = `PageNumber=${pageNumber}`; 
    const start = `DailyReportDateStart=${dayjs(new Date().setHours(0, 0, 0, 0)).format('MM/DD/YYYY HH:mm:ss')}`; const end = `DailyReportDateEnd=${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}`;
    // const endpoint =`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORTS}?${start}&${end}ProjectIds=${encodeURIComponent(projectId)}&${size}&${number}`;
    const dateRange = dateFilter ? start + "&" + end + "&"  : ""; 
    const endpoint = projectId ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORTS}?${dateRange}ProjectIds=${encodeURIComponent(projectId)}&${size}&${number}` : 
                                //  `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORTS}?${start}&${end}&${size}&${number}`;
                                 `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORTS}?${size}&${number}`;
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_DAILY_REPORTS, 
            payload: axios.get(endpoint, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

type DetailRequest = ({ dailyReportId: string, userId: string });
export const fetchDailyReportDetails = (dailyReportId: string, userId: string) => {
    const request: DetailRequest = { dailyReportId: dailyReportId, userId: userId };

    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_DAILY_REPORT_DETAIL, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORT_DETAIL}`, request, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return null;
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

type ReportDetailRequest = ({ projectId: string, reportDate: Date, userId: string });
export const fetchDailyReportData = (projectId: string, reportDate: Date, userId: string) => {
    const request: ReportDetailRequest = { projectId: projectId, reportDate: reportDate, userId: userId };

    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_DAILY_REPORT_DATA, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORT_INITIALIZE}`, request, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return null;
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const createDailyReport = (dailyReport: DailyReportSummary) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CREATE_DAILY_REPORT, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORTS}`, dailyReport, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const saveDailyReport = (dailyReport: DailyReportSummary) => {
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            
            type: SAVE_DAILY_REPORT, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_DAILY_REPORTS}`, dailyReport, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}