import { useState, useRef, useEffect } from 'react';
import styles from './../forms/forms.module.scss';
import './filter.css';
import Select from '../controls/select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { Project, ProjectState } from '../../views/projects/interfaces';
import { Task } from '../../views/tasks/interfaces';
import { SelectType } from '../controls/select/interfaces';
import { Structure, StructureState } from '../../views/structures/interfaces';
import { User, UserState } from '../../views/users/interfaces';
import { fetchStructure } from '../../views/structures/actions';
import { fetchUsers } from '../../views/users/actions';
import { fetchProjects } from '../../views/projects/actions';
import { fetchTasks } from '../../views/tasks/actions';

type Props = ({setStructureId: Function, setProjectId: Function, setCustomPageSize: Function, setFilterStatus: Function, setUserId: Function})
export const Filter = ({setStructureId, setProjectId, setCustomPageSize, setFilterStatus, setUserId} : Props) => {    

    const dispatch =  useDispatch();

    useEffect(() => {
        dispatch(fetchProjects(projectsStore.projectsinfo.resultstotal || 100, 1));
        dispatch(fetchStructure(structuresStore.resultstotal || 100, 1));
        dispatch(fetchUsers(usersStore.resultstotal || 100, 1));
    }, []);

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);
    const projects = projectsStore.projectsinfo.projects;

    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);
    const structures = structuresStore.structures;

    const usersStore = useSelector((state: { usersStore: UserState }) => state.usersStore);
    const users = usersStore.users.map(users => ({ fullName: users.firstName + ' ' + users.lastName, ...users }));

    const [showFilterPanel, setShowFilterPanel] = useState<string>('none');
    const [currentTask, setCurrentTask] = useState<Task | null | undefined>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [structureList, setStructureList] = useState<any>();
    const [applied, setApplied] = useState<boolean>(false);
    const [clearAll, setClearAll] = useState<boolean>(false);

    const setProject = (project: Project) => {
        if(project.id && project.name)
        {
            setStructureList(structures.filter((struct: Structure) => {
                return struct.projectId === project.id;
            }))
        }
        else {
            setStructureList(structures);
        }
        setCurrentTask({ ...currentTask, projectId: project.id, projectName: project.name});
    }
    const setStructure = (structure: Structure) => {
        setCurrentTask({ ...currentTask, structureId: structure.id, structureName: structure.name });
    }

    const setAssignTo = (user: User) => setCurrentTask({ ...currentTask, assignedToUserId: user.id, assignedToName: user.firstName + ' ' + user.lastName });

    const setStatus = (status: any) => {
        setCurrentTask({...currentTask, status: status.name});
    }

    const showFilter = () => {
        setShowFilterPanel('block');
    }
    const applyFilter = () => {
        if(currentTask)
        {
            dispatch(fetchTasks(pageSize || 10, 1, undefined, currentTask?.structureId, currentTask?.projectId, currentTask?.status, currentTask?.assignedToUserId));
            setProjectId(currentTask?.projectId);
            setStructureId(currentTask?.structureId);
            setCustomPageSize(pageSize);
            setFilterStatus(currentTask?.status);
            setUserId(currentTask?.assignedToUserId)
            setPageSize(pageSize);
            setShowFilterPanel('none');
            setApplied(true);
        }
    }
    const clearFilter = () => {
        setShowFilterPanel('none');
        dispatch(fetchTasks(10, 1));
        setApplied(false);
        setCurrentTask(undefined);
        setClearAll(true);
    }
    const projectRef = useRef<HTMLDivElement>(null);
    const structureRef = useRef<HTMLDivElement>(null);
    const userRef = useRef<HTMLDivElement>(null);
    const statusRef = useRef<HTMLDivElement>(null);
    const taskStatuses = [{id: 0, name: 'In Progress'}, {id: 1, name: 'Paused'}, {id: 2, name: 'Completed'}, {id: 3, name: 'Behind Schedule'}, {id: 4, name: 'Pending'}, {id: 5, name: 'Completed Late'}];
    return(
        <>
            { (!clearAll || showFilterPanel === 'block') &&
                <div className='filter-bar' style={{display: showFilterPanel}}>
                    <div className='sidemenu-head' >
                        Filter
                        <a className='closeIcon' onClick={() => {setShowFilterPanel('none');}}>&times;</a>
                    </div>
                    <div className={styles['form-container']}>
                        <div className={styles['inputs-section']}>
                            <div className={`${styles.inputs}`}>
                                <div className={styles['input-item']}>
                                    <Select placeholder={currentTask?.projectName ?? 'Project'} items={projects} selectedItem={currentTask?.projectName} setSelectedItem={setProject} selectref={projectRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name'  selectType={SelectType.TypeAhead} />
                                </div>
                            </div>
                        </div>  
                        <div className={styles['inputs-section']}>
                            <div className={styles['inputs']}>
                                <div className={styles['input-item']}>
                                    <Select placeholder={currentTask?.structureName ?? 'Structure'} items={structureList || structures} selectedItem={undefined} setSelectedItem={setStructure} selectref={structureRef} isItemsObject={true} objectKeyField="id" objectDisplayField='name' selectType={SelectType.TypeAhead}/>
                                </div>
                            </div>
                        </div>
                        <div className={styles['inputs-section']}>
                            <div className={`${styles.inputs}`}>
                                <div className={styles['input-item']}>
                                    <Select placeholder={currentTask?.status ?? 'Status'} items={taskStatuses} selectedItem={undefined} setSelectedItem={setStatus} selectref={statusRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name'  selectType={SelectType.TypeAhead} />
                                </div>
                            </div>
                        </div>                    
                        <div className={styles['inputs-section']}>
                            <div className={`${styles.inputs}`}>
                                <div className={styles['input-item']}>
                                    <Select placeholder={currentTask?.assignedToName ?? 'Assigned to'} items={users} selectedItem={currentTask?.assignedToName} setSelectedItem={setAssignTo} selectref={userRef} isItemsObject={true} objectKeyField='id' objectDisplayField='fullName' selectType={SelectType.TypeAhead} />
                                </div>
                            </div>
                        </div>
                        <div className={styles['inputs-section']}>
                            <div className={`${styles.inputs}`}>
                                <div className={styles['input-item']}>
                                    <input type='number' placeholder='Results per page' min={10} value = {pageSize} onChange = {e => setPageSize(parseInt(e.target.value))}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='filter-btn-grp'>
                        <button onClick={applyFilter}>Apply</button>
                        <button className='clear-btn' onClick={clearFilter} disabled={!applied}>Clear</button>
                    </div>
                </div>
            }
            <button className='filter-btn' onClick={showFilter}>Filter</button>
        </>
    )
}

export default Filter;