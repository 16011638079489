import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import Stack from '@mui/material/Stack';
import 'react-datepicker/dist/react-datepicker.css';

// import './style.css';
import styles from './forms.module.scss';
import Select from '../controls/select/Select';
import Switch from '../controls/switch/Switch';
import { Project, ProjectState } from '../../views/projects/interfaces';
import { Structure, StructureState } from '../../views/structures/interfaces';
import { Task, TaskSvcState } from '../../views/tasks/interfaces';
import { User } from '../../views/users/interfaces';
import { createTask, saveTask, fetchDefaultTasks } from '../../views/tasks/actions';
import { SortInfo, useItemsSort } from '../../hooks/hooks';
import { fetchProjects } from '../../views/projects/actions';
import { fetchStructure } from '../../views/structures/actions';
import { fetchUsers } from '../../views/users/actions';
import { CurrentUserDetails } from '../helpers/CurrentUserDetails';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';
import selector from '../controls/select/select.module.scss';
import { SelectType } from '../controls/select/interfaces';


type Props = ({ task?: Task, isExisting?: boolean, closeModal: any, project?: Project, structure?: Structure, showEModal?: boolean, discard ?: any  });
const TaskForm = ({ task, isExisting, closeModal, project, structure, showEModal, discard }: Props) => {
    const dispatch = useDispatch();

    const [currentTask, setCurrentTask] = useState<Task | null | undefined>(task);
    // used for cloning from default task
    const [selectedDefault, setSelectedDefault] = useState<Task | undefined>(undefined);

    const [showNameRequired, setShowNameRequired] = useState<boolean>(false);
    const [showProjectRequired, setShowProjectRequired] = useState<boolean>(false);
    const [showStructureRequired, setShowStructureRequired] = useState<boolean>(false);
    const [showPriorityRequired, setShowPriorityRequired] = useState<boolean>(false);
    const [showDurationRequired, setShowDurationRequired] = useState<boolean>(false);
    const [showOrderNumberRequired, setShowOrderNumberRequired] = useState<boolean>(false);
    // states For Confirmation modal before save/create
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    // const [durationDays, setDurationDays] = useState<number | undefined>(undefined);
    // const [durationHours, setDurationHours] = useState<number | undefined>(undefined);
    // const [durationMins, setDurationMins] = useState<number | undefined>(undefined);

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);
    const projects = projectsStore.projectsinfo.projects;

    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);
    const structures = structuresStore.structures;

    const defaultTaskStore = useSelector((state: { defaultTaskStore: TaskSvcState }) => state.defaultTaskStore);

    useEffect(() => {
        dispatch(fetchUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!isExisting) {
            dispatch(fetchProjects());
            dispatch(fetchDefaultTasks());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExisting]);
    useEffect(() => {
        // used to set project details if create task triggres inside project detail page
        if (project) {
            setCurrentTask({ ...currentTask, projectId: project.id, projectName: project.name });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);
    useEffect(() => {
        // used to set structure details if create task triggres inside structure detail page 
        if (structure) {
            setCurrentTask({ ...currentTask, structureId: structure.id, structureName: structure.name, projectId: structure.projectId, projectName: structure.projectName });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [structure]);
    useEffect(() => {
        if (currentTask?.projectId) {
            dispatch(fetchStructure(25, 1, currentTask?.projectId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTask?.projectId])
    useEffect(() => {
        if (!isExisting && selectedDefault) {
            setCurrentTask({
                ...currentTask,
                name: selectedDefault.name,
				projectId: project?.id ?? structure?.projectId, projectName: project?.name ?? structure?.projectName, 
                structureId: structure?.id, structureName: structure?.name, 
                assignedToUserId: selectedDefault.assignedToName, assignedToName: selectedDefault.assignedToName, 
                priority: selectedDefault.priority, description: selectedDefault.description, 
                estimatedDuration: selectedDefault.estimatedDuration,
                orderNumber: selectedDefault.orderNumber,
                // scheduledStartTime: selectedDefault.startTime, scheduledEndTime: selectedDefault.endTime
            });

            // if (selectedDefault.estimatedDuration && selectedDefault.estimatedDuration > 0) {
            //     setDurationDays(Math.floor(selectedDefault.estimatedDuration / (3600*4)));
            //     setDurationHours(Math.floor(selectedDefault.estimatedDuration % (3600*4) / 3600));
            //     setDurationMins(Math.floor(selectedDefault.estimatedDuration % 3600 / 60));
            // }
            // else { setDurationDays(0); setDurationHours(0); setDurationMins(0); }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExisting, selectedDefault])

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }

    type UsersStore = { users: User[], loading: boolean, error: any };
    const usersStore = useSelector((state: { usersStore: UsersStore }) => state.usersStore);
    const users = usersStore.users.map(users => ({ fullName: users.firstName + ' ' + users.lastName, ...users }));
    const sortInfo: SortInfo = { items: users, valueType: 'string', isAsc: false, isItemsObject: true, objectFieldName: 'lastName' };
    const sortedUsers = useItemsSort(sortInfo);

    const setProject = (project: Project) => {
        setCurrentTask({ ...currentTask, projectId: project.id, projectName: project.name, structureId: '', structureName: '' });
        if (project) setShowProjectRequired(false);
    }
    const setStructure = (structure: Structure) => {
        setCurrentTask({ ...currentTask, structureId: structure.id, structureName: structure.name });
        if (structure) setShowStructureRequired(false);
    }
    const setAssignTo = (user: User) => setCurrentTask({ ...currentTask, assignedToUserId: user.id, assignedToName: user.firstName + ' ' + user.lastName });
    const formatDuration = (duration: number | undefined) => {
        if (duration) {
            const hours = duration ? Math.floor((duration / 3600)) : 0;
            const minutues = duration ? Math.floor((duration / 60) % 60) : 0;
            const seconds = duration ? Math.floor((duration / 1) % 60) : 0;

            const durationDate = new Date();
            durationDate.setHours(hours);
            durationDate.setMinutes(minutues);
            durationDate.setSeconds(seconds);
            return durationDate;
        }
        return '';
    }
    const setEstimated = (estimatedDuration: Date) => {
        let hours = estimatedDuration.getHours();
        let minutues = estimatedDuration.getMinutes();
        let seconds = estimatedDuration.getSeconds();

        if (!isNaN(hours)) {
            const duration = ((hours * 3600) + (minutues * 60) + (seconds));

            setCurrentTask({...currentTask, estimatedDuration: duration});
        }
    }
    const setPriority = (priority: string) => {
        setCurrentTask({ ...currentTask, priority: priority })
        if (priority) setShowPriorityRequired(false);
    };
    const setDefault = (isDefault: boolean) => setCurrentTask({ ...currentTask, isDefault: isDefault });

    const currentUser = CurrentUserDetails(); 

    // used for save structure
    const handleSaveTask = () => {
        handleChange();
        if (!currentTask?.name || currentTask.name === '') setShowNameRequired(true);
        if (!currentTask?.estimatedDuration) setShowDurationRequired(true);
        if (!currentTask?.projectId) setShowProjectRequired(true);
        if (!currentTask?.structureId) setShowStructureRequired(true);
        if (!currentTask?.priority) setShowPriorityRequired(true);
        if(!currentTask?.orderNumber) setShowOrderNumberRequired(true);

        if (currentTask?.name && currentTask.name !== '' && currentTask?.projectId && currentTask?.structureId && currentTask?.priority) {
            // if (durationDays || durationHours || durationMins) {
            //     let seconds = 0;
            //     if (durationDays && durationDays > 0) seconds = durationDays * 24 * 60 * 60;
            //     if (durationHours && durationHours > 0) seconds += durationHours * 60 * 60;
            //     if (durationMins && durationMins > 0) seconds += durationMins * 60;

            //     currentTask.estimatedDuration = seconds;
            // }
            if(isExisting) {
                currentTask.updatedBy = currentUser.userName;
                currentTask.updatedById = currentUser.id;
            }
            else {
                currentTask.createdBy = currentUser.userName;
                currentTask.createdById = currentUser.id;
            }
            dispatch(isExisting ? saveTask(currentTask) : createTask({ ...currentTask }));
            discard();
            closeModal();
            // //Timeout to wait for task updated and set the state of task
            // setTimeout(() => {
            //     dispatch(fetchStructure(25,1, currentTask.projectId));
            // }, 500);
        }
    }

    const form = document.getElementById('taskForm');

    if(form && !unsavedChanges) {
        form.addEventListener('input', function () {
            setUnsavedChanges(true);
        });
    }

    const selectorItems = document.getElementsByClassName(selector.item);

    if(selectorItems.length > 0 && !unsavedChanges)
    {
        setUnsavedChanges(true);
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    const projectRef = useRef<HTMLDivElement>(null);
    const structureRef = useRef<HTMLDivElement>(null);
    const userRef = useRef<HTMLDivElement>(null);
    const priorityRef = useRef<HTMLDivElement>(null);
    const defaultTaskRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className={styles['form-container']}>
                {   !isExisting && 
                    <div className={styles['clone-inputs-section']}>
                        <div className={`${styles.inputs} ${styles['clone-input']}`}>
                            <div className={styles['input-item']}>
                                <Select 
                                    placeholder='Copy from Task' 
                                    items={defaultTaskStore.tasks} 
                                    selectedItem={selectedDefault?.name} 
                                    setSelectedItem={setSelectedDefault} 
                                    selectref={defaultTaskRef} 
                                    isItemsObject={true} 
                                    objectKeyField='id' 
                                    objectDisplayField='name' 
                                    disabled={defaultTaskStore.loading}
                                    selectType={SelectType.TypeAhead} />
                            </div>
                        </div>
                    </div>
                }
                <form id='taskForm'>
                    <div className={styles['inputs-section']}>
                        <div className={styles.inputs} onClick={() => setUnsavedChanges(true)}>
                            <Switch isSwitchSet={currentTask?.isDefault || false} setSwitch={setDefault} onText='YES' offText='NO' switchText='Set Task as default' />
                            <span className={styles['tooltip-info']} data-tooltip='Default tasks will be automatically generated on new projects when copying from an existing project.' data-flow='down'><FontAwesomeIcon icon={faInfoCircle} /></span>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                {showNameRequired ? <label className={styles.required}>* name is required</label> : <label>{currentTask?.name ? 'name' : ''}</label>}
                                <input type='text' placeholder='name' value={currentTask?.name} onChange={(e) => { setCurrentTask({ ...currentTask, name: e.target.value }); if (e.target.value.trim() === '') { setShowNameRequired(true); } else { setShowNameRequired(false); } }} />
                            </div>
                            <div className={styles['input-item']}>
                                {showOrderNumberRequired ? <label className={styles.required}>*Order Number is required</label> : <label>{currentTask?.orderNumber ? 'order number' : ''}</label>}
                                <input type='number' placeholder='Order Number' value={currentTask?.orderNumber} onChange={(e) => { setCurrentTask({ ...currentTask, orderNumber: parseInt(e.target.value) }); if (e.target.value.trim() === '') { setShowNameRequired(true); } else { setShowNameRequired(false); } }} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                {showProjectRequired ? <label className={styles.required}>* project is required</label> : <label>{currentTask?.projectId ? 'project' : ''}</label>}
                                <Select placeholder= {currentTask?.projectName ?? 'Project'} items={projects} selectedItem={currentTask?.projectName} setSelectedItem={setProject} selectref={projectRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name' disabled={isExisting || project !== undefined || structure?.projectName !== undefined} selectType={SelectType.TypeAhead} />
                            </div>
                            <div className={styles['input-item']}>
                                {showStructureRequired ? <label className={styles.required}>* structure is required</label> : <label>{currentTask?.structureId ? 'structure' : ''}</label>}
                                <Select placeholder= {currentTask?.structureName ?? 'Structure'} items={structures} selectedItem={currentTask?.structureName} setSelectedItem={setStructure} selectref={structureRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name' disabled={isExisting || structuresStore.loading || structure !== undefined} selectType={SelectType.TypeAhead} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                <label>{currentTask?.assignedToName ? 'assigned to' : ''}</label>
                                <Select placeholder='Assigned to' items={sortedUsers} selectedItem={currentTask?.assignedToName} setSelectedItem={setAssignTo} selectref={userRef} isItemsObject={true} objectKeyField='id' objectDisplayField='fullName' selectType={SelectType.TypeAhead} />
                            </div>
                            <div className={styles['input-item']}>
                                {showPriorityRequired ? <label className={styles.required}>* priority is required</label> : <label>{currentTask?.priority ? 'priority' : ''}</label>}
                                <Select placeholder='Priority' items={['Low', 'Medium', 'High']} selectedItem={currentTask?.priority} setSelectedItem={setPriority} selectref={priorityRef} isItemsObject={false} />
                            </div>
                            <div className={styles['input-control-item']}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <TimePicker
                                            ampm={false}
                                            openTo='hours'
                                            views={['hours', 'minutes', 'seconds']}
                                            inputFormat='HH:mm:ss'
                                            mask='__:__:__'
                                            label={showDurationRequired ? 'ESTIMATED DURATION IS REQUIRED' :'ESTIMATED DURATION'}
                                            value={formatDuration(currentTask?.estimatedDuration)}
                                            onChange={number => setEstimated(number ? new Date(number) : new Date())}
                                            renderInput={(params) => 
                                                <TextField {...params} required={showDurationRequired} focused={showDurationRequired || this} error={showDurationRequired} type='search' placeholder='HH:MM:SS' variant='standard' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                <label>{currentTask?.description ? 'description' : ''}</label>
                                <textarea rows={3} cols={10} id='description' name='description' placeholder='Description' value={currentTask?.description} onChange={(e) => setCurrentTask({ ...currentTask, description: e.target.value })} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className={styles.actions}>
                <button onClick={() => {if(unsavedChanges) {setShowEditModal(true); } else {discard(); }}}>Cancel</button>
                <button onClick={() =>handleSaveTask()}>{isExisting ? 'Save' : 'Create'} Task</button>
            </div>
            {(showEModal || showEditModal) && <EditModal title='UNSAVED CHANGES' closeModal={() =>{handleChange()}}><EditPopup  closeModal={() =>{handleChange()}} editEntity={() =>handleSaveTask()} discardChanges = {() => discard()}/></EditModal> }
        </>
    )
}

export default TaskForm;


