import cx from 'classnames';
import { Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './lists.module.scss';
import { Structure } from "../../views/structures/interfaces";
import { Project } from '../../views/projects/interfaces';
import { useState } from 'react';

type Props = ({ structures: Structure[], selected?: Structure, resetClick?(): void, behind?: number, active?: number, upcoming?: number, selectedProject?: Project, reset?: boolean, applyFilter?: any });
const StructuresList = ({ structures, selected, resetClick, behind, active, upcoming, selectedProject, reset, applyFilter }: Props) => {
const [hideCurrTask, setHideCurrTask] = useState<any>(false);
   
    const filtered = reset ? structures : (selectedProject?.id ? structures.filter(s => s.projectId === selectedProject.id) : structures); 
    console.log("filtered",hideCurrTask)
    // if(selected?.name == 'P2 Structure'){
    //   setHideCurrTask(true);
    // }
    // else{
    //     setHideCurrTask(false);
    // }
    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '320px'
            }}
        >
            <div className={styles['list-container']}>
                <div className={styles.title}>
                    <div>Today's Structures</div>
                    {selected?.name && <div className={styles['selected-title']}>{selected.name}&nbsp;<FontAwesomeIcon className={styles.icon} icon={faTimesCircle} onClick={resetClick} /></div>}
                </div>
                <ul>
                    {   filtered.map((s: Structure) => {
                      return (
                                <li key={s.id} onClick={() => {applyFilter(s.projectId, s.id)}}>
                                    <div className={cx(styles.indicator, 
                                                        {[styles['in-progress']]: s.status && s.status.toLocaleLowerCase() === 'in progress'}, 
                                                        {[styles['behind-schedule']]: s.status && s.status.toLocaleLowerCase() === 'behind schedule'}, 
                                                        {[styles.pending]: s.status && s.status.toLocaleLowerCase() === 'pending'})}></div>
                                    <div className={styles['item-name']}>{s.name}</div>
                                    {/* <div>{s.status}</div> */}
                                </li>
                            )
                        })
                    }
                </ul>
                <div className={styles.footer}>
                    <div className={styles.overdue}><span>{selectedProject?.id ? selectedProject.structuresBehindScheduleCount : behind}</span> late</div>
                    <div className={styles.completed}><span>{selectedProject?.id ? selectedProject.structuresInProgressCount : active}</span> in progress</div>
                    <div className={styles.pending}><span>{selectedProject?.id ? selectedProject.structuresPendingCount : upcoming}</span> not started</div>
                </div>
            </div>
        </Paper>
    )
}

export default StructuresList;