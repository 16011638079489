import { LogLevel } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
	names: {
		signUpSignIn: 'b2c_1_sign_up_sign_in',
		signIn: 'b2c_1_sign_in',
		signUp: 'b2c_1_sign_up',
		reset: 'b2c_1_reset'
	},
	authorities: {
		signUpSignIn: {
			authority: `${process.env.REACT_APP_AUTH_URL}/b2c_1_sign_up_sign_in`
		},
		signIn: {
			authority: `${process.env.REACT_APP_AUTH_URL}/b2c_1_sign_in`
		},
		signUp: {
			authority: `${process.env.REACT_APP_AUTH_URL}/b2c_1_sign_up`
		},
		reset: {
			authority: `${process.env.REACT_APP_AUTH_URL}/b2c_1_reset`
		}
	},
	authorityDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
	auth: {
		clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
		authority: b2cPolicies.authorities.signIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: `${process.env.REACT_APP_AUTH_REDIRECT}/home`,
		postLogoutRedirectUri: process.env.REACT_APP_AUTH_REDIRECT, // Indicates the page to navigate after logout.
		// redirectUri: "http://localhost:3000/home",
		// postLogoutRedirectUri: "http://localhost:3000", // Indicates the page to navigate after logout.
		navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			}
		}
	}
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
//  export const protectedResources = {
//   apiHello: {
//       endpoint: "http://localhost:5000/hello",
//       scopes: ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"], // e.g. api://xxxxxx/access_as_user
//   },
// }

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
// export const loginRequest = {
//   scopes: [...protectedResources.apiHello.scopes]
// };

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
//  scopes: ["openid", "profile"]
//  scopes: ["User.Read"]
// };

// Add the endpoints here for Microsoft Graph API services you'd like to use.
//   export const graphConfig = {
//       graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//   };
