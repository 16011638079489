import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { Paper } from '@mui/material';

import styles from '../structure.module.scss';
import Heading from '../../../components/heading/Heading';
import { Tab } from '../../../components/tab/interfaces';
import TabItem from '../../../components/tab/TabItem';
import Map from '../../../components/map/Map.js';
import { Modal } from '../../../components/modal/Modal';
import { fetchStructure, uploadFile } from '../actions';
import { Structure, StructureState, Attachment } from '../interfaces';
import TasksActionTable from '../../../components/tables/TasksActionTable';
import { fetchTasks } from '../../tasks/actions';
import History from '../../history/History';
import { TasksContent } from '../../tasks/Tasks';
import StructureForm from '../../../components/forms/StructureForm';
import Camera from '../../../components/camera/Camera';
import PhotoGallery from '../../../components/gallery/photo-gallery';
import {User} from '../../users/interfaces';
import { Task } from '../../tasks/interfaces';

type MapProps = ({ structure?: Structure });
const StructureMap = ({ structure }: MapProps) => {
    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);
    const relatedStructures = structuresStore.structures[0]?.relatedStructures;
    return (
        <Map 
            lat={structure?.latitude} 
            lng={structure?.longitude} 
            markers={[{
                lat: structure?.latitude, 
                lng: structure?.longitude, 
                id : structure?.id,
				status: structure?.status,
                content: `<div class='info-container'>
                            <div class='heading-section'>${structure?.name}</div>
                            <div class='body-section'>
                                <div class='section-item'>
                                    <div class='item-label'>Type</div>
                                    <div class='item-value'>${structure?.type}</div>
                                </div>
                                <div class='section-item'>
                                    ${
                                        structure?.type?.toLowerCase() === 'drilled pier' ? 
                                        `<div class='item-label'>Drilled Pier Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Diameter: ${structure?.diameter} (in)</div><div>Depth + Reveal: ${structure?.depth} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'pier' ? 
                                        `<div class='item-label'>Pier Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Diameter: ${structure?.diameter} (in)</div><div>Depth: ${structure?.depth} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'slab' ? 
                                        `<div class='item-label'>Slab Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length} (ft)</div><div>Width: ${structure?.width} (ft)</div><div>Depth: ${structure?.depth} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'slab on pier' ?  
                                        `<div class='item-label'>Slab Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length} (ft)</div><div>Width: ${structure?.width} (ft)</div><div>Depth: ${structure?.depth} (ft)</div>
                                            </div>
                                        </div>
                                        <div class='item-label second-item'>Pier Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Diameter: ${structure?.diameter} (in)</div><div>Depth: ${structure?.depth2} (ft)</div><div>Qty: ${structure?.quantity}</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'slab on beam' ? 
                                        `<div class='item-label'>Slab Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length} (ft)</div><div>Width: ${structure?.width} (ft)</div><div>Depth: ${structure?.depth} (ft)</div>
                                            </div>
                                        </div>
                                        <div class='item-label second-item'>Beam Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length2} (ft)</div><div>Width: ${structure?.width2} (ft)</div><div>Depth: ${structure?.depth2} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'wall' ? 
                                        `<div class='item-label'>Wall Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length} (ft)</div><div>Thickness: ${structure?.thickness} (ft)</div><div>Height: ${structure?.height} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'direct embed' ? 
                                        `<div class='item-label'>Direct Embed Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Diameter: ${structure?.diameter} (in)</div><div>Depth: ${structure?.depth} (ft)</div><div>Annulus: ${structure?.annulus} (in)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'prefab install' ? 
                                        `<div class='item-label'>Prefab Install Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length}(ft)</div><div>Width: ${structure?.width} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'conduit' ? 
                                        `<div class='item-label'>Conduit Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'ground grid' ? 
                                        `<div class='item-label'>Ground Grid Dimensions</div>
                                        <div class='item-value'>
                                            <div class='multi-items'>
                                                <div>Length: ${structure?.length} (ft)</div>
                                            </div>
                                        </div>` : 
                                        structure?.type?.toLowerCase() === 'other' ? 
                                        `<div class='item-label'>Other Dimensions</div>
                                        <div class='item-value'>
                                        <div class='multi-items'>
                                            ${structure?.length ? '<div>Length: '+ structure?.length +'(ft)</div>' : ''}
                                            ${structure?.width ? '<div>Width: '+ structure?.width +'(ft)</div>' : ''}
                                            ${structure?.height ? '<div>Height: '+ structure?.height +'(ft)</div>' : ''}
                                            ${structure?.thickness ? '<div>Thickness: '+ structure?.thickness +'(ft)</div>' : ''}
                                            ${structure?.depth ? '<div>Depth: '+ structure?.depth +'(ft)</div>' : ''}
                                            ${structure?.diameter ? '<div>Diameter: '+ structure?.diameter +'(in)</div>' : ''}
                                            ${structure?.annulus ? '<div>Annulus: '+ structure?.annulus +'(in)</div>' : ''}
                                            ${structure?.quantity ? '<div>Quantity: '+ structure?.quantity +'</div>' : ''}
                                            ${structure?.length2 ? '<div>Length 2: '+ structure?.length2 +'(ft)</div>' : ''}
                                            ${structure?.width2 ? '<div>Width 2: '+ structure?.width2 +'(ft)</div>' : ''}
                                            ${structure?.depth2 ? '<div>Depth 2: '+ structure?.depth2 +'(ft)</div>' : ''}
                                            </div>
                                    </div>` :``
                                    }
                                </div>
                            </div>
                        </div>`
            }]}
            title={structure?.name} 
            relatedStructures = {relatedStructures?.map(s => {
                const content = `<div class='info-container'>
                                    <div class='heading-section' id = ${s.id}><a style = 'color: inherit;
                                    text-decoration: none; cursor : pointer;'>${s?.name}</a></div>
                                    <div class='body-section'>
										<div class='section-item'>
											<div class='item-label'>Status</div>
											<div class='item-value'>${s.status}</div>
										</div>
                                        <div class='section-item'>
                                            <div class='item-label'>Type</div>
                                            <div class='item-value'>${s.type}</div>
                                        </div>
                                        <div class='section-item'>
                                            ${
                                                s?.type?.toLowerCase() === 'drilled pier' ? 
                                                `<div class='item-label'>Drilled Pier Dimensions</div>
                                                <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Diameter: ${s?.diameter} (in)</div><div>Depth + Reveal: ${s?.depth} (ft)</div>
                                                    </div>
                                                </div>` : 
                                                s.type?.toLowerCase() === 'pier' ? 
                                                `<div class='item-label'>Pier Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Diameter: ${s.diameter} (in)</div><div>Depth: ${s.depth} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'slab' ? 
                                                `<div class='item-label'>Slab Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length} (ft)</div><div>Width: ${s.width} (ft)</div><div>Depth: ${s.depth} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'slab on pier' ?  
                                                `<div class='item-label'>Slab Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length} (ft)</div><div>Width: ${s.width} (ft)</div><div>Depth: ${s.depth} (ft)</div>
                                                    </div>
                                                 </div>
                                                 <div class='item-label second-item'>Pier Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Diameter: ${s.diameter} (in)</div><div>Depth: ${s.depth2} (ft)</div><div>Qty: ${s.quantity}</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'slab on beam' ? 
                                                `<div class='item-label'>Slab Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length} (ft)</div><div>Width: ${s.width} (ft)</div><div>Depth: ${s.depth} (ft)</div>
                                                    </div>
                                                 </div>
                                                 <div class='item-label second-item'>Beam Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length2} (ft)</div><div>Width: ${s.width2} (ft)</div><div>Depth: ${s.depth2} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'wall' ? 
                                                `<div class='item-label'>Wall Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length} (ft)</div><div>Thickness: ${s.thickness} (ft)</div><div>Height: ${s.height} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'direct embed' ? 
                                                `<div class='item-label'>Direct Embed Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Diameter: ${s.diameter} (in)</div><div>Depth: ${s.depth} (ft)</div><div>Annulus: ${s.annulus} (in)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'prefab install' ? 
                                                `<div class='item-label'>Prefab Install Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length}(ft)</div><div>Width: ${s.width} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'conduit' ? 
                                                `<div class='item-label'>Conduit Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'ground grid' ? 
                                                `<div class='item-label'>Ground Grid Dimensions</div>
                                                 <div class='item-value'>
                                                    <div class='multi-items'>
                                                        <div>Length: ${s.length} (ft)</div>
                                                    </div>
                                                 </div>` : 
                                                s.type?.toLowerCase() === 'other' ? 
                                                `<div class='item-label'>Other Dimensions</div>
                                                <div class='item-value'>
                                                <div class='multi-items'>
                                                    ${s.length ? '<div>Length: '+ s.length +'(ft)</div>' : ''}
                                                    ${s.width ? '<div>Width: '+ s.width +'(ft)</div>' : ''}
                                                    ${s.height ? '<div>Height: '+ s.height +'(ft)</div>' : ''}
                                                    ${s.thickness ? '<div>Thickness: '+ s.thickness +'(ft)</div>' : ''}
                                                    ${s.depth ? '<div>Depth: '+ s.depth +'(ft)</div>' : ''}
                                                    ${s.diameter ? '<div>Diameter: '+ s.diameter +'(in)</div>' : ''}
                                                    ${s.annulus ? '<div>Annulus: '+ s.annulus +'(in)</div>' : ''}
                                                    ${s.quantity ? '<div>Quantity: '+ s.quantity +'</div>' : ''}
                                                    ${s.length2 ? '<div>Length 2: '+ s.length2 +'(ft)</div>' : ''}
                                                    ${s.width2 ? '<div>Width 2: '+ s.width2 +'(ft)</div>' : ''}
                                                    ${s.depth2 ? '<div>Depth 2: '+ s.depth2 +'(ft)</div>' : ''}
                                                    </div>
                                            </div>` :``
                                            }
                                        </div>
                                    </div>
                                 </div>`;
                return ({ lat: s.latitude, lng: s.longitude, content: content, id: s.id, status: s.status })
            })}
			isFromStructures = {true}
        />
    )
}
const TaskInfo = ({ structure }: MapProps ) => {
    const [showTable, setShowTable] = useState<boolean>(true);
    return (
        <>
            <div className={cx(styles['task-info-expand-colapse'], {[styles.selected]: showTable})} onClick={() => setShowTable(!showTable)}></div>
            <div className={cx(styles['task-info'], {[styles['task-info-collapse']]: !showTable})}>
                <TasksActionTable />
                <div className={styles['structure-detail']}>
                    <div className={styles['detail-item']}>
                        <div className={styles.title}>Description:</div>
                        <div>{structure?.description || 'No description information'}</div>
                    </div>
                    <div className={styles['detail-item']}>
                        <div className={styles.title}>Lat/Lng: </div>
                        <div>{`${structure?.latitude}/${structure?.longitude}`}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
type ImageProps = ({ attachments?: Attachment[] });
const ImageContent = ( { attachments }: ImageProps ) => {
    const history = useHistory();
    const [currentAttachment, setCurrentAttachment] = useState<Attachment | undefined>(attachments ? attachments[0] : undefined);

    const images: ReactImageGalleryItem[] | undefined = attachments?.map(a => {
        const caption = a.fileName ? 
                        `${ a.fileName } - ${a.createdOn && dayjs(a.createdOn).format('MM/DD/YYYY HH:mm')}` : 
                        a.createdOn && dayjs(a.createdOn).format('MM/DD/YYYY HH:mm');
        return {
            original: a.uri,
			thumbnail: a.uri, 
            description: caption
        }
    });

    const updateCurrentImage = (imagePath: string) => {
        const attachment = attachments?.find(a => a.uri === imagePath);
        setCurrentAttachment(attachment);
    }

    return (
        <div style={{ marginTop: '20px' }} className={styles['table-container']}>
            { attachments && attachments.length > 0 && images && images.length > 0 ? 
                <div className={styles['image-data']}>
                    <div className={styles.title}>
                        <div className={styles['title-item']} onClick={() => history.push(`/tasks/${encodeURIComponent(currentAttachment?.workTaskId || '')}`)}>
                            {currentAttachment?.workTaskName || currentAttachment?.workTaskId || 'No task name available'}
                        </div>
                    </div>
                    <PhotoGallery images={images} updateCurrentImage={updateCurrentImage} />
                </div> : 
                <div className={styles['no-data']}>No images found for this structure. Click the camera button to upload a new image.</div> }
        </div> 
    )
}

type ImageCatureProps = ({ profile: User, structure?: Structure, 
                           discard(): void, closeModal(): void, 
                           showCameraModal: boolean, setShowCameraModal(arg: boolean): void, 
                           showEditModal: boolean, setShowEditModal(arg: boolean): void });
const ImageCapture = ({ profile, structure, discard, closeModal, showCameraModal, setShowCameraModal, showEditModal, setShowEditModal }: ImageCatureProps) => {
    const dispatch = useDispatch();

    const [selectedTask, setSelectedTask] = useState<Task>();
    const [imageCaption, setImageCaption] = useState<string>('');

    const callback = useCallback((task, caption) => {
        setSelectedTask(task);
        setImageCaption(caption);
    }, []);

    const upload = async (file: any) => {
        const firstName = profile.firstName ? profile.firstName : '';
        const lastName = profile.lastName ? profile.lastName : '';
        const data = (selectedTask !== undefined && selectedTask.id !== undefined) ? {
            FileName : imageCaption,
            StructureId : selectedTask ? selectedTask.structureId : '',
            StructureName : selectedTask ? selectedTask.structureName : '',
            TaskId : selectedTask ? selectedTask.id : '',
            TaskName : selectedTask ? selectedTask.name : '',
            CreatedBy : firstName + " " + lastName,
            CreatedById : profile.id
        } : 
        {
            FileName : imageCaption,
            StructureId : structure?.id,
            StructureName : structure?.name,
            CreatedBy : firstName + " " + lastName,
            CreatedById : profile.id
        };
        dispatch(uploadFile(file, new Date().toTimeString(), data));
        setTimeout(() => setShowCameraModal(false), 800);
    };
    return (
        <>
            {
                process.env.REACT_APP_ENABLE_CAMERA?.toString().toLowerCase() === 'true' && showCameraModal && 
                <Modal title='Capture Image' closeModal={() => setShowEditModal(true)}>
                    <Camera sendFile={upload} showEModal={showEditModal} closeModal={closeModal} discard={() => discard()} parentCallback={callback} />
                </Modal>
            }
        </>
    )
}

const StructureItem = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    type Params = { id: string; }
    const { id } = useParams<Params>();
    // const id = 'structures%2F626-A';

    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCameraModal, setShowCameraModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);	   

    const structuresStore = useSelector((state: { structuresStore: StructureState }) => state.structuresStore);
    const structure = structuresStore.structures.find(s => s.id === decodeURIComponent(id));
    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;

    useEffect(() => {
        dispatch(fetchStructure(25, 1, undefined, decodeURIComponent(id)));
        dispatch(fetchTasks(25, 1, undefined, decodeURIComponent(id), undefined, undefined, undefined, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
        setShowCameraModal(false);
    }
    const formatDuration = (structureDuration: number | undefined) => {
        return structureDuration ? `${(Math.floor((structureDuration / 3600)))}:${('0' + Math.floor((structureDuration / 60) % 60)).slice(-2)}:${('0' + Math.floor((structureDuration / 1) % 60)).slice(-2)}` : '';
    };

    const tabItems: Tab[] = [
        { id: 1, name: 'Structure' }, { id: 2, name: 'Tasks' },
        // { id: 3, name: 'History' },
        { id: 4, name: 'Images' }
    ]
    const tasksContent = (
        <div className={styles['table-container']}>
            <TasksContent showSort={true} showTitle={false} structure={structure} />
        </div>
    );
    const historyContent = (
        <div className={styles['table-container']}>
            <History showSort={true} showTitle={false} history={structure?.changeHistory?structure.changeHistory:[]}/>
        </div>
    );
    return (
        <div className={styles['structure-item-container']}>
            <div className={styles['structure-item-heading']}>
                <Heading title={structure?.name || ''} isClickable={true} clickAction={() => setShowModal(true)} />
                {   
                    process.env.REACT_APP_ENABLE_CAMERA?.toString().toLowerCase() === 'true' &&
                    <div className={styles['icons']}>  
                        <FontAwesomeIcon icon={faCamera} size={'2x'} onClick={() => setShowCameraModal(true)} />
                    </div>
                }
            </div>
            <div className={styles['item-detail-section']}>
                <div className={styles.item}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className={styles.title}>Structure Info</div>
                        {
                            structuresStore.loading ? 
                                <div className={styles['row-spinner']}>
                                    <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                        <div className={styles.spinner}></div>
                                    </div>
                                </div> : 
                            <>
                                <div className={styles.detail}>
                                    <div>Project</div>
                                    <div className={styles['clickable-item']} onClick={() => history.push(`/projects/${encodeURIComponent(structure?.projectId || '')}`)}>{structure?.projectName}</div>
                                </div>
                                <div className={styles.detail}>
                                    <div>Type</div>
                                    <div>{structure?.type}</div>
                                </div>
                                <div className={styles.detail}>
                                    <div>Status</div>
                                    <div>{structure?.status}</div>
                                </div>
                                <div className={styles.detail}>
                                    <div>Start date</div>
                                    <div>{structure?.startDate ? new Date(structure?.startDate).toLocaleDateString() : ''}</div>
                                </div>
                                <div className={styles.detail}>
                                    <div>Aggregate Estimated Duration</div>
                                    <div>{formatDuration(structure?.aggregateEstimatedDuration)}</div>
                                </div>
                                <div className={styles.detail}>
                                    <div>Aggregate Duration</div>
                                    <div>{formatDuration(structure?.aggregateDuration)}</div>
                                </div>
                                <div className={styles.subtitle}>Tasks</div>
                                <div className={styles.detail}>
                                    <div>Completed / Total</div>
                                    <div>{structure?.workTasksCompletedCount} / {structure?.workTasksTotalCount}</div>
                                </div>
                            </>
                        }
                    </Paper>
                </div>
                <div className={styles.item}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className={styles.title}>Dimension &amp; Material Info</div>
                        {
                            structuresStore.loading ? 
                            <div className={styles['row-spinner']}>
                                <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                    <div className={styles.spinner}></div>
                                </div>
                            </div> :
                            <>
                                {   structure?.type?.toLocaleLowerCase() === 'drilled pier' && 
                                    <>
                                        <div className={styles.subtitle}>Drilled Pier Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Diameter (in)</div>
                                            <div>{structure?.diameter || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Depth + Reveal (ft)</div>
                                            <div>{structure?.depth || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'slab' && 
                                    <>
                                        <div className={styles.subtitle}>Slab Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Width (ft)</div>
                                            <div>{structure?.width || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Depth (ft)</div>
                                            <div>{structure?.depth || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'slab on pier' && 
                                    <>
                                        <div className={styles.subtitle}>Slab Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Width (ft)</div>
                                            <div>{structure?.width || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Depth (ft)</div>
                                            <div>{structure?.depth || '-'}</div>
                                        </div>
                                        <div className={styles.subtitle}>Pier Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Diameter (in)</div>
                                            <div>{structure?.diameter || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Depth (ft)</div>
                                            <div>{structure?.depth2 || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Quantity</div>
                                            <div>{structure?.quantity || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'slab on beam' && 
                                    <>
                                        <div className={styles.subtitle}>Slab Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Width (ft)</div>
                                            <div>{structure?.width || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Depth (ft)</div>
                                            <div>{structure?.depth || '-'}</div>
                                        </div>
                                        <div className={styles.subtitle}>Beam Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Total length (ft)</div>
                                            <div>{structure?.length2 || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Avg width (ft)</div>
                                            <div>{structure?.width2 || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Avg depth</div>
                                            <div>{structure?.depth2 || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'wall' && 
                                    <>
                                        <div className={styles.subtitle}>Wall Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Thickness (ft)</div>
                                            <div>{structure?.thickness || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Height (ft)</div>
                                            <div>{structure?.height || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'direct embed' && 
                                    <>
                                        <div className={styles.subtitle}>Direct Embeded Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Diameter (in)</div>
                                            <div>{structure?.diameter || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Depth (ft)</div>
                                            <div>{structure?.depth || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Annulus (in)</div>
                                            <div>{structure?.annulus || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'prefab install' && 
                                    <>
                                        <div className={styles.subtitle}>Prefab Install Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                        <div className={styles.detail}>
                                            <div>Width (ft)</div>
                                            <div>{structure?.width || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'conduit' && 
                                    <>
                                        <div className={styles.subtitle}>Conduit Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'ground grid' && 
                                    <>
                                        <div className={styles.subtitle}>Ground Grid Dimensions</div>
                                        <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length || '-'}</div>
                                        </div>
                                    </>
                                }
                                {   structure?.type?.toLocaleLowerCase() === 'other' && 
                                    <>
                                        <div className={styles.subtitle}>Other Dimensions</div>
                                        {structure?.length && <div className={styles.detail}>
                                            <div>Length (ft)</div>
                                            <div>{structure?.length}</div>
                                        </div>}
                                        {structure?.width && <div className={styles.detail}>
                                            <div>Width (ft)</div>
                                            <div>{structure?.width}</div>
                                        </div>}
                                        {structure?.height && <div className={styles.detail}>
                                            <div>Height (ft)</div>
                                            <div>{structure?.height}</div>
                                        </div>}
                                        {structure?.thickness && <div className={styles.detail}>
                                            <div>Thickness (ft)</div>
                                            <div>{structure?.thickness}</div>
                                        </div>}
                                        {structure?.depth && <div className={styles.detail}>
                                            <div>Depth (ft)</div>
                                            <div>{structure?.depth}</div>
                                        </div>}
                                        {structure?.diameter && <div className={styles.detail}>
                                            <div>Diameter (in)</div>
                                            <div>{structure?.diameter}</div>
                                        </div>}
                                        {structure?.annulus && <div className={styles.detail}>
                                            <div>Annulus (in)</div>
                                            <div>{structure?.annulus}</div>
                                        </div>}
                                        {structure?.quantity && <div className={styles.detail}>
                                            <div>Quantity</div>
                                            <div>{structure?.quantity}</div>
                                        </div>}
                                        {structure?.length2 && <div className={styles.detail}>
                                            <div>Length 2 (ft)</div>
                                            <div>{structure?.length2}</div>
                                        </div>}
                                        {structure?.width2 && <div className={styles.detail}>
                                            <div>Width 2 (ft)</div>
                                            <div>{structure?.width2}</div>
                                        </div>}
                                        {structure?.depth2 && <div className={styles.detail}>
                                            <div>Depth 2 (ft)</div>
                                            <div>{structure?.depth2}</div>
                                        </div>}
                                    </>
                                }
                                {   (structure?.concrete || structure?.steel || structure?.backfill) && <div className={styles.subtitle}>Materials</div>}
                                {   structure?.concrete && 
                                    <div className={styles.detail}>
                                        <div>Concrete (CUYD)</div>
                                        <div>{structure.concrete}</div>
                                    </div>
                                }
                                {   structure?.steel && 
                                    <div className={styles.detail}>
                                        <div>Steel (lb. x 1,000)</div>
                                        <div>{structure.steel}</div>
                                    </div>
                                }
                                {   structure?.type?.toLocaleLowerCase() !== 'drilled pier' && structure?.backfill && 
                                    <div className={styles.detail}>
                                        <div>Backfill (CUYD)</div>
                                        <div>{structure.backfill}</div>
                                    </div>
                                }
                            </>
                        }
                    </Paper>
                </div>
            </div>
            <div className={styles['tabs-container']}>
                <div className={styles.tabs}>
                    {tabItems.map(ti => <TabItem key={ti.id} tab={ti} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />)}
                </div>
                <div className={styles['tab-content']}>
                    { selectedTab === 1 ? 
                        <div className={styles['structure-map']}>
                            <StructureMap structure={structure} />
                            <TaskInfo structure={structure} />
                        </div> :
                        selectedTab === 2 ? tasksContent :
                        selectedTab === 3 ? historyContent : 
                        selectedTab === 4 ? <ImageContent attachments={structure?.attachments} /> : null }
                </div>
            </div>
            {showModal && <Modal title='Edit Structure' closeModal={() => setShowEditModal(true)}><StructureForm structure={structure || undefined} isExisting={true} showEModal={showEditModal} closeModal={() => setShowEditModal(false)} discard={() => discard()} /></Modal>}
            <ImageCapture profile={profile} structure={structure} discard={discard} closeModal={() => setShowEditModal(false)} showCameraModal={showCameraModal} setShowCameraModal={setShowCameraModal} showEditModal={showEditModal} setShowEditModal={setShowEditModal} />
        </div>
    )
}

export default StructureItem;