import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Paper } from '@mui/material';

import styles from '../projects.module.scss';
import { ProjectState } from '../interfaces';
import { User } from '../../users/interfaces';
import { fetchProjectDetail } from '../actions';
import Heading from '../../../components/heading/Heading';
import { Modal,OtherContactModel } from '../../../components/modal/Modal';
import { Tab } from '../../../components/tab/interfaces';
import TabItem from '../../../components/tab/TabItem';
import Map from '../../../components/map/Map.js';
import ProjectForm from '../../../components/forms/ProjectForm';
import { StructuresContent } from '../../structures/Structures';
import { fetchStructure } from '../../structures/actions';
import History from '../../history/History';
import { fetchTasks } from '../../tasks/actions';
import { TasksContent } from '../../tasks/Tasks';
import Reports from '../../reports/Reports';

const ProjectItem = () => {
    const dispatch = useDispatch();
    type Params = { id: string; }
    const { id } = useParams<Params>();

    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showContactModal, setshowContactModal] = useState<boolean>(false);
    const [showOtherContact, setshowOtherContact] = useState<boolean>(false);

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);
    const project = projectsStore.selectedprojectinfo.project;

    useEffect(() => {
        if (id && profileStore?.profile?.id) dispatch(fetchProjectDetail(decodeURIComponent(id)
, profileStore.profile?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, profileStore.profile])

    useEffect(() => {
        if (project && project.id) {
            if (selectedTab === 2) dispatch(fetchStructure(25, 1, decodeURIComponent(project?.id)));
            if (selectedTab === 3) dispatch(fetchTasks(25, 1, undefined, undefined, decodeURIComponent(project?.id)));
            // if (selectedTab === 4) dispatch(fetchDailyReports(25, 1, decodeURIComponent(project?.id)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, project])

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }

      const handleChange1 = () =>{
        setshowOtherContact(false)
         }

    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    const discard1 = () => {
        setshowOtherContact(false);
        setshowContactModal(false);
    }

    const contactModal = () => {
        setshowOtherContact(true);
    }

    const tabItems: Tab[] = [
        { id: 1, name: 'Project' }, { id: 2, name: 'Structures' }, { id: 3, name: 'Tasks' }, { id: 4, name: 'Reports' },
        // { id: 5, name: 'History' }
    ]

    const projectContent = (
        <div className={styles['content-info']}>
            <div className={styles['project-description']}>
                <div className={styles.title}>Description</div>
                <div>{project?.description || 'No description available'}</div>
            </div>
            <div className={styles['project-map']}>
                <Map 
                    lat={project?.address?.latitude} 
                    lng={project?.address?.longitude} 
                    markers={projectsStore.selectedprojectinfo?.project?.structures?.map(s => {
                        const content = `<div class='info-container'>
                        <div class='heading-section' id = ${s.id}><a style = 'color: inherit;
                        text-decoration: none; cursor : pointer;'>${s?.name}</a></div>
                                            <div class='body-section'>
                                                <div class='section-item'>
                                                    <div class='item-label'>Type</div>
                                                    <div class='item-value'>${s.type}</div>
                                                </div>
                                                <div class='section-item'>
                                                    ${
                                                        s?.type?.toLowerCase() === 'drilled pier' ? 
                                                        `<div class='item-label'>Drilled Pier Dimensions</div>
                                                        <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Diameter: ${s?.diameter} (in)</div><div>Depth + Reveal: ${s?.depth} (ft)</div>
                                                            </div>
                                                        </div>` : 
                                                        s.type?.toLowerCase() === 'pier' ? 
                                                        `<div class='item-label'>Pier Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Diameter: ${s.diameter} (in)</div><div>Depth: ${s.depth} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'slab' ? 
                                                        `<div class='item-label'>Slab Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length} (ft)</div><div>Width: ${s.width} (ft)</div><div>Depth: ${s.depth} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'slab on pier' ?  
                                                        `<div class='item-label'>Slab Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length} (ft)</div><div>Width: ${s.width} (ft)</div><div>Depth: ${s.depth} (ft)</div>
                                                            </div>
                                                         </div>
                                                         <div class='item-label second-item'>Pier Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Diameter: ${s.diameter} (in)</div><div>Depth: ${s.depth2} (ft)</div><div>Qty: ${s.quantity}</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'slab on beam' ? 
                                                        `<div class='item-label'>Slab Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length} (ft)</div><div>Width: ${s.width} (ft)</div><div>Depth: ${s.depth} (ft)</div>
                                                            </div>
                                                         </div>
                                                         <div class='item-label second-item'>Beam Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length2} (ft)</div><div>Width: ${s.width2} (ft)</div><div>Depth: ${s.depth2} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'wall' ? 
                                                        `<div class='item-label'>Wall Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length} (ft)</div><div>Thickness: ${s.thickness} (ft)</div><div>Height: ${s.height} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'direct embed' ? 
                                                        `<div class='item-label'>Direct Embed Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Diameter: ${s.diameter} (in)</div><div>Depth: ${s.depth} (ft)</div><div>Annulus: ${s.annulus} (in)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'prefab install' ? 
                                                        `<div class='item-label'>Prefab Install Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length}(ft)</div><div>Width: ${s.width} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'conduit' ? 
                                                        `<div class='item-label'>Conduit Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                        s.type?.toLowerCase() === 'ground grid' ? 
                                                        `<div class='item-label'>Ground Grid Demensions</div>
                                                         <div class='item-value'>
                                                            <div class='multi-items'>
                                                                <div>Length: ${s.length} (ft)</div>
                                                            </div>
                                                         </div>` : 
                                                         s.type?.toLowerCase() === 'other' ? 
                                                         `<div class='item-label'>Other Dimensions</div>
                                                         <div class='item-value'>
                                                         <div class='multi-items'>
                                                            ${s.length ? '<div>Length: '+ s.length +'(ft)</div>' : ''}
                                                            ${s.width ? '<div>Width: '+ s.width +'(ft)</div>' : ''}
                                                            ${s.height ? '<div>Height: '+ s.height +'(ft)</div>' : ''}
                                                            ${s.thickness ? '<div>Thickness: '+ s.thickness +'(ft)</div>' : ''}
                                                            ${s.depth ? '<div>Depth: '+ s.depth +'(ft)</div>' : ''}
                                                            ${s.diameter ? '<div>Diameter: '+ s.diameter +'(in)</div>' : ''}
                                                            ${s.annulus ? '<div>Annulus: '+ s.annulus +'(in)</div>' : ''}
                                                            ${s.quantity ? '<div>Quantity: '+ s.quantity +'</div>' : ''}
                                                            ${s.length2 ? '<div>Length 2: '+ s.length2 +'(ft)</div>' : ''}
                                                            ${s.width2 ? '<div>Width 2: '+ s.width2 +'(ft)</div>' : ''}
                                                            ${s.depth2 ? '<div>Depth 2: '+ s.depth2 +'(ft)</div>' : ''}
                                                         </div>
                                                     </div>` : ``
                                                    }
                                                </div>
                                            </div>
                                         </div>`;
                        return ({ lat: s.latitude, lng: s.longitude, content: content, id: s.id })
                    })} 
                    title={project?.name} 
                />
            </div>
        </div>
    );
    const structuresContent = (
        <div className={styles['table-container']}>
            <StructuresContent showSort={true} showTitle={false} project={project} />
        </div>
    );
    const tasksContent = (
        <div className={styles['table-container']}>
            <TasksContent showSort={true} showTitle={false} project={project} />
        </div>
    );
	const reportsContent = (
        <div className={styles['table-container']}>
            <Reports project={project} showSort={false} showTitle={true} projectId = {project?.id}/>
        </div>
    );
    const historyContent = (
        <div className={styles['table-container']}>
            <History  showSort={true} showTitle={false} history={project?.changeHistory?project?.changeHistory:[]}/>
        </div>
    );

    // function findPrimaryContact(contact: Contact, index: number, array: Contact[]) {
    //     return contact?.isPrimary ? contact : null;
    // }

    return (
		<div className={styles['project-item-container']}>
			<div className={styles['project-item-heading']}>
				<Heading
					title={project?.name || ''}
					isClickable={true}
					clickAction={() => setShowModal(true)}
				/>
			</div>
			<div className={styles['item-detail-section']}>
				<div className={styles.item}>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column'
						}}>
						<div className={styles.title}>Project Info</div>
						{projectsStore.selectedprojectinfo.loading ? (
							<div
								className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
								<div className={styles.spinner}></div>
							</div>
						) : (
							<>
								<div className={styles.detail}>
									<div>Type</div>
									<div>{project?.type || '-'}</div>
								</div>
								<div className={styles.detail}>
									<div>Start Date:</div>
									<div>
										{project?.scheduledStartDate
											? new Date(
													project?.scheduledStartDate
											  ).toLocaleDateString()
											: '-'}
									</div>
								</div>
								<div className={styles.detail}>
									<div>Status:</div>
									<div>{project?.status || '-'}</div>
								</div>
								<div className={styles.detail}>
									<div>Primary Contact:</div>
									<div>
										{project?.primaryContact ? (
											<div>
												<div>{project?.primaryContact?.fullName} </div>
												{project?.primaryContact?.phoneNumber && (
													<div>
														<a
															href={`tel: ${project?.primaryContact?.phoneNumber}`}>
															{project?.primaryContact?.phoneNumber}
														</a>
													</div>
												)}
												{project?.primaryContact?.email && (
													<a
														href={`mailto: ${project?.primaryContact?.email}`}>
														{project?.primaryContact?.email}
													</a>
												)}
											</div>
										) : (
											'-'
										)}
									</div>
								</div>
								<div className={styles.detail}>
									<div
										className={styles['clickable-item']}
										onClick={contactModal}>
										Other Contacts
									</div>
									{showOtherContact && (
										<OtherContactModel
											title='Other Contacts'
											data={project}
											closeModal={() => handleChange1()}></OtherContactModel>
									)}
								</div>
							</>
						)}
					</Paper>
				</div>
				<div className={styles.item}>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column'
						}}>
						<div className={styles.title}>Project Totals</div>
						{projectsStore.selectedprojectinfo.loading ? (
							<div
								className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
								<div className={styles.spinner}></div>
							</div>
						) : (
							<>
								<div className={styles.subtitle}>Structures</div>
								<div className={styles.detail}>
									<div>Completed / Total</div>
									<div>
										{project?.structuresCompletedCount} /{' '}
										{project?.structuresTotalCount}
									</div>
								</div>
								<div className={styles.subtitle}>Tasks</div>
								<div className={styles.detail}>
									<div>Completed / Total</div>
									<div>
										{project?.workTasksCompletedCount} /{' '}
										{project?.workTasksTotalCount}
									</div>
								</div>
							</>
						)}
					</Paper>
				</div>
			</div>

			<div className={styles['tabs-container']}>
				<div className={styles.tabs}>
					{tabItems.map((ti) => (
						<TabItem
							key={ti.id}
							tab={ti}
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
						/>
					))}
				</div>
				<div className={styles['tab-content']}>
					{selectedTab === 1
						? projectContent
						: selectedTab === 2
						? structuresContent
						: selectedTab === 3
						? tasksContent
						: selectedTab === 4
						? reportsContent
						: selectedTab === 5
						? historyContent
						: null}
				</div>
			</div>
			{showModal && (
				<Modal title='Edit Project' closeModal={() => setShowEditModal(true)}>
					<ProjectForm
						project={project || undefined}
						isExisting={true}
						showEModal={showEditModal}
						closeModal={handleChange}
						discard={() => discard()}
					/>
				</Modal>
			)}
		</div>
	);
}

export default ProjectItem;