import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateZoomLevel } from '../../store/actions/actions';

import styles from './map.module.scss';
import '../../assets/css/map.css';
import { useHistory } from 'react-router-dom';

//Use the dot to signify selected
//Map icons mapped to statuses
//Blue = Pending
//Red = Behind Schedule
//Green = Complete
//Yellow = In Progress
const greenMarker = 'http://maps.gstatic.com/mapfiles/ms2/micons/green.png';
const blueMarker = 'http://maps.gstatic.com/mapfiles/ms2/micons/blue.png';
const redMarker = 'http://maps.gstatic.com/mapfiles/ms2/micons/red.png';
const yellowMarker = 'http://maps.gstatic.com/mapfiles/ms2/micons/yellow.png';

//selectedMarkers
const selectedBlueMarker = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
const selectedYellowMarker = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
const selectedGreenMarker = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
const selectedRedMarker = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';


const Map = (props) => {
	const { lat, lng, markers = [], relatedStructures = [], isFromStructures = false } = props;
	const history = useHistory();
    const dispatch = useDispatch();
	const profileStore = useSelector((state) => state.profileStore);
	const profile = profileStore.profile;

	//Removing Main Structure from Related Structure Array
	let filteredRelatedStructures = relatedStructures.filter((struct) => {
		if (struct.id !== markers[0].id) {
			return struct;
		}
	});

	let availableMarkers = [];
	let infoWindows = [];
	useEffect(() => {
		const map = new window.google.maps.Map(document.getElementById('google-map'), {
			zoom: profile?.mapZoomLevel ? Number(profile.mapZoomLevel) : 14,
			streetViewControl: false,
			fullscreenControl: false,
			scaleControl: true,
			center: { lat: lat, lng: lng },
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_LEFT,
				mapTypeIds: ['roadmap', 'satellite', 'hybrid']
			}
		});
		map.setMapTypeId('satellite');

		//Function to toggle Marker upon onclick
		// const toggleMarker = () => {
		// 	if (availableMarkers.length > 0) {
		// 		availableMarkers.forEach((marker) => {
		// 			if (marker.icon == blueMarker) {
		// 				marker.setIcon(redMarker);
		// 			}
		// 		});
		// 	}
		// };

		//Add Redirection EventListener to InfoWindow
		const bindRedirectURL = (windowObject, targetStructureId) => {
			new window.google.maps.event.addListener(windowObject, 'domready', () => {
				document.getElementById(targetStructureId)?.addEventListener("click", () => {
					history.push(`/structures/${encodeURIComponent(targetStructureId)}`);
				});
			});
		}

		//Store zoom level on change
		new window.google.maps.event.addListener(map, 'zoom_changed', function () {
			let zoomLevel = map.getZoom();
			if (zoomLevel) {
				profile.mapZoomLevel = zoomLevel.toString();
				dispatch(updateZoomLevel(profile));
			}
		});

		//Adding Markers on Map
		const renderMarkers = (markerData, isRelated) => {
			markerData.forEach((m) => {
				const infowindow = new window.google.maps.InfoWindow({
					content: m.content
				});
				bindRedirectURL(infowindow, m.id);
				infoWindows.push(infowindow);

				let markerColor = yellowMarker;
				let isSelected = !isRelated && filteredRelatedStructures.length > 0;
				switch (m?.status?.toLocaleLowerCase()) {
					case 'pending':
						markerColor = isSelected ? selectedBlueMarker : blueMarker;
						break;

					case 'in progress':
						markerColor = isSelected ? selectedYellowMarker : yellowMarker;
						break;

					case 'behind schedule':
						markerColor = isSelected ? selectedRedMarker : redMarker;
						break;

					case 'completed':
					case 'completed late':
						markerColor = isSelected ? selectedGreenMarker : greenMarker;
						break;

					default:
						markerColor = yellowMarker;
				}

				const marker = new window.google.maps.Marker({
					position: { lat: m.lat, lng: m.lng },
					map,
					title: props.title,
					icon: {
						url: markerColor
					}
				});
				marker.addListener('click', () => {
					// toggleMarker();
					// marker.setIcon(blueMarker);
					infoWindows.forEach((openedWindow) => {
						if(openedWindow !== infowindow) {
							openedWindow.close();
						}
					})
					infowindow.open({
						anchor: marker,
						map,
						shouldFocus: false
					});
				});
				availableMarkers.push(marker);
			});
		};

		renderMarkers(markers, false);

		renderMarkers(filteredRelatedStructures, true);
		/*markers.forEach((m) => {
			const infowindow = new window.google.maps.InfoWindow({
				content: m.content
			});
			const marker = new window.google.maps.Marker({
				position: { lat: m.lat, lng: m.lng },
				map,
				title: props.title,
				icon: {
					url: (relatedStructures.length > 0) ? blueMarker : redMarker
				}
			});
			marker.addListener('click', () => {
				infowindow.open({
					anchor: marker,
					map,
					shouldFocus: false
				});
			});
			availableMarkers.push(marker);
		});*/
	}, [markers]);

	return <div id='google-map' className={styles[props.isFromStructures ? 'map-container-structures' : 'map-container']} />;
};

export default Map;
