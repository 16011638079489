import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { Task, Comment, AddComment } from '../../views/tasks/interfaces';
import styles from './forms.module.scss';
import { User } from '../../views/users/interfaces';
import { createComment } from '../../views/tasks/actions';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';

type Props = ({ task?: Task, comment?: Comment, closeModal: any, showEModal?: boolean, discard ?: any });
const CommentForm = ({ task, comment, closeModal, showEModal, discard }: Props) => {
    const dispatch = useDispatch();

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;

    const [addComment, setAddComment] = useState<AddComment>({
        taskId: task?.id, comment: {
            authorUserId: profile?.id,
            authorFullName: profile?.firstName+" "+profile.lastName,
            comment: comment?.comment || '',
            status: comment?.status,
            createdOn: new Date()
        }
    })
    const [showCommentRequired, setShowCommentRequired] = useState<boolean>(false);
    //States For Confirmation modal before save/create
    const [showEditModal, setShowEditModal] = useState<boolean>(false);  
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    const setComment = (e: ChangeEvent<HTMLInputElement>) => {
        setUnsavedChanges(true);
        setAddComment({ ...addComment, comment: {
            comment: e.target.value, authorUserId: addComment.comment?.authorUserId, authorFullName: addComment.comment?.authorFullName, createdOn: new Date()
        } })
    }

    const handleSaveComment = () => {
        if (!addComment.comment?.comment) setShowCommentRequired(true);
        if (addComment.comment?.comment && addComment.comment?.comment !== '' ){
            dispatch(createComment({ ...addComment }));
            discard();
            closeModal();
        }
       
    }

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }

    const form = document.getElementById("commentForm");

    if(form && !unsavedChanges) {
        form.addEventListener("input", function () {
            setUnsavedChanges(true);
        });
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    return (
        <>
            <div className={styles['form-container']}>
                <form id='commentForm'>
                    <div className={styles['inputs-section']}>

                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                {showCommentRequired ? <label className={styles.required}>* comment is required</label> : <label>{addComment.comment?.comment ? 'comment' : ''}</label>}  
                                <input type='text' placeholder='Comment' value={addComment.comment?.comment} onChange={(e) => setComment(e)} />
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            <div className={styles.actions}>
                <button onClick={() => {if(unsavedChanges) {setShowEditModal(true); } else {discard(); }}}>Cancel</button>
                <button onClick={() => { handleSaveComment(); }}>Add Comment</button>
            </div>
            { (showEModal || showEditModal) && <EditModal title='UNSAVED CHANGES' closeModal={() =>{handleChange()}}><EditPopup  closeModal={() =>{handleChange()}} editEntity={() =>handleSaveComment()} discardChanges = {() => discard()}/></EditModal> }
        </>
    )
}

export default CommentForm;
