import { useState, useRef } from 'react';
import cx from 'classnames';

import styles from './select.module.scss';
import { useEventListener } from '../../../hooks/hooks';

type Props = ({ placeholder: string, selectedItems?: any[], setSelectedItems?: any, items?: any[], isItemsObject: boolean, objectKeyField?: string, objectDisplayField?: string, directionUp?: boolean, selectref: any });
const SelectMulti = ({ placeholder, selectedItems, setSelectedItems, items, isItemsObject, objectKeyField, objectDisplayField, directionUp, selectref }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [showItems, setShowItems] = useState<boolean>(false);
    
    const handleOutsideClick = (e: any) => {
        if (selectref && selectref.current && !selectref.current.contains(e.target)) setShowItems(false);
    }
    const handleChecboxChange = (e: any, item: any) => {
        const currentItems = selectedItems ? [...selectedItems] : [];

        if (e.target.checked) currentItems.push(item);
        else currentItems.splice(currentItems.findIndex(c => c === item), 1);

        setSelectedItems([...currentItems]);
    }

    useEventListener('mousedown', handleOutsideClick);
    return (
        <div className={styles['select-container']} ref={selectref}>
            <div className={styles.select} onClick={() => setShowItems(!showItems)} ref={containerRef}>
                <div className={styles.label}>Select Contact</div>
                {/* <div className={styles.label}></div> */}
                <div className={styles.arrow}></div>
            </div>
            {
                items && items.length > 0 && showItems &&
                <div style={{ width: `${containerRef.current?.offsetWidth}px`}} className={cx(styles.items, {[styles['up-item']]: directionUp})}>
                    {
                        items.map(i => {
                            return (
                                <div key={isItemsObject ? i[objectKeyField || ''] : i} className={styles.item}>
                                    <label className={styles['checkbox-container']}>
                                        <div className={styles.title}>{isItemsObject ? i[objectDisplayField || ''] : i}</div>
                                        <input type='checkbox' checked={ selectedItems?.includes(i) } onChange={e => handleChecboxChange(e, i)} />
                                        <span className={styles['checkbox']} />
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default SelectMulti;