import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../authorization/AuthConfig';

import styles from './forms.module.scss';
import Phone from '../controls/phone/Phone';
import Select from '../controls/select/Select';
import { User } from '../../views/users/interfaces';
import { createUser, saveUser } from '../../views/users/actions';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';
import selector from '../controls/select/select.module.scss';

type Props = ({ user?: User, isExisting?: boolean, isViewMode?:boolean, closeModal: any, isProfile: boolean, showEModal?: boolean, discard ?: any });
const UserForm = ({ user, isExisting, isViewMode, closeModal, isProfile, showEModal, discard }: Props) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const [currentUser, setCurrentUser] = useState<User>({ id: user?.id || '', azureId: user?.azureId, firstName: user?.firstName, 
                                                           lastName: user?.lastName, email: user?.email, phoneNumber: user?.phoneNumber, 
                                                           company: user?.company, title: user?.title, role: user?.role});
    
    const [showFirstNameRequired, setShowFirstNameRequired] = useState<boolean>(false);
    const [showLastNameRequired, setShowLastNameRequired] = useState<boolean>(false);
    const [showEmailRequired, setShowEmailRequired] = useState<boolean>(false);
    const [showValidPhone, setShowValidPhone] = useState<boolean>(false);
     //States For Confirmation modal before save/create
     const [showEditModal, setShowEditModal] = useState<boolean>(false);  
     const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
     const [showUserRoleRequired, setShowUserRoleRequired] = useState<boolean>(false);
    
    const setCompany = (company: string) => setCurrentUser({...currentUser, company: company});
    const setRole = (role: string) => setCurrentUser({...currentUser, role: role });
    const setPhone = (number: string) => setCurrentUser({...currentUser, phoneNumber: number });
    const setTitle = (title: string) => setCurrentUser({...currentUser, title: title });

    const getRandomInt = () => Math.floor(Math.random() * 99999);
    const handleSaveUser = () => {
        handleChange();
        !currentUser.firstName || currentUser.firstName === '' ? setShowFirstNameRequired(true) : setShowFirstNameRequired(false);
        !currentUser.lastName || currentUser.lastName === '' ? setShowLastNameRequired(true) : setShowLastNameRequired(false);
        !currentUser.email || currentUser.email === '' ? setShowEmailRequired(true) : setShowEmailRequired(false);
        !currentUser.role || currentUser.role === '' ? setShowUserRoleRequired(true) : setShowUserRoleRequired(false);
        currentUser.phoneNumber && currentUser.phoneNumber?.length > 0 && currentUser.phoneNumber?.length < 14 ? setShowValidPhone(true) : setShowValidPhone(false);

        if (currentUser.firstName && currentUser.firstName !== '' && 
            currentUser.lastName && currentUser.lastName !== '' && 
            currentUser.email && currentUser.email !== '' && 
            currentUser.role && currentUser.role !== '' && 
            ((!currentUser.phoneNumber) || (currentUser.phoneNumber && currentUser.phoneNumber.length === 14))) {
            dispatch(isExisting ? saveUser(currentUser, isProfile) : createUser({...currentUser, azureId: getRandomInt().toString()}));
            discard();
            closeModal();
        }
    }

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }
    const handleChangePassword = () => {
        instance.loginRedirect({ scopes: [], 
            authority: b2cPolicies.authorities.reset.authority
        }).then((response: any) => {
            console.log("ForgotPassword Response: " + response.json())
        });
    }

    const form = document.getElementById("userForms");

    if(form && !unsavedChanges) {
        form.addEventListener("input", function () {
            setUnsavedChanges(true);
        });
    }

    const selectorItems = document.getElementsByClassName(selector.item);

    if(selectorItems.length > 0 && !unsavedChanges)
    {
        setUnsavedChanges(true);
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    const rolesRef = useRef<HTMLDivElement>(null);
    const companyRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);

    return (
		<>
			<div className={styles['form-container']}>
				<form id='userForms'>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showFirstNameRequired ? (
									<label className={styles.required}>
										* first name is required
									</label>
								) : (
									<label>{user?.firstName ? 'first name' : ''}</label>
								)}
								<input
									type='text'
									placeholder='First Name'
									value={currentUser?.firstName}
									onChange={(e) => {
										setUnsavedChanges(true);
										setCurrentUser({
											...currentUser,
											firstName: e.target.value
										});
									}}
									readOnly={isViewMode}
								/>
							</div>
							<div className={styles['input-item']}>
								{showLastNameRequired ? (
									<label className={styles.required}>
										* last name is required
									</label>
								) : (
									<label>{user?.lastName ? 'last name' : ''}</label>
								)}
								<input
									type='text'
									placeholder='Last Name'
									value={currentUser?.lastName}
									onChange={(e) => {
										setUnsavedChanges(true);
										setCurrentUser({
											...currentUser,
											lastName: e.target.value
										});
									}}
									readOnly={isViewMode}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showEmailRequired ? (
									<label className={styles.required}>* email is required</label>
								) : (
									<label>{user?.email ? 'email' : ''}</label>
								)}
								<input
									type='text'
									placeholder='Email'
									value={currentUser?.email}
									onChange={(e) => {
										setUnsavedChanges(true);
										setCurrentUser({ ...currentUser, email: e.target.value });
									}}
									readOnly={isViewMode}
								/>
							</div>
							<div className={styles['input-item']}>
								{showValidPhone ? (
									<label className={styles.required}>* phone is invalid</label>
								) : (
									<label>{user?.phoneNumber ? 'phone' : ''}</label>
								)}
								<Phone
									placeholder='phone'
									number={currentUser?.phoneNumber || ''}
									setSelected={setPhone}
									isViewMode={isViewMode || false}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								<label>{user?.company ? 'company' : ''}</label>
								<Select
									placeholder='COMPANY'
									items={[
										'Pilot Foundation Services',
										'Kiewit',
										'Kiewit - NextEra',
										'NextEra',
										'T-Minus Solutions'
									]}
									selectedItem={currentUser?.company}
									setSelectedItem={setCompany}
									selectref={companyRef}
									isItemsObject={false}
								/>
							</div>
							<div className={styles['input-item']}>
								<label>{user?.title ? 'title' : ''}</label>
								<Select
									placeholder='TITLE'
									items={[
										'Field',
										'Foreman',
										'Supervisor',
										'PM',
										'Officer',
										'Other'
									]}
									selectedItem={currentUser?.title}
									setSelectedItem={setTitle}
									selectref={titleRef}
									isItemsObject={false}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showUserRoleRequired ? (
									<label className={styles.required}>
										* User role is required
									</label>
								) : (
									<label>{user?.lastName ? 'user role' : ''}</label>
								)}
								<Select
									placeholder='USER ROLE'
									items={['Admin', 'Field User', 'Client']}
									selectedItem={currentUser?.role}
									setSelectedItem={setRole}
									selectref={rolesRef}
									isItemsObject={false}
									disabled={isProfile}
								/>
							</div>
						</div>
					</div>
					{isProfile && (
						<div className={styles['inputs-section']}>
							<div className={styles.inputs}>
								<div
									className={`${styles['input-item']} ${styles['password-item']}`}>
									<button
										type='button'
										className={styles['link-button']}
										onClick={() => handleChangePassword()}>
										Change Password
									</button>
								</div>
							</div>
						</div>
					)}
				</form>
			</div>
			{isViewMode ? (
				''
			) : (
				<div className={styles.actions}>
					<button
						onClick={() => {
							if (unsavedChanges) {
								setShowEditModal(true);
							} else {
								discard();
							}
						}}>
						Cancel
					</button>
					<button onClick={() => handleSaveUser()}>
						{isExisting ? 'Save' : 'Create'} User
					</button>
				</div>
			)}
			{(showEModal || showEditModal) && (
				<EditModal
					title='UNSAVED CHANGES'
					closeModal={() => {
						handleChange();
					}}>
					<EditPopup
						closeModal={() => {
							handleChange();
						}}
						editEntity={() => handleSaveUser()}
						discardChanges={() => discard()}
					/>
				</EditModal>
			)}
		</>
	);
}

export default UserForm;