import cx from 'classnames';

import styles from '../modal.module.scss';

type Props = ({children: any, isSmallModal?: boolean});
const ModalBody = ({ children, isSmallModal }: Props) => {
    return (
        <div className={cx(styles['modal-body'], {[styles['modal-normal']]: !isSmallModal})}>
            {children}
        </div>
    )
}

export default ModalBody;