import axios, { AxiosError } from 'axios';

import { FETCH_STRUCTURES, DELETE_STRUCTURES, SAVE_STRUCTURES, CREATE_STRUCTURES, UPLOAD_STRUCTURES, CLEAR_STRUCTURES_UPLOAD } from '../../store/actions/actionTypes';
import { RECCE_STRUCTURES, RECCE_STRUCTURES_UPLOAD, RECCE_STRUCTURES_CSV_UPLOAD } from '../../store/api/endPoints';
import { Structure } from './interfaces';
import { svcconfig, fileuploadconfig } from '../../store/actions/actions';

// const svcconfig = { headers: { 'Pragma': 'no-cache', 'Content-Type': 'application/json' } };

export const fetchStructure = (pageSize: number, pageNumber: number, projectId?: string, id?: string) => {
    const size = `PageSize=${pageSize}`; const number = `PageNumber=${pageNumber}`; 
    const endpoint = id ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES}?Ids=${encodeURIComponent(id)}&${size}&${number}` : 
                     projectId ? `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES}?ProjectIds=${encodeURIComponent(projectId)}&${size}&${number}` : 
                                 `${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES}?${size}&${number}`;
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: FETCH_STRUCTURES, 
            payload: axios.get(endpoint, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const saveStructure = (structure: Structure) => {
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            
            type: SAVE_STRUCTURES, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES}`, structure, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const deleteStructure = (structure: Structure) => {
    structure.status = 'Inactive';
    return (dispatch: (arg: { type: string; payload: any; }) => void) => {
        dispatch({
            type: DELETE_STRUCTURES, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES}`, structure, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return structure;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const createStructure = (structure: Structure) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CREATE_STRUCTURES, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES}`, structure, svcconfig())
                .then(resp => {
                    if (!resp.statusText && resp.statusText !== 'OK') return [];
                    return resp.data;
                })
                .catch((error: AxiosError) => {
                    if (error?.response?.status === 401) {
                        sessionStorage.removeItem('token');
                        window.location.href = '/logout';
                    }
                    return { data: undefined, error: error }
                })
        })
    }
}

export const uploadCsv = (file: any, projectId: string, projectName: string, createdBy: string, createdById: string) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        const formData = new FormData();
        formData.append('formFile', file);
        formData.append('projectId', projectId);
        formData.append('projectName', projectName);
        formData.append('createdBy', createdBy);
        formData.append('createdById', createdById);

        dispatch({
            type: UPLOAD_STRUCTURES, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES_CSV_UPLOAD}`, formData, fileuploadconfig())
                .then(resp => {
                    if (resp.statusText && resp.statusText === 'OK') return true;
                    else return false;
                })
                .catch((error: AxiosError) => {
                    return false;
                })
        })
    }
}

export const uploadFile = (file: any, fileName: string, imageProps? : any) => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        const formData = new FormData();
        formData.append('formFile', file);
        /*Removed this property as a part of Ticket #1526*/
        //formData.append('fileName', fileName); 
        if(imageProps !== undefined && imageProps !== {})
        {
            for (var prop in imageProps) {
                if (imageProps.hasOwnProperty(prop)) {     
                    formData.append(prop, imageProps[prop]);          
                }
            }
        }
        dispatch({
            type: UPLOAD_STRUCTURES, 
            payload: axios.post(`${process.env.REACT_APP_RECCE_API_BASE}${RECCE_STRUCTURES_UPLOAD}`, formData, fileuploadconfig())
                .then(resp => {
                    if (resp.statusText && resp.statusText === 'OK') return true;
                    else return false;
                })
                .catch((error: AxiosError) => {
                    return false;
                })
        })
    }
}

export const clearUpload = () => {
    return (dispatch: (arg: { type: string; payload: any }) => void) => {
        dispatch({
            type: CLEAR_STRUCTURES_UPLOAD, 
            payload: false
        })
    }
}