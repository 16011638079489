import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import styles from './forms.module.scss';
import SelectMulti from '../controls/select/SelectMulti';
import { Contact, Project } from '../../views/projects/interfaces';
import { User } from '../../views/users/interfaces';

type Props = ({ currentProject: Project, setCurrentProject: any })
const ContactsForm = ({ currentProject, setCurrentProject }: Props) => {
    const [selectedContacts, setSelectedContacts] = useState<User[]>([]);

    const contactsRef = useRef<HTMLDivElement>(null);
    const usersRef = useRef<HTMLDivElement>(null);

    type UsersStore = { users: User[], loading: boolean, error: any };
    const usersStore = useSelector((state: { usersStore: UsersStore }) => state.usersStore);

    const scrollToBottom = () => contactsRef.current?.scrollIntoView({ behavior: 'smooth'});
    
    useEffect(() => {
        const currentContacts: User[] = [];
        currentProject.contacts.forEach(c => {
            const user = usersStore.users.find(u => u.id === c.userId);
            if (user) {user.isPrimary = c.isPrimary; currentContacts.push(user);}
        });
        setSelectedContacts(currentContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        usersStore.users.forEach(u => u.fullName = `${u.lastName}, ${u.firstName}`);
    }, [usersStore.users]);
    useEffect(() => {
        const contacts: Contact[] = selectedContacts.map(sc => {return({userId: sc.id, isPrimary: sc.isPrimary})});
        setCurrentProject({...currentProject, contacts: contacts});

        if (contacts.length > 0) scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContacts]);

    const handlePrimaryChange = (e: any, contact: User) => {
        const primarycontact = selectedContacts.find(sc => sc.id === contact.id);
        selectedContacts.forEach(sc => sc.isPrimary = false);
        if (primarycontact) primarycontact.isPrimary = e.target.checked;

        setSelectedContacts([...selectedContacts]);
    }

    return (
        <div className={styles['form-container']}>
            <form>
                <div>
                    <div className={`${styles.inputs} ${styles.reverse}`}>
                        <div className={styles['input-item']}>
                            <SelectMulti 
                                placeholder='NO CONTACTS' 
                                items={usersStore.users} 
                                selectedItems={selectedContacts} 
                                setSelectedItems={setSelectedContacts} 
                                isItemsObject={true}
                                objectKeyField='id'
                                objectDisplayField='fullName'
                                selectref={usersRef} 
                                directionUp={true} />
                        </div>
                        <div className={styles['input-item']}>
                            {   selectedContacts && selectedContacts.length > 0 &&
                                <>
                                    <div className={styles.counter}>{selectedContacts.length}</div>
                                    <ul>
                                    {   selectedContacts.map(sc => (
                                        <li key={sc.id}>
                                            <div className={styles['input-item-small']}><input type='checkbox' className={styles.star} checked={sc.isPrimary} onChange={e => handlePrimaryChange(e, sc)} /></div>
                                            <div>{`${sc.lastName}, ${sc.firstName}`}</div>
                                        </li>
                                        ))
                                    }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div ref={contactsRef}></div>
            </form>
        </div>
    )
}

export default ContactsForm;