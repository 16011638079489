
import { FETCH_HOME_DASHBOARD } from '../../store/actions/actionTypes';


interface HomeState {
    data: {},
    loading: boolean, 
    error: any
}
const initialState: HomeState = {
    data: {}, 
    loading: false, 
    error: null
}

const homeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${FETCH_HOME_DASHBOARD}_PENDING`: {
            return {
                ...state, 
                loading: true
            }
        }
        case `${FETCH_HOME_DASHBOARD}_FULFILLED`: {
            return {
                ...state, 
                loading: false, 
                data: action.payload.data
            }
        }
        default: return state;
    }
}

export default homeReducer;