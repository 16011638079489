import styles from '../modal.module.scss';

type Props = ({ closeModal: any, title: string, editModal? : boolean });
const ModalHeader = ({ closeModal, title, editModal }: Props) => (
    <div className={styles['modal-header']} style={{marginTop:10}}>
        {editModal && <div className={styles['modal-title']} style={{color:"gray", textAlign : "center",}}>{title}</div>}
        {!editModal && <div className={styles['modal-title']}>{title}</div>}        
        <div className={styles['modal-close']} onClick={() => closeModal()}></div>
    </div>
)

export default ModalHeader;