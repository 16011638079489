import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './forms.module.scss';
import data from '../../data/static-data.json';
import Select from '../controls/select/Select';
import ContactsForm from './ContactsForm';
import { Address, Project } from '../../views/projects/interfaces';
import { saveProject, createProject } from '../../views/projects/actions';
import { fetchUsers } from '../../views/users/actions';
import { CurrentUserDetails } from '../helpers/CurrentUserDetails';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';
import selector from '../controls/select/select.module.scss';
import { SelectType } from '../controls/select/interfaces';
import DailyReportEmailsContactsForm from './DailyReportEmailsForm';


type Props = ({ project?: Project, isExisting?: boolean, closeModal: any, showEModal?: boolean, discard ?: any });
const ProjectForm = ({ project, isExisting, closeModal, showEModal, discard }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    type ProjectsStore = { projectsinfo: {projects: Project[], loading: boolean, error: any} };
    const projectsStore = useSelector((state: { projectsStore: ProjectsStore }) => state.projectsStore);
    const projects = projectsStore.projectsinfo.projects;

    const [currentProject, setCurrentProject] = useState<Project>({ id: project?.id, 
                                                            name: project?.name || '', type: project?.type || '', 
                                                            status: project?.status, company: project?.company,
                                                            scheduledStartDate: project?.scheduledStartDate, scheduledEndDate: project?.scheduledEndDate, 
                                                            address: { 
                                                                addressLine1: project?.address?.addressLine1, city: project?.address?.city, state: project?.address?.state, zip: project?.address?.zip,
                                                                latitude: project?.address?.latitude, longitude: project?.address?.longitude, county: project?.address?.county
                                                            }, 
                                                            createdBy : project?.createdBy,
                                                            createdById : project?.createdById,
                                                            updatedBy : project?.updatedBy,
                                                            updatedById : project?.updatedById,
                                                            description: project?.description, 
                                                            contacts: project?.contacts || [],
															dailyReportEmailContactIdList: project?.dailyReportEmailContactIdList || [] });
    const [showNameRequired, setShowNameRequired] = useState<boolean>(false);
    const [showTypeRequired, setShowTypeRequired] = useState<boolean>(false);
    const [showStartRequired, setShowStartRequired] = useState<boolean>(false);
    const [showCityCountyRequired, setShowCityCountyRequired] = useState<boolean>(false);
    const [showStateRequired, setShowStateRequired] = useState<boolean>(false);   
    const [showLatRequired, setShowLatRequired] = useState<boolean>(false);
    const [showLongRequired, setShowLongRequired] = useState<boolean>(false);   
    // states For Confirmation modal before save/create
    const [showEditModal, setShowEditModal] = useState<boolean>(false);  
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    // used for cloning from default task
    const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string>("");
    let oldStartDate = project?.scheduledStartDate;

    useEffect(() => {
        if (!isExisting && selectedProject) {
            setCurrentProject({
                ...currentProject,
                type: selectedProject.type, company: selectedProject.company, 
                scheduledStartDate: selectedProject.scheduledStartDate, scheduledEndDate: selectedProject.scheduledEndDate, 
                address: selectedProject.address, description: selectedProject.description, 
                contacts: selectedProject.contacts
            });
        }
        oldStartDate = currentProject.scheduledStartDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExisting, selectedProject])
    
    const setAddress = (address: Address) => {
        setCurrentProject({...currentProject, address});
        (!currentProject?.address?.city && !currentProject?.address?.county) ? setShowCityCountyRequired(true) : setShowCityCountyRequired(false);
    };
    const setState = (state: string) => {setAddress({...currentProject.address, state: state});
        if(state) setShowStateRequired(false)}
    const setType = (type: string) => {
        setCurrentProject({...currentProject, type: type})
        if(type) setShowTypeRequired(false)};
    const setStatus = (status: string) => setCurrentProject({...currentProject, status: status});
    const setStart = (start: Date) => {setCurrentProject({...currentProject, scheduledStartDate: start})
        setUnsavedChanges(true);
        if(oldStartDate && (new Date(oldStartDate).getTime() !== start.getTime()))
        {
            console.log(new Date(oldStartDate), start);
            setWarningMessage("The Scheduled Start Date for related Structures will be moved by the same relative amount");
        }
        else 
        {
            setWarningMessage("");
            setUnsavedChanges(false);
        }
        if(start) setShowStartRequired(false)};
    const setEnd = (end: Date) => {setUnsavedChanges(true); setCurrentProject({...currentProject, scheduledEndDate: end})};
    const currentUser = CurrentUserDetails(); 

    const handleSaveProject = () => {
        handleChange();
        (!currentProject?.name || currentProject.name === '') ? setShowNameRequired(true) : setShowNameRequired(false);
        !currentProject?.type ? setShowTypeRequired(true) : setShowTypeRequired(false);
        !currentProject?.scheduledStartDate ? setShowStartRequired(true) : setShowStartRequired(false);
        !currentProject?.address?.state ? setShowStateRequired(true) : setShowStateRequired(false);
        !currentProject?.address?.latitude ? setShowLatRequired(true) : setShowLatRequired(false);
        !currentProject?.address?.longitude ? setShowLongRequired(true) : setShowLongRequired(false);
        (!currentProject?.address?.city && !currentProject?.address?.county) ? setShowCityCountyRequired(true) : setShowCityCountyRequired(false);
        if (currentProject?.name && currentProject.name !== '' && currentProject?.type && 
            currentProject?.scheduledStartDate && currentProject?.address?.state && 
            (currentProject?.address.city || currentProject?.address?.county)) {
                if(isExisting) {
                    currentProject.updatedBy = currentUser.userName;
                    currentProject.updatedById = currentUser.id;
                }
                else {
                    currentProject.createdBy = currentUser.userName;
                    currentProject.createdById = currentUser.id;
                }
                dispatch(isExisting ? saveProject(currentProject) : createProject({...currentProject})); 
                discard();
                closeModal();
        }        
    }

    const cloneRef = useRef<HTMLDivElement>(null);
    const typeRef = useRef<HTMLDivElement>(null);
    const statusRef = useRef<HTMLDivElement>(null);
    const stateRef = useRef<HTMLDivElement>(null);

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }

    const form = document.getElementById("projectForm");

    if(form && !unsavedChanges) {
        form.addEventListener("change", function () {
            setUnsavedChanges(true);
        });
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    const selectorItems = document.getElementsByClassName(selector.item);

    if(selectorItems.length > 0 && !unsavedChanges)
    {
        setUnsavedChanges(true);
    }

    return (
		<>
			<div className={styles['form-container']}>
				<form id='projectForm'>
					{!isExisting && (
						<div className={styles['clone-inputs-section']}>
							<div className={`${styles.inputs} ${styles['clone-input']}`}>
								<div className={styles['input-item']}>
									<Select
										placeholder='COPY FROM PROJECT'
										items={projects}
										selectedItem={selectedProject?.name}
										setSelectedItem={setSelectedProject}
										selectref={cloneRef}
										isItemsObject={true}
										objectKeyField='id'
										objectDisplayField='name'
										disabled={projectsStore.projectsinfo.loading}
										selectType={SelectType.TypeAhead}
									/>
								</div>
							</div>
						</div>
					)}
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showNameRequired ? (
									<label className={styles.required}>* name is required</label>
								) : (
									<label>{currentProject?.name ? 'name' : ''}</label>
								)}
								<input
									type='text'
									placeholder='name'
									value={currentProject?.name || ''}
									onChange={(e) => {
										setCurrentProject({
											...currentProject,
											name: e.target.value
										});
										if (e.target.value.trim() === '') {
											setShowNameRequired(true);
										} else {
											setShowNameRequired(false);
										}
									}}
								/>
							</div>
							<div className={styles['input-item']}>
								<label>{currentProject?.company ? 'company' : ''}</label>
								<input
									type='text'
									placeholder='company'
									value={currentProject?.company || ''}
									onChange={(e) =>
										setCurrentProject({
											...currentProject,
											company: e.target.value
										})
									}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								<label>{currentProject?.status ? 'status' : ''}</label>
								<Select
									placeholder='STATUS'
									items={['Pending', 'In Progress', 'Complete']}
									selectedItem={currentProject?.status}
									setSelectedItem={setStatus}
									selectref={statusRef}
									isItemsObject={false}
								/>
							</div>
							<div className={styles['input-item']}>
								{showTypeRequired ? (
									<label className={styles.required}>* type is required</label>
								) : (
									<label>{currentProject?.type ? 'type' : ''}</label>
								)}
								<Select
									placeholder='TYPE'
									items={['Substation', 'T-line', 'Other']}
									selectedItem={currentProject?.type}
									setSelectedItem={setType}
									selectref={typeRef}
									isItemsObject={false}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showStartRequired ? (
									<label className={styles.required}>
										* sched. start is required
									</label>
								) : (
									<label>
										{currentProject?.scheduledStartDate ? 'sched start' : ''}
									</label>
								)}
								{warningMessage !== '' ? (
									<label className={styles.required} style={{ marginTop: '8px' }}>
										{warningMessage}
									</label>
								) : (
									''
								)}
								<DatePicker
									placeholderText='Sched. start'
									selected={
										currentProject?.scheduledStartDate
											? new Date(currentProject.scheduledStartDate)
											: null
									}
									onChange={setStart}
								/>
							</div>
							<div className={styles['input-item']}>
								<label>{currentProject?.scheduledEndDate ? 'sched end' : ''}</label>
								{warningMessage !== '' ? (
									<label
										className={styles.required}
										style={{ marginTop: '8px' }}></label>
								) : (
									''
								)}
								<DatePicker
									placeholderText='Sched. end'
									selected={
										currentProject?.scheduledEndDate
											? new Date(currentProject.scheduledEndDate)
											: null
									}
									onChange={setEnd}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								<label>
									{currentProject?.address?.addressLine1 ? 'address' : ''}
								</label>
								<input
									type='text'
									placeholder='address'
									value={currentProject?.address?.addressLine1 || ''}
									onChange={(e) =>
										setAddress({
											...currentProject.address,
											addressLine1: e.target.value
										})
									}
								/>
							</div>
						</div>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showCityCountyRequired ? (
									<label className={styles.required}>
										* city or county is required
									</label>
								) : (
									<label>{currentProject?.address?.city ? 'city' : ''}</label>
								)}
								<input
									type='text'
									placeholder='city'
									value={currentProject?.address?.city || ''}
									onChange={(e) =>
										setAddress({
											...currentProject.address,
											city: e.target.value
										})
									}
								/>
							</div>
							<div className={styles['input-item']}>
								<label>{currentProject?.address?.county ? 'county' : ''}</label>
								<input
									type='text'
									placeholder='county'
									value={currentProject?.address?.county || ''}
									onChange={(e) =>
										setAddress({
											...currentProject.address,
											county: e.target.value
										})
									}
								/>
							</div>
							<div className={styles['input-item']}>
								{showStateRequired ? (
									<label className={styles.required}>* state is required</label>
								) : (
									<label>{currentProject?.address?.state ? 'state' : ''}</label>
								)}
								<Select
									placeholder='STATE'
									items={data.states}
									selectedItem={currentProject?.address?.state}
									setSelectedItem={setState}
									selectref={stateRef}
									isItemsObject={false}
								/>
							</div>
							<div className={styles['input-item']}>
								<label>{currentProject?.address?.zip ? 'zip' : ''}</label>
								<input
									type='text'
									placeholder='zip'
									value={currentProject?.address?.zip || ''}
									onChange={(e) =>
										setAddress({
											...currentProject.address,
											zip: e.target.value
										})
									}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								{showLatRequired ? (
									<label className={styles.required}>
										* latitude is required
									</label>
								) : (
									<label>
										{currentProject?.address?.latitude ? 'latitude' : ''}
									</label>
								)}
								<input
									type='text'
									placeholder='latitude'
									value={currentProject?.address?.latitude || ''}
									onChange={(e) => {
										setAddress({
											...currentProject.address,
											latitude: e.target.value
										});
										if (e.target.value.trim() === '') {
											setShowLatRequired(true);
										} else {
											setShowLatRequired(false);
										}
									}}
								/>
							</div>
							<div className={styles['input-item']}>
								{showLongRequired ? (
									<label className={styles.required}>
										* longitude is required
									</label>
								) : (
									<label>
										{currentProject?.address?.longitude ? 'longitude' : ''}
									</label>
								)}
								<input
									type='text'
									placeholder='longitude'
									value={currentProject?.address?.longitude || ''}
									onChange={(e) => {
										setAddress({
											...currentProject.address,
											longitude: e.target.value
										});
										if (e.target.value.trim() === '') {
											setShowLongRequired(true);
										} else {
											setShowLongRequired(false);
										}
									}}
								/>
							</div>
						</div>
					</div>
					<div className={styles['inputs-section']}>
						<div className={styles.inputs}>
							<div className={styles['input-item']}>
								<label>{currentProject?.description ? 'description' : ''}</label>
								<input
									type='text'
									placeholder='description'
									value={currentProject?.description || ''}
									onChange={(e) =>
										setCurrentProject({
											...currentProject,
											description: e.target.value
										})
									}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>

			<div className={styles['sub-form-section']}>
				<div className={styles['heading-section']}>
					<div className={styles.title}>Daily Report Email List</div>
				</div>
				<DailyReportEmailsContactsForm
					currentProject={currentProject}
					setCurrentProject={setCurrentProject}
				/>
			</div>

			<div className={styles['sub-form-section']}>
				<div className={styles['heading-section']}>
					<div className={styles.title}>Contacts</div>
				</div>
				<ContactsForm
					currentProject={currentProject}
					setCurrentProject={setCurrentProject}
				/>
			</div>
			<div className={styles.actions}>
				<button
					onClick={() => {
						if (unsavedChanges) {
							setShowEditModal(true);
						} else {
							discard();
						}
					}}>
					Cancel
				</button>
				<button onClick={() => handleSaveProject()}>
					{isExisting ? 'Save' : 'Create'} Project
				</button>
			</div>
			{(showEModal || showEditModal) && (
				<EditModal
					title='UNSAVED CHANGES'
					closeModal={() => {
						handleChange();
					}}>
					<EditPopup
						closeModal={() => {
							handleChange();
						}}
						editEntity={() => handleSaveProject()}
						discardChanges={() => discard()}
					/>
				</EditModal>
			)}
		</>
	);
}

export default ProjectForm;