import { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faBuilding, faTasks, faFileInvoice, faUsers, faSignOutAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import styles from './navigation.module.scss';
import { Modal } from '../modal/Modal';
import UserForm from '../forms/UserForm';
import { User } from '../../views/users/interfaces';
import { ADMIN } from '../../constants/roles';

function handleLogOut(instance: IPublicClientApplication) {
    try {
        const loginResponse = instance.logoutRedirect().then((response: any) => {
            console.log("Logout Response: " + response.json())
        });

        console.log(loginResponse);
    } catch (err) {
        console.log(err);
    }
}

const Navigation = () => {
    const history = useHistory(); const location = useLocation();
    const { instance } = useMsal();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    return (
        <nav className={styles['left-nav']}>
            <div className={styles['menu-container']}>
                <input id='menu-toggle' className={styles['menu-toggle']} type='checkbox' />
                <label className={styles['menu-btn']} htmlFor='menu-toggle'>
                    <span></span>
                </label>
                <div className={styles.menus}>
                    <ul className={styles['menu']}> 
                        <li>
                            <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname === '/home'}, {[styles['menu-item-unselected']]: location.pathname !== '/home'})} onClick={() => {history.push('/home');}}>
                                <div><FontAwesomeIcon icon={faHome} /></div>
                                <div className={styles['item-label']}>Home</div>
                            </div>
                        </li>
                        <li>
                            <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname.includes('/projects')}, {[styles['menu-item-unselected']]: !location.pathname.includes('/projects')})} onClick={() => {history.push('/projects');}}>
                                <div><FontAwesomeIcon icon={faClipboardList} /></div>
                                <div className={styles['item-label']}>Projects</div>
                            </div>
                        </li>
                        <li>
                            <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname.includes('/structures')}, {[styles['menu-item-unselected']]: location.pathname.includes('/structures')})} onClick={() => {history.push('/structures');}}>
                                <div><FontAwesomeIcon icon={faBuilding} /></div>
                                <div className={styles['item-label']}>Structures</div>
                            </div>
                        </li>
                        <li>
                            <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname.includes('/tasks')}, {[styles['menu-item-unselected']]: location.pathname.includes('/tasks')})} onClick={() => {history.push('/tasks');}}>
                                <div><FontAwesomeIcon icon={faTasks} /></div>
                                <div className={styles['item-label']}>Tasks</div>
                            </div>
                        </li>
                        <li>
                            <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname.includes('/reports')}, {[styles['menu-item-unselected']]: location.pathname.includes('/reports')})} onClick={() => {history.push('/reports');}}>
                                <div><FontAwesomeIcon icon={faFileInvoice} /></div>
                                <div className={styles['item-label']}>Reports</div>
                            </div>
                        </li>
                        {   profileStore.profile?.role?.trim().toUpperCase() === ADMIN && 
                            <li>
                                <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname.includes('/users')}, {[styles['menu-item-unselected']]: location.pathname.includes('/users')})} onClick={() => {history.push('/users');}}>
                                    <div><FontAwesomeIcon icon={faUsers} /></div>
                                    <div className={styles['item-label']}>Users</div>
                                </div>
                            </li>
                        }
                        {/* <li>
                            <div className={styles['menu-item']} onClick={() => { setShowModal(true); }}>
                                <div><FontAwesomeIcon icon={faUser} /></div>
                                <div className={styles['item-label']}>Profile</div>
                            </div>
                        </li> */}
                    </ul>
                    <ul className={`${styles['menu']} ${styles['bottom-menu']}`}>
                        <li>
                            <div className={`${styles['menu-item']} ${styles['signout-item']} ${styles['menu-item-unselected']}`} onClick={() => handleLogOut(instance)}>
                                <div className={styles.signout}><FontAwesomeIcon icon={faSignOutAlt} className={styles['icon-rotate']} /></div>
                                <div className={styles['item-label']}>Sign Out</div>
                            </div>
                        </li>
                    </ul>
                </div>
                { showModal && <Modal title='Edit Profile' closeModal={() => setShowEditModal(true)}><UserForm user={profileStore.profile} isExisting={true} isViewMode={false} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} isProfile={true} /></Modal> }
            </div>
        </nav>
    )
}

export default Navigation;