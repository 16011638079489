import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import styles from './cards.module.scss';
import Card, { DummyCard } from './Card';
import { FilterInfo, useItemsFilter } from '../../hooks/hooks';
import { Task } from '../../views/tasks/interfaces';
import { deleteTask } from '../../views/tasks/actions';
import { DeleteModal, Modal } from '../modal/Modal';
import TaskForm from '../forms/TaskForm';
import StartStop from '../controls/buttons/start-stop/StartStop';
import { User } from '../../views/users/interfaces';
import DeletePopup from '../popup/DeletePopup';
import {Dashboard} from '../../views/home/interfaces';

type CardProps = ({ task: Task, profile: User, elapsedTime: number, setElapsedTime(arg: number): void });
export const TaskCardHeaderInfo = ({ task, profile, elapsedTime, setElapsedTime }: CardProps) => {
    return (
        <>
        {   profile?.role?.toLocaleLowerCase() !== 'client' && task ? 
            <div className={`${styles.info} ${styles['start-stop']}`}>
                <StartStop 
                    task={task} 
                    elapsedTime={elapsedTime} 
                    setElapsedTime={setElapsedTime} />
            </div> : null
        }
        </>
    )
}
export const TaskCardInfo = ({ elapsedTime }: CardProps) => {    
    const formattedElapsed = elapsedTime ? `${(Math.floor((elapsedTime / 3600)))}:${('0' + Math.floor((elapsedTime / 60) % 60)).slice(-2)}:${('0' + Math.floor((elapsedTime / 1) % 60)).slice(-2)}` : null;
    return (
        <div className={styles.elapsed}>
            {   formattedElapsed ?
                <>
                    <div className={styles.label}>Elapsed Time</div>
                    <div className={styles.value}>{formattedElapsed}</div> 
                </> : 
                <div></div>
            }
        </div>
        // <>
        //     <div className={`${styles.ratios} `} style={{ display: 'flex !important', alignItems: 'center', justifyContent: 'space-between' }}>
        //         <div className={styles.ratio} style={{ width: '100%', marginBottom: '20px' }}>
        //             <div className={styles.title} style={{ paddingTop: 0 }}>Elapsed</div>
        //             <div style={{ fontWeight: 'bold', fontSize: '12px' }} className={styles.bubble}>
        //                 <span>{(Math.floor((elapsedTime / 3600)))}:</span>
        //                 <span>{('0' + Math.floor((elapsedTime / 60) % 60)).slice(-2)}</span>
        //             </div>
        //         </div>
        //     </div>
        // </>
    )
}
type ActionProps = ({ task: Task });
export const TaskCardActionInfo = ({ task } : ActionProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    type PermissionsStore = { canEditTasks: boolean };
    const permissionsStore = useSelector((state: { permissionsStore: PermissionsStore }) => state.permissionsStore);

    const handleChange = (newValue:boolean) =>{
        setShowEditModal(newValue);
       
      }
    const discard = () => {
        setShowModal(false);
        setShowEditModal(false);
    }

    return (
        <>
            <button type='button' className={styles.btn} onClick={() => history.push(`/tasks/${encodeURIComponent(task.id || '')}`)}>View</button>
            {   permissionsStore.canEditTasks && 
                <>
                    <button type='button' className={styles.btn} onClick={() => { setShowModal(true); }}>Edit</button>
                    <button type='button' className={styles.btn} onClick={() => { setShowDeleteModal(true); }}>Delete</button>
                    { showModal && permissionsStore.canEditTasks && <Modal title='Edit Task' closeModal={() => setShowEditModal(true)}><TaskForm task={task} isExisting={true} showEModal={showEditModal} closeModal={handleChange} discard={() => discard()} /></Modal> }
                    {showDeleteModal && <DeleteModal title='Delete Task' closeModal={() => setShowDeleteModal(false)}><DeletePopup name={task.name || ""} closeModal={() => setShowDeleteModal(false)} deleteEntity={() =>{if(task){dispatch(deleteTask(task))}setShowDeleteModal(false);}}/></DeleteModal> }
                </>
            }
        </>
    )
}
type TaskCardProps = ({ task: Task, profile: User });
export const TaskCard = ({ task, profile }: TaskCardProps) => {
    const [elapsedTime, setElapsedTime] = useState<number>(task.elapsedTime || 0);

    useEffect(() => {
        if ((task.status?.toLocaleLowerCase() === 'in progress') && task.pausedTime) {
            const current = dayjs();
            const past = dayjs(task.pausedTime);
            const pauseTime = current.diff(past, 'second');
            setElapsedTime(elapsedTime + pauseTime);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    console.log(task);
    return (
        <Card 
            key={task.id} 
            title={task.name || ''} 
            subtitle={task.assignedToName || ''} 
            status={task.status}
            projectName={task.projectName}
            structureName={task.structureName}
            headerinfo={<TaskCardHeaderInfo task={task} profile={profile} elapsedTime={elapsedTime} setElapsedTime={setElapsedTime} />}
            body={<TaskCardInfo task={task} profile={profile} elapsedTime={elapsedTime} setElapsedTime={setElapsedTime} />} 
            actions={<TaskCardActionInfo task={task} />} 
        />
    )
}

type CardsProps = ({ filterValue: string, projectId? :string, taskStatus?: string, structureId? : string})
const TaskCards = ({ filterValue, projectId, taskStatus, structureId }: CardsProps) => {
    type TasksStore = { tasks: Task[], loading: boolean, error: any, overdueTasks: Task[] };
    const tasksStore = useSelector((state: { tasksStore: TasksStore }) => state.tasksStore);
    
    type ProfileStore = { profile: User, loading: boolean, error: any };
    const profileStore = useSelector((state: { profileStore: ProfileStore }) => state.profileStore);
    const profile = profileStore.profile;
    const urlParam = window.location.href.split("?");

    //For DashBoard Filter
    type HomeStore = { data: Dashboard, loading: boolean, error: any };
    const homeStore = useSelector((state: { homeStore: HomeStore }) => state.homeStore);

    if (window.location.href.split('/').slice(-1)[0] === 'home') {
        tasksStore.tasks = homeStore.data.tasks;
    }
    
    if (projectId) {
        tasksStore.tasks = homeStore.data.tasks.filter((task) => {
            return task.projectId === projectId;
        });
    }

    if (taskStatus && homeStore?.data?.tasks && taskStatus!=='') {
        tasksStore.tasks = homeStore.data.tasks.filter(task => task.status === taskStatus);
    }

    if(structureId)
    {
        tasksStore.tasks = homeStore.data.tasks.filter((task) => {
            return task.structureId === structureId;
        })
    }
    // setup of filters
    const filterInfo: FilterInfo = { items: (urlParam.length > 1 && urlParam[1] === 'overdue') ? tasksStore.overdueTasks : tasksStore.tasks, filterValue: filterValue, isItemsObject: true, objectFieldNames: ['id', 'name', 'type'] };
    const filterTasks = useItemsFilter(filterInfo);

    return (
        <>
            {filterTasks.map(t => <TaskCard key={t.id} task={t} profile={profile} />)}
            {(tasksStore.tasks.length % 2) ? <DummyCard /> : null}
        </>
    )
}

export default TaskCards;

