import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './forms.module.scss';
import Select from '../controls/select/Select';
import data from '../../data/static-data.json';
import { Structure } from '../../views/structures/interfaces';
import { createStructure, saveStructure } from '../../views/structures/actions';
import { Project, ProjectState } from '../../views/projects/interfaces';
import { fetchProjects } from '../../views/projects/actions';
import { CurrentUserDetails } from '../helpers/CurrentUserDetails';
import { EditModal } from '../modal/Modal';
import EditPopup from '../popup/EditPopup';
import selector from '../controls/select/select.module.scss';

type DimensionProps = ({ currentStructure: Structure, setCurrentStucture: any });
const Pier = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>{currentStructure?.type === 'Slab on Pier' ? 'Pier' : 'Drilled Pier'} Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles['inputs-section']}>
                    <div className={styles.inputs}>
                        {   currentStructure?.type === 'Slab on Pier' ? 
                            <>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.diameter ? 'DIAMETER (in)' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='DIAMETER (in)' min={0}  
                                           value={currentStructure?.diameter} 
                                           onChange={(e) => setCurrentStucture({ ...currentStructure, diameter: parseFloat(e.target.value) })} />
                                </div>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.depth2 ? 'DEPTH (ft)' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='DEPTH (ft)' min={0} 
                                           value={currentStructure?.depth2} 
                                           onChange={(e) => setCurrentStucture({ ...currentStructure, depth2: parseFloat(e.target.value) })} />
                                </div>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.quantity ? 'QUANTITY' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='QUANTITY' min={0} 
                                           value={currentStructure?.quantity} 
                                           onChange={(e) => setCurrentStucture({ ...currentStructure, quantity: parseFloat(e.target.value) })} />
                                </div>
                            </>
                            :
                            <>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.diameter ? 'DIAMETER (in)' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='DIAMETER (in)' min={0}  
                                           value={currentStructure?.diameter} onChange={(e) => setCurrentStucture({ ...currentStructure, diameter: parseFloat(e.target.value) })} />
                                </div>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.depth ? 'DEPTH + REVEAL (ft)' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='DEPTH + REVEAL (ft)' min={0} 
                                           value={currentStructure?.depth} onChange={(e) => setCurrentStucture({ ...currentStructure, depth: parseFloat(e.target.value) })} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </form>
        </div>
    </div>
);
const Slab = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Slab Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles['inputs-section']}>
                    <div className={styles.inputs}>
                        <div className={styles['input-item']}>
                            <label className={styles['normal-text']}>{currentStructure?.length ? 'LENGTH (ft)' : ''}</label>
                            <input className={styles['normal-text']} type='number' placeholder='LENGTH (ft)' min={0} 
                                    value={currentStructure?.length} onChange={(e) => setCurrentStucture({ ...currentStructure, length: parseFloat(e.target.value) })} />
                        </div>
                        <div className={styles['input-item']}>
                            <label className={styles['normal-text']}>{currentStructure?.width ? 'WIDTH (ft)' : ''}</label>
                            <input className={styles['normal-text']} type='number' placeholder='WIDTH (ft)' min={0} 
                                    value={currentStructure?.width} onChange={(e) => setCurrentStucture({ ...currentStructure, width: parseFloat(e.target.value) })} />
                        </div>
                        <div className={styles['input-item']}>
                            <label className={styles['normal-text']}>{currentStructure?.depth ? 'DEPTH (ft)' : ''}</label>
                            <input className={styles['normal-text']} type='number' placeholder='DEPTH (ft)' min={0} 
                                    value={currentStructure?.depth} onChange={(e) => setCurrentStucture({ ...currentStructure, depth: parseFloat(e.target.value) })} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
);
const Beam = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Beam Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length2 ? 'TOTAL LENGTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='TOTAL LENGTH (ft)' min={0} 
                                value={currentStructure?.length2} onChange={(e) => setCurrentStucture({ ...currentStructure, length2: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.width2 ? 'AVG WIDTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='AVG WIDTH (ft)' min={0} 
                                value={currentStructure?.width2} onChange={(e) => setCurrentStucture({ ...currentStructure, width2: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.depth2 ? 'AVG DEPTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='AVG DEPTH (ft)' min={0} 
                                value={currentStructure?.depth2} onChange={(e) => setCurrentStucture({ ...currentStructure, depth2: parseFloat(e.target.value) })} />
                    </div>
                </div>
            </form>
        </div>
    </div>
);
const Wall = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Wall Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length ? 'LENGTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='LENGTH (ft)' min={0} 
                                value={currentStructure?.length} onChange={(e) => setCurrentStucture({ ...currentStructure, length: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.thickness ? 'THICKNESS (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='THICKNESS (ft)' min={0} 
                                value={currentStructure?.thickness} onChange={(e) => setCurrentStucture({ ...currentStructure, thickness: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.height ? 'HEIGHT (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='HEIGHT (ft)' min={0} 
                                value={currentStructure?.height} onChange={(e) => setCurrentStucture({ ...currentStructure, height: parseFloat(e.target.value) })} />
                    </div>
                </div>
            </form>
        </div>
    </div>
);
const Direct = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Direct Embed Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles['inputs-section']}>
                    <div className={styles.inputs}>
                        <div className={styles['input-item']}>
                            <label className={styles['normal-text']}>{currentStructure?.diameter ? 'DIAMETER (in)' : ''}</label>
                            <input className={styles['normal-text']} type='number' placeholder='DIAMETER (in)' 
                                    value={currentStructure?.diameter} onChange={(e) => setCurrentStucture({ ...currentStructure, diameter: parseFloat(e.target.value) })} />
                        </div>
                        <div className={styles['input-item']}>
                            <label className={styles['normal-text']}>{currentStructure?.depth ? 'DEPTH (ft)' : ''}</label>
                            <input className={styles['normal-text']} type='number' placeholder='DEPTH (ft)' 
                                    value={currentStructure?.depth} onChange={(e) => setCurrentStucture({ ...currentStructure, depth: parseFloat(e.target.value) })} />
                        </div>
                        <div className={styles['input-item']}>
                            <label className={styles['normal-text']}>{currentStructure?.annulus ? 'ANNULUS (in)' : ''}</label>
                            <input className={styles['normal-text']} type='number' placeholder='ANNULUS (in)' 
                                    value={currentStructure?.annulus} onChange={(e) => setCurrentStucture({ ...currentStructure, annulus: parseFloat(e.target.value) })} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
);
const Prefab = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Prefab Install Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length ? 'LENGTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='LENGTH (ft)' 
                                value={currentStructure?.length} onChange={(e) => setCurrentStucture({ ...currentStructure, length: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.width ? 'WIDTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='WIDTH (ft)' 
                                value={currentStructure?.width} onChange={(e) => setCurrentStucture({ ...currentStructure, width: parseFloat(e.target.value) })} />
                    </div>
                </div>    
            </form>
        </div>
    </div>
);
const Conduit = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Conduit Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length ? 'LENGTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='LENGTH (ft)' 
                                value={currentStructure?.length} onChange={(e) => setCurrentStucture({ ...currentStructure, length: parseFloat(e.target.value) })} />
                    </div>
                </div> 
            </form>
        </div>
    </div>
)
const Ground = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Ground Grid Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length ? 'LENGTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='LENGTH (ft)' 
                                value={currentStructure?.length} onChange={(e) => setCurrentStucture({ ...currentStructure, length: parseFloat(e.target.value) })} />
                    </div>
                </div> 
            </form>
        </div>
    </div>
)
const Other = ({ currentStructure, setCurrentStucture }: DimensionProps) => (
    <div className={styles['sub-form-section']}>
        <div className={styles['heading-section']}>
            <div className={styles.title}>Other Dimensions</div>
        </div>
        <div className={styles['form-container']}>
            <form>
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length ? 'LENGTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='LENGTH (ft)' 
                                value={currentStructure?.length} onChange={(e) => setCurrentStucture({ ...currentStructure, length: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.width ? 'WIDTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='WIDTH (ft)' 
                                value={currentStructure?.width} onChange={(e) => setCurrentStucture({ ...currentStructure, width: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.depth ? 'DEPTH (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='DEPTH (ft)' 
                                value={currentStructure?.depth} onChange={(e) => setCurrentStucture({ ...currentStructure, depth: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.thickness ? 'THICKNESS (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='THICKNESS (ft)' 
                            value={currentStructure?.thickness} onChange={(e) => setCurrentStucture({ ...currentStructure, thickness: parseFloat(e.target.value) })} />
                    </div>
                </div> 
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.height ? 'HEIGHT (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='HEIGHT (ft)' 
                                value={currentStructure?.height} onChange={(e) => setCurrentStucture({ ...currentStructure, height: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.diameter ? 'DIAMETER (in)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='DIAMETER (in)' 
                                value={currentStructure?.diameter} onChange={(e) => setCurrentStucture({ ...currentStructure, diameter: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.annulus ? 'ANNULUS (in)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='ANNULUS (in)' 
                                value={currentStructure?.annulus} onChange={(e) => setCurrentStucture({ ...currentStructure, annulus: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.quantity ? 'QUANTITY' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='QUANTITY' 
                            value={currentStructure?.quantity} onChange={(e) => setCurrentStucture({ ...currentStructure, quantity: parseFloat(e.target.value) })} />
                    </div>
                </div> 
                <div className={styles.inputs}>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.length2 ? 'LENGTH 2 (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='LENGTH 2 (ft)' 
                                value={currentStructure?.length2} onChange={(e) => setCurrentStucture({ ...currentStructure, length2: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.width2 ? 'WIDTH 2 (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='WIDTH 2 (ft)' 
                                value={currentStructure?.width2} onChange={(e) => setCurrentStucture({ ...currentStructure, width2: parseFloat(e.target.value) })} />
                    </div>
                    <div className={styles['input-item']}>
                        <label className={styles['normal-text']}>{currentStructure?.depth2 ? 'DEPTH 2 (ft)' : ''}</label>
                        <input className={styles['normal-text']} type='number' placeholder='DEPTH 2 (ft)' 
                                value={currentStructure?.depth2} onChange={(e) => setCurrentStucture({ ...currentStructure, depth2: parseFloat(e.target.value) })} />
                    </div>
                </div> 
            </form>
        </div>
    </div>
)

type Props = ({ structure?: Structure, isExisting?: boolean, closeModal: any, project?: Project, showEModal?: boolean, discard ?: any  });
const StructureForm = ({ structure, isExisting, closeModal, project, showEModal, discard }: Props) => {
    const dispatch = useDispatch();

    const [currentStructure, setCurrentStucture] = useState<Structure | null | undefined>(structure);

    const [showNameRequired, setShowNameRequired] = useState<boolean>(false);
    const [showProjectRequired, setShowProjectRequired] = useState<boolean>(false);
    const [showTypeRequired, setShowTypeRequired] = useState<boolean>(false);
     //States For Confirmation modal before save/create
    const [showEditModal, setShowEditModal] = useState<boolean>(false);  
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    useEffect(() => {
        if (!isExisting) dispatch(fetchProjects());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExisting]);
    useEffect(() => {
        // used to set project details if create project triggres inside project detail page
        if (project) {
            setCurrentStucture({ ...currentStructure, projectId: project.id, projectName: project.name });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    const projectsStore = useSelector((state: { projectsStore: ProjectState }) => state.projectsStore);
    const projects = projectsStore.projectsinfo.projects;

    const setProject = (project: Project) => setCurrentStucture({ ...currentStructure, projectId: project.id, projectName: project.name });
    const setStructureType = (structuretype: string) => setCurrentStucture({ ...currentStructure, type: structuretype });
    const setStart = (startDate: Date) => {setCurrentStucture({ ...currentStructure, scheduledStartDate: startDate }); setUnsavedChanges(true);};
    const setEnd = (endDate: Date) => {setCurrentStucture({ ...currentStructure, scheduledEndDate: endDate }); setUnsavedChanges(true);};
    const setGeotechAssessment = (geotechAssessment: string) => setCurrentStucture({ ...currentStructure, geotechAssessment: geotechAssessment });
    const currentUser = CurrentUserDetails(); 

    // used for save structure
    const handleSaveStructure = () => {
        handleChange();
        if (!currentStructure?.name || currentStructure.name === '') setShowNameRequired(true);
        if (!currentStructure?.projectId) setShowProjectRequired(true);
        if (!currentStructure?.type) setShowTypeRequired(true);
        if (currentStructure?.name && currentStructure.name !== '' && currentStructure?.type && currentStructure?.projectId) {
            if(isExisting) {
                currentStructure.updatedBy = currentUser.userName;
                currentStructure.updatedById = currentUser.id;
            }
            else {
                currentStructure.createdBy = currentUser.userName;
                currentStructure.createdById = currentUser.id;
            }
            dispatch(isExisting ? saveStructure(currentStructure) : createStructure({ ...currentStructure }));
            discard();
            closeModal();
        }
    }

    function handleChange() {
        setShowEditModal(false);
        closeModal(false);
    }

    const form = document.getElementById("structureForm");

    if(form && !unsavedChanges) {
        form.addEventListener("input", function () {
            setUnsavedChanges(true);
        });
    }

    const selectorItems = document.getElementsByClassName(selector.item);

    if(selectorItems.length > 0 && !unsavedChanges)
    {
        setUnsavedChanges(true);
    }

    if(showEModal && !unsavedChanges) {
        discard();
    }

    const projectRef = useRef<HTMLDivElement>(null);
    const typeRef = useRef<HTMLDivElement>(null);
    const geoRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <div className={styles['form-container']}>
                <form id='structureForm'>
                    {/* {currentStructure?.projectName && <div className={styles.title}>Project Name: {currentStructure.projectName}</div>} */}
                    <div className={styles['inputs-section']}>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                {showNameRequired ? <label className={styles.required}>* name is required</label> : <label>{currentStructure?.name ? 'name' : ''}</label>}
                                <input type='text' placeholder='name' value={currentStructure?.name} onChange={(e) => setCurrentStucture({ ...currentStructure, name: e.target.value })} />
                            </div>
                            <div className={styles['input-item']}>
                                {showTypeRequired ? <label className={styles.required}>* type is required</label> : <label>{currentStructure?.type ? 'type' : ''}</label>}
                                <Select placeholder='Type' items={data.structureTypes} selectedItem={currentStructure?.type} setSelectedItem={setStructureType} selectref={typeRef} isItemsObject={false} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                {showProjectRequired ? <label className={styles.required}>* project is required</label> : <label>{currentStructure?.projectId ? 'project' : ''}</label>}
                                <Select placeholder='Project' items={projects} selectedItem={currentStructure?.projectName} setSelectedItem={setProject} selectref={projectRef} isItemsObject={true} objectKeyField='id' objectDisplayField='name' disabled={isExisting || project !== undefined} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                <label>{currentStructure?.startDate ? 'Scheduled start date' : ''}</label>
                                <DatePicker placeholderText='Start date' selected={currentStructure?.scheduledStartDate ? new Date(currentStructure.scheduledStartDate) : null} onChange={setStart} />
                            </div>
                            <div className={styles['input-item']}>
                                <label>{currentStructure?.endDate ? 'Scheduled end date' : ''}</label>
                                <DatePicker placeholderText='End date' selected={currentStructure?.scheduledEndDate ? new Date(currentStructure.scheduledEndDate) : null} onChange={setEnd} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                <label>{currentStructure?.latitude || currentStructure?.latitude === 0 ? 'latitude' : ''}</label>
                                <input type='number' placeholder='LATITUDE: 00.000000' value={currentStructure?.latitude} onChange={(e) => setCurrentStucture({ ...currentStructure, latitude: parseFloat(e.target.value) })} />
                            </div>
                            <div className={styles['input-item']}>
                                <label>{currentStructure?.longitude ? 'longitude' : ''}</label>
                                <input type='number' placeholder='LONGITUDE: 00.000000' value={currentStructure?.longitude} onChange={(e) => setCurrentStucture({ ...currentStructure, longitude: parseFloat(e.target.value) })} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                <label>{currentStructure?.geotechAssessment ? 'geotech assessment' : ''}</label>
                                <Select placeholder='Geotech Assessment' items={data.geotechAssessmentValues} selectedItem={currentStructure?.geotechAssessment} setSelectedItem={setGeotechAssessment} selectref={geoRef} isItemsObject={false} />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles['input-item']}>
                                <label>{currentStructure?.description ? 'description' : ''}</label>
                                <textarea rows={3} cols={10} placeholder='Description' value={currentStructure?.description} onChange={(e) => setCurrentStucture({ ...currentStructure, description: e.target.value })} />
                            </div>
                        </div>
                       
                        {   currentStructure?.type?.toLocaleLowerCase() === 'drilled pier' ? 
                            <Pier currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'slab' ? 
                            <Slab currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'slab on pier' ? 
                            <><Slab currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /><Pier currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /></> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'slab on beam' ? 
                            <><Slab currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /><Beam currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /></> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'wall' ? 
                            <Wall currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'direct embed' ? 
                            <Direct currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'prefab install' ? 
                            <Prefab currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'conduit' ? 
                            <Conduit currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'ground grid' ? 
                            <Ground currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            currentStructure?.type?.toLocaleLowerCase() === 'other' ? 
                            <Other currentStructure={currentStructure} setCurrentStucture={setCurrentStucture} /> : 
                            null
                        }   
                        <div className={styles['sub-form-section']}>
                            <div className={styles['heading-section']}>
                                <div className={styles.title}>Materials</div>
                            </div>
                            <div className={styles.inputs}>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.concrete ? 'CONCRETE' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='CONCRETE (CUYD)' value={currentStructure?.concrete} onChange={(e) => setCurrentStucture({ ...currentStructure, concrete: parseFloat(e.target.value) })} />
                                </div>
                                <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.steel ? 'STEEL' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='STEEL (lb. x 1,000)' value={currentStructure?.steel} onChange={(e) => setCurrentStucture({ ...currentStructure, steel: parseFloat(e.target.value) })} />
                                </div>
                                {   currentStructure?.type?.toLocaleLowerCase() !== 'drilled pier' ? <div className={styles['input-item']}>
                                    <label className={styles['normal-text']}>{currentStructure?.backfill ? 'BACKFILL (CUYD)' : ''}</label>
                                    <input className={styles['normal-text']} type='number' placeholder='BACKFILL (CUYD)' value={currentStructure?.backfill} onChange={(e) => setCurrentStucture({ ...currentStructure, backfill: parseFloat(e.target.value) })} />
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        
            <div className={styles.actions}>
                <button onClick={() => {if(unsavedChanges) {setShowEditModal(true); } else {discard(); }}}>Cancel</button>
                <button onClick={() => handleSaveStructure()}>{isExisting ? 'Save' : 'Create'} Structure</button>
            </div>
            {(showEModal || showEditModal) && <EditModal title='UNSAVED CHANGES' closeModal={() => handleChange()}><EditPopup  closeModal={() => handleChange()} editEntity={() =>handleSaveStructure()} discardChanges = {() => discard()}/></EditModal> }
        </>
    )
}

export default StructureForm;


